/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getM_9a_products_classes = /* GraphQL */ `
  query GetM_9a_products_classes($product_class_id: Int!) {
    getM_9a_products_classes(product_class_id: $product_class_id) {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_9a_products_classes = /* GraphQL */ `
  query List_m_9a_products_classes($where_clause: String) {
    list_m_9a_products_classes(where_clause: $where_clause) {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_billings = /* GraphQL */ `
  query GetM_billings($site_id: Int!) {
    getM_billings(site_id: $site_id) {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_billings = /* GraphQL */ `
  query List_m_billings($where_clause: String) {
    list_m_billings(where_clause: $where_clause) {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_calendar = /* GraphQL */ `
  query GetM_calendar($get_m_calendarInput: Get_m_calendarInput!) {
    getM_calendar(get_m_calendarInput: $get_m_calendarInput) {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_calendar = /* GraphQL */ `
  query List_m_calendar($where_clause: String) {
    list_m_calendar(where_clause: $where_clause) {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_clients = /* GraphQL */ `
  query GetM_clients($id: Int!) {
    getM_clients(id: $id) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_clients = /* GraphQL */ `
  query List_m_clients($where_clause: String) {
    list_m_clients(where_clause: $where_clause) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_clients_01 = /* GraphQL */ `
  query List_v_clients_01($where_clause: String) {
    list_v_clients_01(where_clause: $where_clause) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_clients_parent_child = /* GraphQL */ `
  query List_m_clients_parent_child($where_clause: String) {
    list_m_clients_parent_child(where_clause: $where_clause) {
      client_class_parent
      client_id_parent
      client_name_kanji
      client_class_branch
      client_id_branch
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_clients_parent_child_01 = /* GraphQL */ `
  query List_v_clients_parent_child_01($where_clause: String) {
    list_v_clients_parent_child_01(where_clause: $where_clause) {
      client_class_parent
      client_id_parent
      client_name_kanji_parent
      client_name_kana_parent
      client_name_kanji
      client_class_branch
      client_id_branch
      client_name_kanji_branch
      client_name_kana_branch
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_clients_products = /* GraphQL */ `
  query List_m_clients_products($where_clause: String) {
    list_m_clients_products(where_clause: $where_clause) {
      client_class
      client_id
      product_id
      unit_price_effective_date
      sales_unit_price
      reason
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_clients_products_01 = /* GraphQL */ `
  query List_v_clients_products_01($where_clause: String) {
    list_v_clients_products_01(where_clause: $where_clause) {
      client_class
      client_id
      client_name_kanji
      client_name_kana
      product_id
      product_name_kanji
      product_name_kana
      unit_price_effective_date
      sales_unit_price
      reason
    }
  }
`;
export const list_v_clients_products_02 = /* GraphQL */ `
  query List_v_clients_products_02($where_clause: String) {
    list_v_clients_products_02(where_clause: $where_clause) {
      client_class
      client_id
      client_name_kanji
      client_name_kana
      product_id
      product_name_kanji
      product_name_kana
      unit_price_effective_date
      sales_unit_price
      reason
    }
  }
`;
export const getM_clients_sites = /* GraphQL */ `
  query GetM_clients_sites($site_id: Int!) {
    getM_clients_sites(site_id: $site_id) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_clients_sites = /* GraphQL */ `
  query List_m_clients_sites($where_clause: String) {
    list_m_clients_sites(where_clause: $where_clause) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_clients_sites_01 = /* GraphQL */ `
  query List_v_clients_sites_01($where_clause: String) {
    list_v_clients_sites_01(where_clause: $where_clause) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      created
      created_user
      updated
      updated_user
      check_contents
    }
  }
`;
export const list_m_control = /* GraphQL */ `
  query List_m_control($where_clause: String) {
    list_m_control(where_clause: $where_clause) {
      process_month_year
      beginning_month
      tax_rate
      new_tax_rate
      new_tax_start_date
      system_state
      monthly_update_state
      monthly_update_start_date
      monthly_update_end_date
      switch_client_id_state
      switch_client_id_start_date
      switch_client_id_end_date
      switch_product_id_state
      switch_product_id_start_date
      switch_product_id_end_date
      closing_update_state
      products_bulk_update_state
      light_tax_rate
      new_light_tax_rate
      light_tax_mark
      invoice_input_no
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_deliverys_designs_classes = /* GraphQL */ `
  query GetM_deliverys_designs_classes($delivery_design_class: String!) {
    getM_deliverys_designs_classes(
      delivery_design_class: $delivery_design_class
    ) {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_deliverys_designs_classes = /* GraphQL */ `
  query List_m_deliverys_designs_classes($where_clause: String) {
    list_m_deliverys_designs_classes(where_clause: $where_clause) {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_form_counter = /* GraphQL */ `
  query List_m_form_counter($where_clause: String) {
    list_m_form_counter(where_clause: $where_clause) {
      counter_class
      count_cur
      count_min
      count_max
      free_class_1
      free_class_2
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_inquiries_definitions = /* GraphQL */ `
  query GetM_inquiries_definitions($id: Int!) {
    getM_inquiries_definitions(id: $id) {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_inquiries_definitions = /* GraphQL */ `
  query List_m_inquiries_definitions($where_clause: String) {
    list_m_inquiries_definitions(where_clause: $where_clause) {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_service_classes = /* GraphQL */ `
  query GetM_service_classes($inquiry_name: String!) {
    getM_service_classes(inquiry_name: $inquiry_name) {
      service_class
      service_class_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_service_classes = /* GraphQL */ `
  query List_m_service_classes($where_clause: String) {
    list_m_service_classes(where_clause: $where_clause) {
      service_class
      service_class_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_offices = /* GraphQL */ `
  query GetM_offices($office_id: Int!) {
    getM_offices(office_id: $office_id) {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_offices = /* GraphQL */ `
  query List_m_offices($where_clause: String) {
    list_m_offices(where_clause: $where_clause) {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_payments = /* GraphQL */ `
  query GetM_payments($office_id: Int!) {
    getM_payments(office_id: $office_id) {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_payments = /* GraphQL */ `
  query List_m_payments($where_clause: String) {
    list_m_payments(where_clause: $where_clause) {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products = /* GraphQL */ `
  query GetM_products($id: Int!) {
    getM_products(id: $id) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products = /* GraphQL */ `
  query List_m_products($where_clause: String) {
    list_m_products(where_clause: $where_clause) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_products_01 = /* GraphQL */ `
  query List_v_products_01($where_clause: String) {
    list_v_products_01(where_clause: $where_clause) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      supplier_product_id
      created
      created_user
      updated
      updated_user
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_id
      supplier_name_kanji
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
    }
  }
`;
export const list_v_products_02 = /* GraphQL */ `
  query List_v_products_02($where_clause: String) {
    list_v_products_02(where_clause: $where_clause) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      set_flg
      group_flg
      group_flg_2
      case_flg
      loose_flg
      supplier_product_id
      created
      created_user
      updated
      updated_user
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_id
      supplier_name_kanji
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
    }
  }
`;
export const getM_products_categories = /* GraphQL */ `
  query GetM_products_categories($category_5: Int!) {
    getM_products_categories(category_5: $category_5) {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_categories = /* GraphQL */ `
  query List_m_products_categories($where_clause: String) {
    list_m_products_categories(where_clause: $where_clause) {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_categories_names = /* GraphQL */ `
  query GetM_products_categories_names($category_5: Int!) {
    getM_products_categories_names(category_5: $category_5) {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_categories_names = /* GraphQL */ `
  query List_m_products_categories_names($where_clause: String) {
    list_m_products_categories_names(where_clause: $where_clause) {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_compositions = /* GraphQL */ `
  query GetM_products_compositions($component_product_id: Int!) {
    getM_products_compositions(component_product_id: $component_product_id) {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_compositions = /* GraphQL */ `
  query List_m_products_compositions($where_clause: String) {
    list_m_products_compositions(where_clause: $where_clause) {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_details = /* GraphQL */ `
  query GetM_products_details($office_id: Int!) {
    getM_products_details(office_id: $office_id) {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_details = /* GraphQL */ `
  query List_m_products_details($where_clause: String) {
    list_m_products_details(where_clause: $where_clause) {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_orders = /* GraphQL */ `
  query GetM_products_orders($office_id: Int!) {
    getM_products_orders(office_id: $office_id) {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_orders = /* GraphQL */ `
  query List_m_products_orders($where_clause: String) {
    list_m_products_orders(where_clause: $where_clause) {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_products_orders = /* GraphQL */ `
  query List_v_products_orders($where_clause: String) {
    list_v_products_orders(where_clause: $where_clause) {
      product_id
      office_id
      client_id
      office_name_kanji
      client_class
      client_name_kanji
      client_name_kana
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_prices_groups = /* GraphQL */ `
  query GetM_products_prices_groups($product_id: Int!) {
    getM_products_prices_groups(product_id: $product_id) {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_prices_groups = /* GraphQL */ `
  query List_m_products_prices_groups($where_clause: String) {
    list_m_products_prices_groups(where_clause: $where_clause) {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_products_units_conversions = /* GraphQL */ `
  query GetM_products_units_conversions($loose_product_id: Int!) {
    getM_products_units_conversions(loose_product_id: $loose_product_id) {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_products_units_conversions = /* GraphQL */ `
  query List_m_products_units_conversions($where_clause: String) {
    list_m_products_units_conversions(where_clause: $where_clause) {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_routes = /* GraphQL */ `
  query GetM_routes($shipping_code: String!) {
    getM_routes(shipping_code: $shipping_code) {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_routes = /* GraphQL */ `
  query List_m_routes($where_clause: String) {
    list_m_routes(where_clause: $where_clause) {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_staffs = /* GraphQL */ `
  query GetM_staffs($staff_id: Int!) {
    getM_staffs(staff_id: $staff_id) {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_staffs = /* GraphQL */ `
  query List_m_staffs($where_clause: String) {
    list_m_staffs(where_clause: $where_clause) {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_stocks = /* GraphQL */ `
  query GetM_stocks($product_id: Int!) {
    getM_stocks(product_id: $product_id) {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_stocks = /* GraphQL */ `
  query List_m_stocks($where_clause: String) {
    list_m_stocks(where_clause: $where_clause) {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_suppliers_sites = /* GraphQL */ `
  query GetM_suppliers_sites($site_id: Int!) {
    getM_suppliers_sites(site_id: $site_id) {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_suppliers_sites = /* GraphQL */ `
  query List_m_suppliers_sites($where_clause: String) {
    list_m_suppliers_sites(where_clause: $where_clause) {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_suppliers_offices = /* GraphQL */ `
  query GetM_suppliers_offices(
    $get_m_suppliers_offices: Get_m_suppliers_officesInput!
  ) {
    getM_suppliers_offices(get_m_suppliers_offices: $get_m_suppliers_offices) {
      supplier_id
      office_id
      supplier_control_office_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_suppliers_offices = /* GraphQL */ `
  query List_m_suppliers_offices($where_clause: String) {
    list_m_suppliers_offices(where_clause: $where_clause) {
      supplier_id
      office_id
      supplier_control_office_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getM_versions = /* GraphQL */ `
  query GetM_versions($type_name: String!) {
    getM_versions(type_name: $type_name) {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_m_versions = /* GraphQL */ `
  query List_m_versions($where_clause: String) {
    list_m_versions(where_clause: $where_clause) {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_billings_balances = /* GraphQL */ `
  query GetT_billings_balances($site_id: Int!) {
    getT_billings_balances(site_id: $site_id) {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_billings_balances = /* GraphQL */ `
  query List_t_billings_balances($where_clause: String) {
    list_t_billings_balances(where_clause: $where_clause) {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_billings_remainings = /* GraphQL */ `
  query GetT_billings_remainings($billing_date: AWSDate!) {
    getT_billings_remainings(billing_date: $billing_date) {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_billings_remainings = /* GraphQL */ `
  query List_t_billings_remainings($where_clause: String) {
    list_t_billings_remainings(where_clause: $where_clause) {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_billings_unsettled = /* GraphQL */ `
  query GetT_billings_unsettled($client_id: Int!) {
    getT_billings_unsettled(client_id: $client_id) {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_billings_unsettled = /* GraphQL */ `
  query List_t_billings_unsettled($where_clause: String) {
    list_t_billings_unsettled(where_clause: $where_clause) {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_billings_unsettled_01 = /* GraphQL */ `
  query List_v_billings_unsettled_01($where_clause: String) {
    list_v_billings_unsettled_01(where_clause: $where_clause) {
      client_class
      client_id
      client_name_kanji
      client_name_kana
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_closing_update_completion = /* GraphQL */ `
  query List_t_closing_update_completion($where_clause: String) {
    list_t_closing_update_completion(where_clause: $where_clause) {
      billing_payment_class
      process_month_year
      closing_date
      client_id_start
      client_id_end
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_closing_update_range = /* GraphQL */ `
  query List_t_closing_update_range($where_clause: String) {
    list_t_closing_update_range(where_clause: $where_clause) {
      billing_payment_class
      process_month_year
      closing_date
      client_id_start
      client_id_end
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_estimate = /* GraphQL */ `
  query List_t_estimate($where_clause: String) {
    list_t_estimate(where_clause: $where_clause) {
      estimate_id
      estimate_row
      office_id
      office_name_kanji
      client_class
      client_id
      client_name_kanji
      client_print_name
      estimate_date
      client_staff_id
      client_staff_name_kanji
      client_staff_name_stamp
      created_staff_id
      created_staff_name_kanji
      created_staff_name_stamp
      estimate_kind
      tax_type
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      contents
      delivery_date
      estimate_amount
      tax
      site_id
      client_site_name_kanji
      payment_way
      estimate_period
      estimate_period_sentence
      summary
      product_id
      product_name_kanji
      product_note
      product_quantity
      product_unit
      product_sales_unit_price
      product_purchase_price
      product_amount
      created_date
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_estimate_orders = /* GraphQL */ `
  query List_t_estimate_orders($where_clause: String) {
    list_t_estimate_orders(where_clause: $where_clause) {
      order_id
      order_row
      office_id
      office_name_kanji
      client_class
      client_id
      client_name_kanji
      client_print_name
      order_date
      client_staff_id
      client_staff_name_kanji
      client_staff_name_stamp
      created_staff_id
      created_staff_name_kanji
      created_staff_name_stamp
      contents
      summary
      product_id
      product_name_kanji
      product_note
      product_quantity
      product_unit
      created_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_inventories = /* GraphQL */ `
  query GetT_inventories(
    $inventory_no: Int!
    $office_id: Int!
    $product_id: Int!
  ) {
    getT_inventories(
      inventory_no: $inventory_no
      office_id: $office_id
      product_id: $product_id
    ) {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_inventories = /* GraphQL */ `
  query List_t_inventories($where_clause: String) {
    list_t_inventories(where_clause: $where_clause) {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_inventories_histories = /* GraphQL */ `
  query GetT_inventories_histories($inventory_no: Int!) {
    getT_inventories_histories(inventory_no: $inventory_no) {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_inventories_histories = /* GraphQL */ `
  query List_t_inventories_histories($where_clause: String) {
    list_t_inventories_histories(where_clause: $where_clause) {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_orders = /* GraphQL */ `
  query GetT_orders($order_row: Int!) {
    getT_orders(order_row: $order_row) {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_orders = /* GraphQL */ `
  query List_t_orders($where_clause: String) {
    list_t_orders(where_clause: $where_clause) {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_orders_received_set = /* GraphQL */ `
  query GetT_orders_received_set($order_receive_row_branch: Int!) {
    getT_orders_received_set(
      order_receive_row_branch: $order_receive_row_branch
    ) {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_orders_received_set = /* GraphQL */ `
  query List_t_orders_received_set($where_clause: String) {
    list_t_orders_received_set(where_clause: $where_clause) {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_orders_receives = /* GraphQL */ `
  query GetT_orders_receives($where_clause: String!) {
    getT_orders_receives(where_clause: $where_clause) {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_orders_receives = /* GraphQL */ `
  query List_t_orders_receives($where_clause: String) {
    list_t_orders_receives(where_clause: $where_clause) {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_payables_balances_results = /* GraphQL */ `
  query GetT_payables_balances_results($month_year: Int!, $supplier_id: Int!) {
    getT_payables_balances_results(
      month_year: $month_year
      supplier_id: $supplier_id
    ) {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_payables_balances_results = /* GraphQL */ `
  query List_t_payables_balances_results($where_clause: String) {
    list_t_payables_balances_results(where_clause: $where_clause) {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_payments_balances = /* GraphQL */ `
  query GetT_payments_balances($office_id: Int!) {
    getT_payments_balances(office_id: $office_id) {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_payments_balances = /* GraphQL */ `
  query List_t_payments_balances($where_clause: String) {
    list_t_payments_balances(where_clause: $where_clause) {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_products_prices_histories = /* GraphQL */ `
  query GetT_products_prices_histories($price_update_datetime: String!) {
    getT_products_prices_histories(
      price_update_datetime: $price_update_datetime
    ) {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_products_prices_histories = /* GraphQL */ `
  query List_t_products_prices_histories($where_clause: String) {
    list_t_products_prices_histories(where_clause: $where_clause) {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_products_deleted_log = /* GraphQL */ `
  query GetT_products_deleted_log($product_id: Int!) {
    getT_products_deleted_log(product_id: $product_id) {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_products_deleted_log = /* GraphQL */ `
  query List_t_products_deleted_log($where_clause: String) {
    list_t_products_deleted_log(where_clause: $where_clause) {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_products_results = /* GraphQL */ `
  query GetT_products_results($todo3: Int!) {
    getT_products_results(todo3: $todo3) {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_products_results = /* GraphQL */ `
  query List_t_products_results($where_clause: String) {
    list_t_products_results(where_clause: $where_clause) {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_receivables_balances_results = /* GraphQL */ `
  query GetT_receivables_balances_results($month_year: Int!, $client_id: Int!) {
    getT_receivables_balances_results(
      month_year: $month_year
      client_id: $client_id
    ) {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_receivables_balances_results = /* GraphQL */ `
  query List_t_receivables_balances_results($where_clause: String) {
    list_t_receivables_balances_results(where_clause: $where_clause) {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_ships = /* GraphQL */ `
  query GetT_ships($order_receive_row: Int!) {
    getT_ships(order_receive_row: $order_receive_row) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_ships = /* GraphQL */ `
  query List_t_ships($where_clause: String) {
    list_t_ships(where_clause: $where_clause) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_v_ships_01 = /* GraphQL */ `
  query List_v_ships_01($where_clause: String) {
    list_v_ships_01(where_clause: $where_clause) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      office_name_kanji
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      closing_date
      billing_type_class
      site_id
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_staffs_results = /* GraphQL */ `
  query GetT_staffs_results($staff_id: Int!) {
    getT_staffs_results(staff_id: $staff_id) {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_staffs_results = /* GraphQL */ `
  query List_t_staffs_results($where_clause: String) {
    list_t_staffs_results(where_clause: $where_clause) {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getT_stocks = /* GraphQL */ `
  query GetT_stocks($order_row: Int!) {
    getT_stocks(order_row: $order_row) {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_t_stocks = /* GraphQL */ `
  query List_t_stocks($where_clause: String) {
    list_t_stocks(where_clause: $where_clause) {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getW_clients_sales_file = /* GraphQL */ `
  query GetW_clients_sales_file($client_id: Int!) {
    getW_clients_sales_file(client_id: $client_id) {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const list_w_clients_sales_file = /* GraphQL */ `
  query List_w_clients_sales_file($where_clause: String) {
    list_w_clients_sales_file(where_clause: $where_clause) {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const getQueryStatus = /* GraphQL */ `
  query GetQueryStatus($ProcessID: ID!) {
    getQueryStatus(ProcessID: $ProcessID) {
      ProcessID
      StatusClass
      TypeClass
      S3Keys
      Error
    }
  }
`;
export const listQueryStatuses = /* GraphQL */ `
  query ListQueryStatuses(
    $ProcessID: ID
    $filter: ModelQueryStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQueryStatuses(
      ProcessID: $ProcessID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ProcessID
        StatusClass
        TypeClass
        S3Keys
        Error
      }
      nextToken
    }
  }
`;
export const getTransactSqlStatus = /* GraphQL */ `
  query GetTransactSqlStatus($ProcessID: ID!) {
    getTransactSqlStatus(ProcessID: $ProcessID) {
      ProcessID
      StatusClass
      TypeClass
      S3Key
      Error
      createdAt
      updatedAt
    }
  }
`;
export const listTransactSqlStatuses = /* GraphQL */ `
  query ListTransactSqlStatuses(
    $ProcessID: ID
    $filter: ModelTransactSqlStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransactSqlStatuses(
      ProcessID: $ProcessID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ProcessID
        StatusClass
        TypeClass
        S3Key
        Error
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
