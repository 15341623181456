export default {
  // 表示制御用画面名配列
  VueName:[
    'INVOICE-LIST', // 請求書一覧
    'PRE-INVOICE-PROCESS', // 請求前処理
    'RECEIVED-ORDER-INPUT', // 受注入力
    'ESTIMATE-INPUT', // 見積入力
    'ESTIMATE-EDIT', // 見積修正
    'CLIENTS-PRODUCTS-MASTER-INPUT', // 取引先製品マスタ入力
    'CLIENTS-PRODUCTS-MASTER-EDIT', // 取引先製品マスタ修正
  ],
  // 配送（ルート）プルダウン
  DeliveryList:[
    {id: '1', name: '通常配送', text: '1：通常配送'},
    {id: '2', name: '宅配', text: '2：宅配'},
    {id: '3', name: '営業持参', text: '3：営業持参'},
    {id: '4', name: '直送', text: '4：直送'},
    {id: '5', name: '', text: '5：'},
    {id: '6', name: '', text: '6：'},
    {id: '7', name: '', text: '7：'},
    {id: '8', name: '', text: '8：'},
    {id: '9', name: '', text: '9：'},
    {id: 'A', name: '', text: 'A：'},
    {id: 'B', name: '', text: 'B：'},
    {id: 'C', name: '', text: 'C：'},
    {id: 'D', name: '', text: 'D：'},
    {id: 'E', name: '', text: 'E：'},
    {id: 'F', name: '', text: 'F：'},
    {id: 'G', name: '', text: 'G：'},
    {id: 'H', name: '', text: 'H：'},
    {id: 'I', name: '', text: 'I：'},
    {id: 'J', name: '', text: 'J：'},
    {id: 'K', name: '', text: 'K：'},
    {id: 'L', name: '', text: 'L：'},
    {id: 'M', name: '', text: 'M：'},
    {id: 'N', name: '', text: 'N：'},
    {id: 'O', name: '', text: 'O：'},
    {id: 'P', name: '', text: 'P：'},
    {id: 'Q', name: '', text: 'Q：'},
    {id: 'R', name: '', text: 'R：'},
    {id: 'S', name: '', text: 'S：'},
    {id: 'T', name: '', text: 'T：'},
    {id: 'U', name: '', text: 'U：'},
    {id: 'V', name: '', text: 'V：'},
    {id: 'W', name: '', text: 'W：'},
    {id: 'X', name: '', text: 'X：'},
    {id: 'Y', name: '', text: 'Y：'},
    {id: 'Z', name: '', text: 'Z：'},
  ],
  // 配送種別区分
  ShippingTypeClass: {
    // 通常配送
    normal: '1',
    // 宅配
    delivery: '2',
    // 営業持参
    bring: '3',
    // 直送
    direct: '4',
  },
  // 配送チェックボックス
  DeliveryListVue:[
    {value: '1',text:'ルート配送'},
    {value: '2',text:'郵送(ヤマト、福山等)'},
    {value: '3',text:'営業持参'},
    {value: '4',text:'メーカー直送'},
  ],
  // 出荷メニュー配送チェックボックス
  ForwardingShippingTypeList:[
    {value: '1',text:'通常（ルート配送）'},
    {value: '2',text:'宅配'},
    {value: '3',text:'営業持参'},
  ],
  // 伝種（受注伝票種別区分）プルダウン
  OrderReceiveBillClassList:[
    {id: 0, name: '通常', text: '0:通常'},
    {id: 12, name: '返品', text: '12:返品'},
    {id: 15, name: '特別受注', text: '15:特別受注'},
  ],
  // 伝種（受注伝票種別区分）プルダウン
  OrderReceiveBillClass: {
    normal: 0,
    return: 12,
    special: 15,
  },
  // 伝票種別プルダウン
  TraditionList: [
    {id: 11, name: '通常売上', text: '11：通常売上'},
    {id: 12, name: '返品伝票', text: '12：返品伝票'},
    {id: 13, name: '入金', text: '13：入金'},
    {id: 14, name: '直送伝票', text: '14：直送伝票'},
    {id: 21, name: '仕入', text: '21：仕入'},
    {id: 22, name: '返品', text: '22：返品'},
    {id: 23, name: '支払', text: '23：支払'},
    {id: 24, name: '直送', text: '24：直送'},
    {id: 51, name: '調整入庫', text: '51：調整入庫'},
    {id: 52, name: '調整出庫', text: '52：調整出庫'},
    {id: 53, name: '移動', text: '53：移動'},
  ],
  // 伝票種別プルダウン
  TraditionListValue: [
    {value: 11, text: '11：通常売上'},
    {value: 12, text: '12：返品伝票'},
    {value: 13, text: '13：入金'},
    {value: 14, text: '14：直送伝票'},
    {value: 21, text: '21：仕入'},
    {value: 22, text: '22：返品'},
    {value: 23, text: '23：支払'},
    {value: 24, text: '24：直送'},
    {value: 51, text: '51：調整入庫'},
    {value: 52, text: '52：調整出庫'},
    {value: 53, text: '53：移動'},
  ],
  // 伝票種別
  Tradition: {
    // 通常売上
    salesNormal: 11,
    // 返品伝票
    salesReturn: 12,
    // 入金
    deposit: 13,
    // 直送伝票
    salesDirect: 14,
    // 仕入
    purchaseNormal: 21,
    // 返品
    purchaseReturn: 22,
    // 支払
    payment: 23,
    // 直送
    purchaseDirect: 24,
    // 調整入庫
    storageAdd: 51,
    // 調整出庫
    storageDel: 52,
    // 移動
    storageMove: 53,
  },
  // 受注の有無
  OrderReceiveUmuClassList:[
    {id: 0, text: '全て'},
    {id: 1, text: '受注あり'},
    {id: 2, text: '受注なし'},
  ],
  OrderReceiveUmuClass: {
    orderReceiveAri: 1,
    orderReceiveNashi: 2,
  },
  // 見積書・発注書ラジオボタン
  EstimateOrderList:[
    {id: 1, name: '見積書', text: '1：見積書'},
    {id: 2, name: '発注書', text: '2：発注書'},
  ],
  // 見積書・発注書
  EstimateOrder: {
    // 見積書
    estimate: 1,
    // 発注書
    order: 2,
  },
  // 見積種類ラジオボタン
  EstimateKindList:[
    {id: 1, name: '通常', text: '1：通常'},
    {id: 2, name: '単価見積り', text: '2：単価見積り'},
  ],
  // 見積種類
  EstimateKind: {
    // 通常
    normal: 1,
    // 単価見積り
    estimate: 2,
  },
  // 消費税区分ラジオボタン
  TaxTypeList:[
    {id: 1, name: '消費税あり', text: '1：消費税あり'},
    {id: 2, name: '消費税なし', text: '2：消費税なし'},
  ],
  // 消費税区分
  TaxType: {
    // 消費税あり
    tax: 1,
    // 消費税なし
    noTax: 2,
  },
  // 単価登録区分ラジオボタン
  UnitPriceRegisterClassList:[
    {id: 1, name: '登録しない', text: '1：登録しない'},
    {id: 2, name: '自身のみ登録', text: '2：自身のみ登録'},
    {id: 3, name: '親子全て登録', text: '3：親子全て登録'},
  ],
  // 単価登録区分
  UnitPriceRegisterClass: {
    // 登録しない
    insertNo: 1,
    // 自身のみ登録
    insertMine: 2,
    // 親子全て登録
    insertAll: 3,
  },
  // 金種種別プルダウン
  denominationList:[
    {id: 'A', name: '値引', text: 'A：値引'},
    {id: 'B', name: 'その他', text: 'B：その他'},
    {id: 'C', name: '消費税', text: 'C：消費税'},
    {id: 'D', name: '相殺消', text: 'D：相殺消'},
    {id: 'E', name: '手形消', text: 'E：手形消'},
    {id: '1', name: '現金', text: '1：現金'},
    {id: '2', name: '小切手', text: '2：小切手'},
    {id: '3', name: '当座', text: '3：当座'},
    {id: '4', name: '普通', text: '4：普通'},
    {id: '5', name: '手形', text: '5：手形'},
    {id: '6', name: '決済', text: '6：決済'},
    {id: '7', name: '相殺', text: '7：相殺'},
    {id: '8', name: '調整', text: '8：調整'},
    {id: '9', name: '手数料', text: '9：手数料'},
  ],
  // 金種
  denomination: {
    // 値引
    discount: 'A',
    // その他
    other: 'B',
    // 消費税
    tax: 'C',
    // 相殺消
    offsetTax: 'D',
    // 手形消
    billsTax: 'E',
    // 現金
    cash: '1',
    // 小切手
    check: '2',
    // 当座
    current: '3',
    // 普通
    normal: '4',
    // 手形
    bills: '5',
    // 決済
    settlement: '6',
    // 相殺
    offset: '7',
    // 調整
    adjustment: '8',
    // 手数料
    commission: '9',
  },
  // 手数料消費税の勘定一補助
  TaxCommission: '193-000',
  // 締日プルダウン
  closingDateList:[
    {value: '', text: '選択してください'},
    {value: '5', text: '5日'},
    {value: '10', text: '10日'},
    {value: '15', text: '15日'},
    {value: '20', text: '20日'},
    {value: '25', text: '25日'},
    {value: '99', text: '末日'},
  ],
  /** 取引区分プルダウン */
  businessClassList:[
    {id: '', name: '選択してください'},
    {id: '1', name: '現金'},
    {id: '2', name: '当座'},
    {id: '3', name: '普通'},
    {id: '4', name: '手形'},
  ],
  /** 取引区分プルダウン */
  billingOutputClassList:[
    {id: '0', name: '取引先別'},
    {id: '1', name: '現場別'},
  ],
  // 取引区分
  billingOutputClass: {
    // 取引先別
    clients: 0,
    // 現場別
    clientsSites: 1,
  },
  // 特別値引
  specialSalesDiscount: {
    // 得意先元帳の自動特別値引き用
    productId: '88888888',
    productName: '特別値引',
    productIdLightTax: '88888889',
    productNameLightTax: '特別値引（軽減税率）',
    productIdNoTax: '88888890',
    productNameNoTax: '特別値引（非課税）',
    honshaClientIdStart: 0,
    honshaClientIdEnd: 799999,
    yokohamaClientIdStart: 800000,
    yokohamaClientIdEnd: 999999,
    honshaOfficeId: 1,
    yokohamaOfficeId: 2,
  },
  bankNameList: [
    {key: '%当座銀行1%', value: '三菱ＵＦＪ・門前仲町0000623', textLength: 440},
    {key: '%当座銀行2%', value: '三井住友　・深　　川0007601', textLength: 440},
    {key: '%普通銀行1%', value: 'みずほ・深川1560615', textLength: 380},
    {key: '%普通銀行2%', value: '横　浜・東京1324208', textLength: 380},
    {key: '%普通銀行3%', value: 'りそな　・日本橋1066323', textLength: 380},
    {key: '%普通銀行4%', value: '商工中金・深川　1103652', textLength: 380},
  ],
  // 取引先製品マスタ登録区分ラジオボタン
  ClientsProductsMasterRegisterClassList:[
    {id: 0, text: '0：グループ'},
    {id: 1, text: '1：単独'},
  ],
  // 取引先製品マスタ登録区分
  ClientsProductsMasterRegisterClass: {
    // グループ
    group: 0,
    // 単独
    single: 1,
  },
  ADMIN_MAIL: 'yamashita_t01@pci-sol.com',
  // 発注書印字区分
  OrderPrintClassList: [
    { value: '0', text: '0：得意先名を印字する' },
    { value: '1', text: '1：仮伝名称を印字する'}
  ],
  // 発注書印字区分
  OrderPrintClass: {
    customerPrint: 0,
    customerNoPrint: 1,
  },
  // 請求書用印字区分
  BillingPrintClassList: [
    { value: '0', text: '0：得意先名を印字する' },
    { value: '1', text: '1：請求書用取引先名を印字する' }
  ],
  // DB が返すメッセージの識別用正規表現
  DbMessage: {
    DuplicatePrimaryKey: 'Duplicate entry \'.+\' for key \'PRIMARY\'; Error code: 1062; SQLState: 23000'
  },
  // 取引先区分
  ClientClassList: [
    { value: '1', text: '1：得意先' },
    { value: '2', text: '2：仕入先' }
  ],
  // 取引先区分
  ClientClass: {
    // 得意先
    customer: 1,
    // 仕入先
    supplier: 2,
  },
  // 伝票種類区分
  BillingTypeClassList: [
    { value: '0', text: '0：標準' },
    { value: '1', text: '1：特殊1' },
    { value: '2', text: '2：特殊2' },
    { value: '3', text: '3：特殊3' },
    { value: '4', text: '4：特殊4' }
  ],
  // 伝票種類区分
  BillingTypeClass: {
    // 標準
    normal: 0,
    // 特殊1
    special1: 1,
    // 特殊2
    special2: 2,
    // 特殊3
    special3: 3,
    // 特殊4
    special4: 4,
  },
  // 伝票種類吹き出し
  BillingTypeClassTitle: '0：標準\r\n　①納品書(正) ②納品書(副) ③受領書1 ④受領書2\r\n1：特殊1\r\n　①納品書(控) ②請求明細書 ③納品書(正) ④納品書(副) ⑤受領書1 ⑥受領書2\r\n2：特殊2\r\n　①納品書(正) ②納品書(正)：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟ㈱ ③納品書(副)：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟ㈱ ④受領書：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟ㈱\r\n3：特殊3\r\n　①納品書(正) ②納品書(副) ③受領書1 ④受領書2 ※「仮伝名称を印字する」場合、②～④は「取引先名」⇒「仮伝名称」\r\n4：特殊4\r\n　①納品書(正) ②納品書(正)：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟｸﾘｰﾝｻｰﾋﾞｽ㈱ ③納品書(副)：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟｸﾘｰﾝｻｰﾋﾞｽ㈱ ④受領書：エフティ販売⇒ｸﾞﾛｰﾌﾞｼｯﾌﾟｸﾘｰﾝｻｰﾋﾞｽ㈱',
  // 受注データの発注状況
  ReceivedOrderOrderJokyoList: [
    { id: 0, name: '在庫不要' },
    { id: 1, name: '在庫管理' },
    { id: 2, name: '発注未' },
    { id: 3, name: '発注済' },
    { id: 4, name: '入荷済' },
  ],
  // 受注データの発注状況
  ReceivedOrderOrderJokyo: {
    // 在庫不要
    noStock: 0,
    // 在庫管理
    stock: 1,
    // 発注未
    orderMi: 2,
    // 発注済
    orderZumi: 3,
    // 入荷済
    nyukaZumi: 4,
  },
  // 発注書発行区分
  OrderIssueClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
    // 済（強制）
    sumi_2: 2,
  },
  // 入荷区分
  StocksClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
  },
  // 出荷確定区分
  ShipsClassList: [
    { id: 0, name: '未' },
    { id: 1, name: '済' },
  ],
  // 出荷確定区分
  ShipsClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
  },
  // 伝票発行区分
  SalesIssueClassList: [
    { id: 0, name: '未' },
    { id: 1, name: '済' },
    { id: 2, name: '一部済' },
  ],
  // 伝票発行区分
  SalesIssueClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
    // 一部済
    middle: 2,
  },
  // 入荷確定区分
  StockConfirmClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
  },
  // 入荷予定日確定区分
  stockScheduledConfirmClass: {
    // 未
    mi: 0,
    // 済
    sumi: 1,
  },  
  // 明細単位区分
  DetailsUnitClass: {
    // 出荷確定入力
    manual: 0,
    // 自動出荷確定
    auto: 1,
  },
  // 納品書種類一覧
  BillingTypeNouhinList: [
    { id: 1, name: '納品書（正）', title: '納品書（正）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFDA0', color: '#000000' },
    { id: 2, name: '納品書（副）', title: '納品書（副）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFFFF', color: '#000000' },
    { id: 3, name: '納品書（控）', title: '納品書（控）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFFFF', color: '#000000' },
    { id: 4, name: '納品書（正）（相手先）', title: '納品書（正）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFDA0', color: '#000000' },
    { id: 5, name: '納品書（副）（相手先）', title: '納品書（副）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFFFF', color: '#000000' },
    { id: 6, name: '請求明細書', title: '請求明細書', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#C1FDFF', color: '#000000' },
    { id: 7, name: '受領書1', title: '受領書', text: '下記の通り受取りました', backcolor: '#FFF3FD', color: '#000000' },
    { id: 8, name: '受領書2', title: '受領書', text: '下記の通り受取りました', backcolor: '#FFF3FD', color: '#000000' },
    { id: 9, name: '受領書（相手先）', title: '受領書', text: '下記の通り受取りました', backcolor: '#FFF3FD', color: '#000000' },
    { id: 10, name: '不足伝票', title: '不足品案内', text: '下記の製品は未納品です。入荷次第お届けいたします。', backcolor: '#FFFFFF', color: '#000000' },
    { id: 11, name: '納品書（副）（発注区分）', title: '納品書（副）', text: '下記の通り納品致しました（金額に消費税は含まれておりません）', backcolor: '#FFFFFF', color: '#000000' },
    { id: 12, name: '受領書1（発注区分）', title: '受領書', text: '下記の通り受取りました', backcolor: '#FFF3FD', color: '#000000' },
    { id: 13, name: '受領書2（発注区分）', title: '受領書', text: '下記の通り受取りました', backcolor: '#FFF3FD', color: '#000000' },
    { id: 14, name: '不足伝票（発注区分）', title: '不足品案内', text: '下記の製品は未納品です。入荷次第お届けいたします。', backcolor: '#FFFFFF', color: '#000000' },
  ],
  // 納品書種類一覧
  BillingTypeClientDataList: [
    { id: 2, clientName: 'エフ・ティ販売', address: '東京都港区芝４丁目１１番３号', tel: '03 (6362) 9940', fax: '03 (3452) 5623', partnerName: 'グローブシップ株式会社' },
    { id: 4, clientName: 'エフ・ティ販売', address: '東京都港区芝４丁目１１番３号', tel: '03 (6362) 9940', fax: '03 (3452) 5623', partnerName: 'グローブシップクリーンサービス株式会社' },
  ],
  // 発注種別
  OrderClassList: [
    { id: 1, text: '1：受注発注' },
    { id: 2, text: '2：在庫切れ' },
    { id: 3, text: '3：直送発注' },
    { id: 4, text: '4：発注入力' },
    { id: 5, text: '5：特別発注' },
  ],
  // 発注種別
  OrderClass: {
    // 受注発注
    orderReceive: 1,
    // 在庫切れ
    noStock: 2,
    // 直送発注
    direct: 3,
    // 発注入力
    input: 4,
    // 特別発注
    special: 5,
  },
  // 入荷入力区分
  StockInputClassList: [
    { id: 1, name: '新規', text: '1：新規' },
    { id: 2, name: '訂正', text: '2：訂正' },
    { id: 3, name: '確定参照', text: '3：確定参照' },
  ],
  // 入荷入力区分
  StockInputClass: {
    // 新規
    new: 1,
    // 訂正
    edit: 2,
    // 確定参照
    confirm: 3,
  },
  // 入荷予定日確定区分
  stockScheduledConfirmClassList: [
    { id: 1, name: '全て', text: '1：全て' },
    { id: 2, name: '未', text: '2：未' },
    { id: 3, name: '済', text: '3：済' },
  ],
  // 入荷予定日確定区分
  stockScheduledConfirmInputClass: {
    // 全て
    all: 1,
    // 未
    mi: 2,
    // 済
    sumi: 3,
  },
  // 発注書回数区分
  OrderCountClassList: [
    { value: '0', text: '0：午前午後' },
    { value: '1', text: '1：午前' },
    { value: '2', text: '2：午後' }
  ],
  // サービス発注区分
  ServiceOrderClassList: [
    { value: '0', text: '0：サービス数を含めて発注' },
    { value: '1', text: '1：分けて発注' }
  ],
  // サービス発注区分
  ServiceOrderClass: {
    // サービス数を含めて発注
    include: 0,
    // 分けて発注
    split: 1,
  },
  // 諸口区分
  SundriesClassList: [
    { value: '0', text: '0：通常' },
    { value: '1', text: '1：諸口' }
  ],
  // 諸口区分
  SundriesClass: {
    // 通常
    normal: 0,
    // 諸口
    shokuchi: 1,
  },
  // 請求書出力単位区分
  BillingOutputClassList: [
    { value: '0', text: '0：取引先別' },
    { value: '1', text: '1：現場別' }
  ],
  // 請求書出力単位区分
  BillingOutputClass: {
    // 取引先別
    client: 0,
    // 現場別
    clientSite: 1,
  },
  // 請求書区分
  BillingClassList: [
    { value: '0', text: '0：出力する' },
    { value: '1', text: '1：出力しない' }
  ],
  // 請求書区分
  BillingClass: {
    // 出力する
    output: 0,
    // 出力しない
    noOutput: 1,
  },
  // 取引区分
  BusinessClassList: [
    { value: '1', text: '1：現金' },
    { value: '2', text: '2：当座' },
    { value: '3', text: '3：普通' },
    { value: '4', text: '4：手形' }
  ],
  // 入金（支払）区分
  PaymentClassList: [
    { value: '0', text: '0：当月末' },
    { value: '1', text: '1：翌月末' },
    { value: '2', text: '2：翌々月末' },
    { value: '3', text: '3：翌々々月末' }
  ],
  // 入金（支払）予定日
  PaymentDateList: [
    { value: '1', text: '1日' },
    { value: '2', text: '2日' },
    { value: '3', text: '3日' },
    { value: '4', text: '4日' },
    { value: '5', text: '5日' },
    { value: '6', text: '6日' },
    { value: '7', text: '7日' },
    { value: '8', text: '8日' },
    { value: '9', text: '9日' },
    { value: '10', text: '10日' },
    { value: '11', text: '11日' },
    { value: '12', text: '12日' },
    { value: '13', text: '13日' },
    { value: '14', text: '14日' },
    { value: '15', text: '15日' },
    { value: '16', text: '16日' },
    { value: '17', text: '17日' },
    { value: '18', text: '18日' },
    { value: '19', text: '19日' },
    { value: '20', text: '20日' },
    { value: '21', text: '21日' },
    { value: '22', text: '22日' },
    { value: '23', text: '23日' },
    { value: '24', text: '24日' },
    { value: '25', text: '25日' },
    { value: '26', text: '26日' },
    { value: '27', text: '27日' },
    { value: '28', text: '28日' },
    { value: '29', text: '29日' },
    { value: '30', text: '30日' },
    { value: '31', text: '31日' },
    { value: '99', text: '末日' }
  ],
  // 売上課税区分
  SalesTaxClassList: [
    { value: '0', text: '0：外税' },
    { value: '1', text: '1：内税' },
    { value: '2', text: '2：非課税' }
  ],
  // 売上課税区分
  SalesTaxClass: {
    outTax: 0,
    inTax: 1,
    noTax: 2,
  },
  // 税額計算区分
  TaxCalculationClassList: [
    { value: '0', text: '0：請求' },
    { value: '1', text: '1：伝票' }
  ],
  // 税額計算区分
  TaxCalculationClass: {
    // 請求
    invoice: 0,
    // 伝票
    salesSlip: 1,
  },
  // 税額端数処理区分
  TaxFractionationClassList: [
    { value: '0', text: '0：切り捨て' },
    { value: '5', text: '5：四捨五入' },
    { value: '9', text: '9：切り上げ' }
  ],
  // 税額端数処理区分
  TaxFractionationClass: {
    // 切り捨て
    truncate: 0,
    // 四捨五入
    round: 5,
    // 切り上げ
    roundUp: 9,
  },
  // カレンダーイベント区分
  CalendarEventClass: {
    Holiday: 1 // 休業日
  },
  // 休日の曜日一覧
  WeekHolidayList: [
    {value: 0, name: '日'},
    {value: 6, name: '土'},
  ],
  // 製品チェック区分
  ProductCheckClass: [
    { value: '0', text: '0:不要', color: '' },
    { value: '1', text: '1:緑', color: 'success' },
    { value: '2', text: '2:黄', color: 'warning' },
    { value: '3', text: '3:赤', color: 'danger' },
    { value: '4', text: '4:青', color: 'primary' }
  ],
  // 9A製品チェック区分
  ProductCheckClass9A: [
    { value: '0', text: '0:不要' },
    { value: '1', text: '1:要' },
  ],
  // 在庫管理区分
  InventoryControlClass: [
    { value: '0', text: '0:する' },
    { value: '1', text: '1:しない' }
  ],
  // 在庫管理区分
  InventoryControlClassDef: {
    // する
    inventory: 0,
    // しない
    noInventory: 1,
  },
  // ピッキング印刷区分
  PickingPrintClass: [
    { value: '0', text: '0:印刷する' },
    { value: '1', text: '1:しない' }
  ],
  // ピッキング印刷区分
  PickingPrintClassDef: {
    // 印刷する
    pick: 0,
    // しない
    noPick: 1,
  },
  // ケース換算区分
  CaseConversionClass: [
    { value: '0', text: '0:換算する' },
    { value: '1', text: '1:しない' }
  ],
  // ケース換算区分（定数）
  CaseConversionClassDef: {
    // 換算する
    conversion: 0,
    // しない
    noConversion: 1,
  },
  // セット品区分
  SetClass: {
    noSet: 0,
    set: 1,
  },
  // サービス有無区分
  ServiceWhetherClass: [
    { value: '0', text: '0:有り' },
    { value: '1', text: '1:なし' }
  ],
  // 受注照会区分
  OrderReceiveInquiryClassList: [
    { id: 1, name: 'チェック用' },
    { id: 2, name: '製品別' },
  ],
  // 受注照会区分
  OrderReceiveInquiryClass: {
    // チェック用
    check: 1,
    // 製品別
    product: 2,
  },
  // 残区分
  RemainingClassList: [
    { id: 1, text: '1：全件' },
    { id: 2, text: '2：残分のみ' },
  ],
  // 残区分
  RemainingClass: {
    // 全件
    all: 1,
    // 残分のみ
    remaining: 2,
  },
  // 製品親子区分値（セット、ケース、バラ）
  ProductParentClass: {
    // セット製品
    set: 'ｾｯﾄ',
    // 部材製品
    parts: '部材',
    // ケース製品
    case: 'ｹｰｽ',
    // バラ製品
    loose: 'ﾊﾞﾗ',
    // グループ製品
    group: 'ｸﾞﾙｰﾌﾟ',
    // 上記以外
    else: '',
  },
  // カウンター区分
  CounterClass: {
    // 伝票番号
    billingNo: 1,
    // 注文番号
    orderNumber: 2,
    // 仮伝票番号
    tentativeBillingNo: 3,
    // 取引先製品CSVワークID
    clientProductCsvId: 4,
    // 分割請求書番号
    separateInvoiceNo: 5,
    // 電子書類データID
    electronicDocumentsId: 6,
  },
  // カウンター区分リスト
  CounterClassList: [
    { id: 1, name: '伝票番号', text: '1:伝票番号' },
    { id: 2, name: '注文番号', text: '2:注文番号' },
    { id: 3, name: '仮伝票番号', text: '3:仮伝票番号' },
    { id: 4, name: '取引先製品CSVワークID', text: '4:取引先製品CSVワークID' },
    { id: 5, name: '分割請求書番号', text: '5:分割請求書番号' },
  ],
  // トランザクションID
  TransactionId: {
    // 売上
    sales: 'UR',
    // 仕入
    stocking: 'SI',
    // 入金
    deposit: 'NK',
    // 支払
    payment: 'SK',
    // 入出庫
    storage: 'NS',
  },
  // トランザクションIDリスト
  TransactionIdList: [
    { id: 'UR', name: '売上', text: 'UR:売上' },
    { id: 'SI', name: '仕入', text: 'SI:仕入' },
    { id: 'NK', name: '入金', text: 'NK:入金' },
    { id: 'SK', name: '支払', text: 'SK:支払' },
    { id: 'NS', name: '入出庫', text: 'NS:入出庫' },
  ],
  // 月次更新の状態区分
  MonthlyUpdateStateClass: {
    NOT_RUNNING: 0, // 停止中
    RUNNING: 1, // 実行中
    ERROR_STOP: 2 // エラーで停止中
  },
  // 振り込み手数料の閾値
  transferFee: [
    // 振り込み手数料（～3万円）
    { id: 0, value: 30000, text: '合計金額3万円未満の振り込み手数料です。' },
    // 振り込み手数料2（3～5万円まで）    
    { id: 1, value: 50000, text: '合計金額3万円～5万円未満の振り込み手数料です。' },
    // 振り込み手数料3（5万円～）    
    { id: 2, value: 50000, text: '合計金額5万円以上の振り込み手数料です。' },
  ],
  Calendar: {
    // 今日を選択するボタン名
    todayButtonName: '今日を選択',
    // 日付クリアボタンのボタン名
    clearButtonName: '未選択',
  },
  // 帳票カウンタマスタ最大桁数
  billingMaxLength : 7,
  // 取引先コード切替状態区分
  ClientCodeSwitchStateClass: {
    NOT_RUNNING: 0, // 停止中
    RUNNING: 1, // 実行中
    ERROR_STOP: 2 // エラーで停止中
  },
  // 製品コード切替状態区分
  ProductCodeSwitchStateClass: {
    NOT_RUNNING: 0, // 停止中
    RUNNING: 1, // 実行中
    ERROR_STOP: 2 // エラーで停止中
  },
  // 締更新状態状態区分
  ClosingUpdateStateClass: {
    NOT_RUNNING: 0, // 停止中
    RUNNING: 1, // 実行中
  },
  // 請求支払区分一覧（月次更新で見るのは以下の２区分のため、請求2は除外）
  BillingPaymentClassList: [
    { id: 0, name: '請求' },
    { id: 1, name: '支払' },
  ],
  // 請求支払区分
  BillingPaymentClass: {
    // 請求
    billing: 0,
    // 支払
    payment: 1,
    // 請求2
    billing2: 2,
  },
  // DB更新SQLの上限（SQLサイズ65535超過で失敗するため、上限を超過しそうな箇所は制御を入れる）
  SqlMaxLength: 60000,
  // 9A製品の製品分類コード範囲
  ProductClassIdRange9A: {
    MIN: 9000, // 最小値
    MAX: 9999 // 最大値
  },
  // 文字の順番（範囲指定時等で使用）
  Char: {
    halfMin: ' ', // 半角の最小文字
    halfMax: 'ﾟ', // 半角の最大文字
  },
  // 大量データ取得関数用の一度のSELECT文の取得件数
  SelectLimit: 4000,
  // PDF出力取引先最大件数（得意先元帳発行）
  PdfCustomerLedgerClientMaxCnt: 400,
  // PDF出力取引先最大件数（得意先元帳発行、仕入先元帳発行）
  PdfSupplierLedgerClientMaxCnt: 100,
  // PDF出力取引先最大件数（請求書）
  PdfSeikyuClientMaxCnt: 300,
  // PDF出力取引先最大件数（請求書別タブ）
  PdfTabSeikyuClientMaxCnt: 200,
  // PCA会計の金種の区分
  PcaKindClass: {
    // 消費税（CDE）以外
    noTax: 'NOTAX',
    // 消費税（CDE）
    tax: 'TAX',
  },
  // PCA会計の営業所
  PcaOfficeClass: {
    // 横浜
    yokohama: '8',
    // 上記以外
    else: '1',
  },
  // PCA会計用データ一覧の入金、勘定科目、金種毎の固定値
  PcaAccountKindDepositList: [
    // 勘定科目（193）、金種（B）、営業所:横浜以外
    {account: '193', kind: 'B', office: '1', debitTaxClass: 'Q5', creditAccount: '152', creditTaxClass: 'Y5'},
    // 勘定科目（193）、金種（B）、営業所:横浜
    {account: '193', kind: 'B', office: '8', debitTaxClass: 'Q5', creditAccount: '153', creditTaxClass: 'Y5'},
    // 勘定科目（193）、金種（C）、営業所:横浜以外
    {account: '193', kind: 'C', office: '1', debitTaxClass: 'Q5', creditAccount: '152', creditTaxClass: 'Y5'},
    // 勘定科目（193）、金種（C）、営業所:横浜
    {account: '193', kind: 'C', office: '8', debitTaxClass: 'Q5', creditAccount: '153', creditTaxClass: 'Y5'},
    // 勘定科目（741）、金種（9）、営業所:横浜以外
    {account: '741', kind: '9', office: '1', debitTaxClass: 'Q5', creditAccount: '152', creditTaxClass: 'Y5'},
    // 勘定科目（741）、金種（9）、営業所:横浜
    {account: '741', kind: '9', office: '8', debitTaxClass: 'Q5', creditAccount: '153', creditTaxClass: 'Y5'},
    // 勘定科目（111）、金種（消費税以外）、営業所:指定無し
    {account: '111', kind: 'NOTAX', office: '*', debitTaxClass: '0', creditAccount: '152', creditTaxClass: 'Y5'},
    // 勘定科目（112）、金種（消費税以外）、営業所:指定無し
    {account: '112', kind: 'NOTAX', office: '*', debitTaxClass: '0', creditAccount: '153', creditTaxClass: 'Y5'},
    // 勘定科目（上記以外）、金種（消費税以外）、営業所:横浜以外
    {account: '*', kind: 'NOTAX', office: '1', debitTaxClass: '0', creditAccount: '152', creditTaxClass: 'Y5'},
    // 勘定科目（上記以外）、金種（消費税以外）、営業所:横浜
    {account: '*', kind: 'NOTAX', office: '8', debitTaxClass: '0', creditAccount: '153', creditTaxClass: 'Y5'},
    // 勘定科目（上記以外）、金種（消費税）、営業所:指定無し
    {account: '*', kind: 'TAX', office: '*', debitTaxClass: '0', creditAccount: '335', creditTaxClass: 'B5'},
  ],
  // PCA会計用データ一覧の支払、勘定科目、金種毎の固定値
  PcaAccountKindPaymentList: [
    // 勘定科目（134）、金種（C）
    {account: '134', kind: 'C', debitAccount: '312', debitTaxClass: '0', creditTaxClass: '0'},
    // 勘定科目（152）、金種（7）
    {account: '152', kind: '7', debitAccount: '312', debitTaxClass: '0', creditTaxClass: 'Y5'},
    // 勘定科目（152）、金種（A）
    {account: '152', kind: 'A', debitAccount: '312', debitTaxClass: '0', creditTaxClass: 'Y5'},
    // 勘定科目（335）、金種（C）
    {account: '335', kind: 'C', debitAccount: '193', debitTaxClass: 'Q5', creditTaxClass: 'B5'},
    // 勘定科目（335）、金種（D）
    {account: '335', kind: 'D', debitAccount: '193', debitTaxClass: 'Q5', creditTaxClass: 'B5'},
    // 勘定科目（上記以外）、金種（消費税以外）
    {account: '*', kind: 'NOTAX', debitAccount: '312', debitTaxClass: '0', creditTaxClass: '0'},
    // 勘定科目（上記以外）、金種（消費税）
    {account: '*', kind: 'TAX', debitAccount: '193', debitTaxClass: 'Q5', creditTaxClass: '0'},
  ],
  // 棚卸製品：追加フラグ
  InventoryInputAddFlag: {
    existing: 0, // 棚卸前処理で抽出された製品
    added: 1 // 追加された製品
  },
  // 棚卸製品：入力済みフラグ
  InventoryInputFinishedFlag: {
    unfinished: 0, // 未済
    finished: 1 // 済
  },
  // 処理区分（排他管理マスタ）
  ProcessClass: {
    // 発注書（入力分）発行
    orderInput: 1,
  },
  // 製品消費税率区分
  ProductTaxRateClass: {
    // 通常消費税
    normalTax: 0,
    // 軽減消費税
    lightTax: 1,
    // 非課税
    noTax: 2,
  },
  // 軽減税率吹き出し
  LightTaxRateTooltip: '軽減税率対象製品',
  // 製品消費税率区分リスト
  ProductTaxRateClassList: [
    { id: 0, name: '通常消費税', text: '0：通常消費税' },
    { id: 1, name: '軽減消費税', text: '1：軽減消費税' },
    { id: 2, name: '非課税', text: '2：非課税' },
  ],
  // 請求書発行登録区分
  InvoiceIssueClass: {
    // 全て
    all: 0,
    // 未
    noIssue: 1,
    // 済
    issue: 2,
  },
  // 請求書発行登録区分リスト
  InvoiceIssueClassList: [
    {value: 0, text: '全て'},
    {value: 1, text: '未'},
    {value: 2, text: '済'}
  ],
  // WEB発行区分
  WebIssueClass: {
    // WEB発行しない
    noIssue: 0,
    // WEB発行する
    issue: 1,
  },
  // WEB発行区分リスト
  WebIssueClassList: [
    { id: '0', name: 'WEB発行しない', text: '0：WEB発行しない' },
    { id: '1', name: 'WEB発行する', text: '1：WEB発行する' },
  ],
  // 請求書分割区分
  SeparateBillingClass: {
    // 伝票毎
    billings: '0',
    // 現場毎
    sites: '1',
  },
  // 請求書分割区分リスト
  SeparateBillingClassList: [
    {value: '0', text: '伝票毎'},
    {value: '1', text: '現場毎'}
  ],
  // 請求書発行方法
  InvoiceIssueWay: {
    // 全て
    all: '0',
    // 全伝票同時発行取引先
    allIssue: '1',
    // 一部伝票先行発行取引先
    partIssue: '2',
  },
  // 請求書発行方法リスト
  InvoiceIssueWayList: [
    {id: '0', text: '全て'},
    {id: '1', text: '全伝票同時発行取引先'},
    {id: '2', text: '一部伝票先行発行取引先'},
  ],
  // 請求一覧計算区分
  InvoiceListCalcClass: {
    // 全請求書合計
    all: '0',
    // 先行発行請求書を除外した合計
    exclude: '1',
  },
  // 請求一覧計算区分リスト
  InvoiceListCalcClassList: [
    {id: '0', text: '全請求書合計'},
    {id: '1', text: '先行発行請求書を除外した合計'},
  ],
  // 電子書類種類
  DocumentsKind: {
    // 電子書類
    electronicDocuments: '1',
    // 請求書
    seikyu: '2',
  },
  // 電子書類種類リスト
  DocumentsKindList: [
    {id: '1', text: '電子書類', key: 'ElectronicDocuments'},
    {id: '2', text: '請求書', key: 'Seikyu'},
  ],
  // 書類アップロード方法
  DocumentsUploadWay: {
    // 行毎アップロード
    everyRow: '1',
    // ページ分割アップロード
    pageSplit: '2',
  },
  // 書類アップロード方法リスト
  DocumentsUploadWayList: [
    {id: '1', text: '行毎アップロード'},
    {id: '2', text: 'ページ分割アップロード'},
  ],
  // 通常分割区分
  NormalSeparateClass: {
    // 通常
    normal: '0',
    // 分割
    separate: '1',
  },
  // 通常分割区分
  NormalSeparateClassList: [
    {id: '0', name: '通常'},
    {id: '1', name: '分割'},
  ],
  // 請求重複区分
  BillingDuplicateClass: {
    // 重複無し
    none: 0,
    // 重複（取引先別の通常）
    clientsNormal: 1,
    // 重複（取引先別の分割）
    clientsSeparate: 2,
    // 重複（現場別の現場全体）
    sitesAll: 3,
    // 重複（現場別の伝票毎）
    sitesBillings: 4,
  },
}
/**
 * 認証情報の有効期限日数（アプリでキャッシュする期間）
 * ここで指定された日数連続でアクセスがないとログアウトされる
*/
export const AUTH_EXPIRATION_DAYS = 7;

/**
 * 担当者登録完了メールのログインURL
 */
export const LOGIN_URL = 'https://enterprisesystem.sanwa-clean.net';

/**
 * 担当者登録完了メールの送信元アドレス
 */
export const REGISTER_COMPLETE_FROM_ADDRESS = 'support-sanwa-enterprisesystem@pci-sol.com';

/**
 * 担当者登録完了メールのタイトル
 */
export const REGISTER_COMPLETE_MAIL_SUBJECT = '販売管理システム担当者登録完了のお知らせ';

/**
 * ログメッセージの最大長
 */
export const MAX_LENGTH_LOG_MESSAGE = 10000;

/**
 * クエリリクエストのタイプ
 */
export const QUERY_REQUEST_TYPE = {
  SIMPLE_QUERY: 0, // クエリ結果をS3にJSONで保存
  QUERY_FOR_CREATE_CSV: 1 // CSV出力を前提としたチェックをした上でクエリ結果をS3に保存
}

/**
 * クエリリクエストのタイプ
 */
export const TRANSACT_SQLS_REQUEST_TYPE = {
  DIRECT: 0, // 実行するSQLをメッセージに持たせる
  SAVE_SQLS_ON_S3: 1 // 実行するSQLをJSONファイルとしてS3に保存する
}