import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth, Cache } from 'aws-amplify'
import store from '@/store'
import { AUTH_EXPIRATION_DAYS } from '@/assets/js/const';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/menu',
    name: 'ROOT',
  },
  {
    path: '/login',
    name: 'LOGIN',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: {
      title: 'サンワ販売管理システム | ログイン',
      desc: '販売管理システムのログイン画面です',
      type: 'login',
      isPublic: true,
    },
  },
  {
    path: '/menu',
    name: 'MENU',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu.vue'),
    meta: {
      title: '販売管理システム｜メインメニュー',
      desc: '販売管理システムのメインメニューです。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-daily',
    name: 'MENU-SALES-DAILY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-daily.vue'),
    meta: {
      title: '販売管理システム｜日次処理メニュー',
      desc: '販売管理システムの日次処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/deposit-list',
    name: 'DEPOSIT-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/deposit-list.vue'),
    meta: {
      title: '販売管理システム｜入金 一覧/入力/修正/削除',
      desc: '販売管理システムの入金 一覧/入力/修正/削除です。',
      type: 'user',
    },
  },
  {
    path: '/deposit-input',
    name: 'DEPOSIT-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/deposit-input.vue'),
    meta: {
      title: '販売管理システム｜入金入力',
      desc: '販売管理システムの入金入力です。',
      type: 'user',
    },
  },
  {
    path: '/deposit-edit',
    name: 'DEPOSIT-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/deposit-edit.vue'),
    meta: {
      title: '販売管理システム｜入金修正',
      desc: '販売管理システムの入金修正です。',
      type: 'user',
    },
  },
  {
    path: '/payment-list',
    name: 'PAYMENT-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/payment-list.vue'),
    meta: {
      title: '販売管理システム｜支払 一覧/入力/修正/削除',
      desc: '販売管理システムの支払 一覧/入力/修正/削除です。',
      type: 'user',
    },
  },
  {
    path: '/payment-edit',
    name: 'PAYMENT-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/payment-edit.vue'),
    meta: {
      title: '販売管理システム｜支払入力/修正',
      desc: '販売管理システムの支払入力/修正です。',
      type: 'user',
    },
    props:true
  },  
  {
    path: '/loading-unloading-list',
    name: 'LOADING-UNLOADING-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/loading-unloading-list.vue'),
    meta: {
      title: '販売管理システム｜入出庫 一覧/入力/照会',
      desc: '販売管理システムの入出庫 一覧/入力/照会です。',
      type: 'user',
    },
  },
  {
    path: '/loading-unloading-input',
    name: 'LOADING-UNLOADING-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/loading-unloading-input.vue'),
    meta: {
      title: '販売管理システム｜入出庫 一覧/入力',
      desc: '販売管理システムの入出庫 一覧/入力です。',
      type: 'user',
    },
  },
  {
    path: '/loading-unloading-inquiry',
    name: 'LOADING-UNLOADING-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/loading-unloading-inquiry.vue'),
    meta: {
      title: '販売管理システム｜入出庫 一覧/照会',
      desc: '販売管理システムの入出庫 一覧/照会です。',
      type: 'user',
    },
  },
  {
    path: '/sales-list',
    name: 'SALES-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-list.vue'),
    meta: {
      title: '販売管理システム｜売上 一覧/修正/削除',
      desc: '販売管理システムの売上 一覧/修正/削除です。',
      type: 'user',
    },
  },
  {
    path: '/sales-edit',
    name: 'SALES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-edit.vue'),
    meta: {
      title: '販売管理システム｜売上修正',
      desc: '販売管理システムの売上修正です。',
      type: 'user',
    },
  },
  {
    path: '/tentative-sales-list',
    name: 'TENTATIVE-SALES-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/tentative-sales-list.vue'),
    meta: {
      title: '販売管理システム｜(仮伝票)売上入力変更・削除（仮伝票発行あり）',
      desc: '販売管理システムの(仮伝票)売上入力変更・削除（仮伝票発行あり）です。',
      type: 'user',
    },
  },
  {
    path: '/tentative-sales-input',
    name: 'TENTATIVE-SALES-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/tentative-sales-input.vue'),
    meta: {
      title: '販売管理システム｜売上入力（仮伝票）',
      desc: '販売管理システムの売上入力（仮伝票）です。',
      type: 'user',
    },
  },
  {
    path: '/tentative-sales-edit',
    name: 'TENTATIVE-SALES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/tentative-sales-edit.vue'),
    meta: {
      title: '販売管理システム｜売上修正（仮伝票）',
      desc: '販売管理システムの売上修正（仮伝票）です。',
      type: 'user',
    },
  },
  {
    path: '/tentative-invoice-list',
    name: 'TENTATIVE-INVOICE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/tentative-invoice-list.vue'),
    meta: {
      title: '販売管理システム｜請求一覧表（仮伝票）',
      desc: '販売管理システムの請求一覧表（仮伝票）です。',
      type: 'user',
    },
  },
  {
    path: '/tentative-seikyu',
    name: 'TENTATIVE-SEIKYU',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/tentative-seikyu.vue'),
    meta: {
      title: '請求書（仮伝票） - 印刷プレビュー',
      desc: '販売管理システムの請求書（仮伝票）印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/sales-direct-list',
    name: 'SALES-DIRECT-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-direct-list.vue'),
    meta: {
      title: '販売管理システム｜直送売上 一覧/入力/修正/削除',
      desc: '販売管理システムの直送売上 一覧/入力/修正/削除です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/sales-direct-input-no-order',
    name: 'SALES-DIRECT-INPUT-NO-ORDER',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-direct-input-no-order.vue'),
    meta: {
      title: '販売管理システム｜直送売上（受注なし）入力',
      desc: '販売管理システムの直送売上（受注なし）入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/sales-direct-edit-no-order',
    name: 'SALES-DIRECT-EDIT-NO-ORDER',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-direct-edit-no-order.vue'),
    meta: {
      title: '販売管理システム｜直送売上修正',
      desc: '販売管理システムの直送売上修正です。',
      type: 'user',
    },
  },
  {
    path: '/pca-account-list',
    name: 'PCA-ACCOUNT-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/pca-account-list.vue'),
    meta: {
      title: '販売管理システム｜PCA会計用データ一覧',
      desc: '販売管理システムのPCA会計用データ一覧です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-list',
    name: 'PURCHASE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase-list.vue'),
    meta: {
      title: '販売管理システム｜仕入（在庫なし） 一覧/入力/修正/削除',
      desc: '販売管理システムの仕入（在庫なし） 一覧/入力/修正/削除です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-input',
    name: 'PURCHASE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase-input.vue'),
    meta: {
      title: '販売管理システム｜仕入（在庫なし）入力',
      desc: '販売管理システムの仕入（在庫なし）入力です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-edit',
    name: 'PURCHASE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase-edit.vue'),
    meta: {
      title: '販売管理システム｜仕入（在庫なし）修正',
      desc: '販売管理システムの仕入（在庫なし）修正です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-claim',
    name: 'MENU-SALES-CLAIM',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-claim.vue'),
    meta: {
      title: '販売管理システム｜請求締次処理メニュー',
      desc: '販売管理システムの請求締次処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/pre-invoice-process',
    name: 'PRE-INVOICE-PROCESS',
    component: () => import(/* webpackChunkName: "about" */ '../views/pre-invoice-process.vue'),
    meta: {
      title: '販売管理システム｜請求前処理（本社）',
      desc: '販売管理システムの請求前処理（本社）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-list',
    name: 'INVOICE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-list.vue'),
    meta: {
      title: '販売管理システム｜請求一覧表（本社）',
      desc: '販売管理システムの請求一覧表（本社）です。',
      type: 'user',
    },
  },
  {
    path: '/seikyu-list',
    name: 'SEIKYU-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/seikyu-list.vue'),
    meta: {
      title: '請求一覧表 - 印刷プレビュー',
      desc: '販売管理システムの請求一覧表印刷です。',
      type: 'user',
    },
  },
  {
    path: '/seikyu',
    name: 'SEIKYU',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/seikyu.vue'),
    meta: {
      title: '請求書 - 印刷プレビュー',
      desc: '販売管理システムの請求書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/invoice-search',
    name: 'INVOICE-SEARCH',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-search.vue'),
    meta: {
      title: '販売管理システム｜得意先元帳発行',
      desc: '販売管理システムの得意先元帳発行です。',
      type: 'user',
    },
  },
  {
    path: '/customer-ledger',
    name: 'CUSTOMER-LEDGER',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/customer-ledger'),
    meta: {
      title: '得意先元帳 - 印刷プレビュー',
      desc: '販売管理システムの得意先元帳印刷です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-check',
    name: 'INVOICE-CHECK',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-check.vue'),
    meta: {
      title: '販売管理システム｜請求マスタ状況チェック（本社）',
      desc: '販売管理システムの請求マスタ状況チェック（本社）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-update',
    name: 'INVOICE-UPDATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-update.vue'),
    meta: {
      title: '販売管理システム｜請求締更新',
      desc: '販売管理システムの請求締更新です。',
      type: 'user',
    },
  },
  {
    path: '/closing-update-completion-list',
    name: 'CLOSING-UPDATE-COMPLETION-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/closing-update-completion-list.vue'),
    meta: {
      title: '販売管理システム｜締更新完了一覧',
      desc: '販売管理システムの締更新完了一覧です。',
      type: 'user',
    },
  },
  {
    path: '/pre-invoice-process2',
    name: 'PRE-INVOICE-PROCESS2',
    component: () => import(/* webpackChunkName: "about" */ '../views/pre-invoice-process.vue'),
    meta: {
      title: '販売管理システム｜請求前処理２（横浜）',
      desc: '販売管理システムの請求前処理２（横浜）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-list2',
    name: 'INVOICE-LIST2',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-list.vue'),
    meta: {
      title: '販売管理システム｜請求一覧表２（横浜）',
      desc: '販売管理システムの請求一覧表２（横浜）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-check2',
    name: 'INVOICE-CHECK2',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-check.vue'),
    meta: {
      title: '販売管理システム｜請求マスタ状況チェック２（横浜）',
      desc: '販売管理システムの請求マスタ状況チェック２（横浜）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-list-separate',
    name: 'INVOICE-LIST-SEPARATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-list-separate.vue'),
    meta: {
      title: '販売管理システム｜請求書発行（現場・伝票毎）（本社）',
      desc: '販売管理システムの請求書発行（現場・伝票毎）（本社）です。',
      type: 'user',
    },
  },
  {
    path: '/invoice-list-separate2',
    name: 'INVOICE-LIST-SEPARATE2',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-list-separate.vue'),
    meta: {
      title: '販売管理システム｜請求書発行（現場・伝票毎）（横浜）',
      desc: '販売管理システムの請求書発行（現場・伝票毎）（横浜）です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-pay',
    name: 'MENU-SALES-PAY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-pay.vue'),
    meta: {
      title: '販売管理システム｜支払締次処理メニュー',
      desc: '販売管理システムの支払締次処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/pre-payment-process',
    name: 'PRE-PAYMENT-PROCESS',
    component: () => import(/* webpackChunkName: "about" */ '../views/pre-payment-process.vue'),
    meta: {
      title: '販売管理システム｜支払前処理',
      desc: '販売管理システムの支払前処理です。',
      type: 'user',
    },
  },
  {
    path: '/payment-search',
    name: 'PAYMENT-SEARCH',
    component: () => import(/* webpackChunkName: "about" */ '../views/payment-search.vue'),
    meta: {
      title: '販売管理システム｜仕入先元帳発行',
      desc: '販売管理システムの仕入先元帳発行です。',
      type: 'user',
    },
  },
  {
    path: '/supplier-ledger',
    name: 'SUPPLIER-LEDGER',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/supplier-ledger'),
    meta: {
      title: '仕入先元帳 - 印刷プレビュー',
      desc: '販売管理システムの仕入先元帳印刷です。',
      type: 'user',
    },
  },
  {
    path: '/payment-check',
    name: 'PAYMENT-CHECK',
    component: () => import(/* webpackChunkName: "about" */ '../views/payment-check.vue'),
    meta: {
      title: '販売管理システム｜支払マスタ状況チェック',
      desc: '販売管理システムの支払マスタ状況チェックです。',
      type: 'user',
    },
  },
  {
    path: '/payment-update',
    name: 'PAYMENT-UPDATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/payment-update.vue'),
    meta: {
      title: '販売管理システム｜支払締更新',
      desc: '販売管理システムの支払締更新です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-monthly',
    name: 'MENU-SALES-MONTHLY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-monthly.vue'),
    meta: {
      title: '販売管理システム｜月次処理メニュー',
      desc: '販売管理システムの月次処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/accounts-receivable-supply-list',
    name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/accounts-receivable-supply-list.vue'),
    meta: {
      title: '販売管理システム｜得意先別売掛実績表',
      desc: '販売管理システムの得意先別売掛実績表です。',
      type: 'user',
    },
  },
  {
    path: '/accounts-receivable-supply-list-print',
    name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/accounts-receivable-supply-list-print.vue'),
    meta: {
      title: '販売管理システム｜得意先別売掛実績表 - 印刷プレビュー',
      desc: '販売管理システムの得意先別売掛実績表印刷です。',
      type: 'user',
    },
    props: true
  },
  {
    path: '/receivable-balance-result-maintenance',
    name: 'RECIVABLE-BALANCE-RESULT-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/receivable-balance-result-maintenance.vue'),
    meta: {
      title: '販売管理システム｜売掛実績・残高保守',
      desc: '販売管理システムの売掛実績・残高保守です。',
      type: 'user',
    },
  },
  {
    path: '/accounts-payable-supply-list',
    name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/accounts-payable-supply-list.vue'),
    meta: {
      title: '販売管理システム｜仕入先別買掛実績表',
      desc: '販売管理システムの仕入先別買掛実績表です。',
      type: 'user',
    },
  },
  {
    path: '/accounts-payable-supply-list-print',
    name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/accounts-payable-supply-list-print.vue'),
    meta: {
      title: '販売管理システム｜仕入先別買掛実績表 - 印刷プレビュー',
      desc: '販売管理システムの仕入先別買掛実績表印刷です。',
      type: 'user',
    },
    props: true
  },
  {
    path: '/payable-balance-result-maintenance',
    name: 'PAYABLE-BALANCE-RESULT-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/payable-balance-result-maintenance.vue'),
    meta: {
      title: '販売管理システム｜買掛実績・残高保守',
      desc: '販売管理システムの買掛実績・残高保守です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-unit-price-list',
    name: 'PURCHASE-UNIT-PRICE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase-unit-price-list.vue'),
    meta: {
      title: '販売管理システム｜仕入単価修正',
      desc: '販売管理システムの仕入単価修正です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-unit-price-edit',
    name: 'PURCHASE-UNIT-PRICE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase-unit-price-edit.vue'),
    meta: {
      title: '販売管理システム｜仕入単価修正',
      desc: '販売管理システムの仕入単価修正です。',
      type: 'user',
    },
  },
  {
    path: '/monthly-update',
    name: 'MONTHLY-UPDATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/monthly-update.vue'),
    meta: {
      title: '販売管理システム｜月次更新',
      desc: '販売管理システムの月次更新です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-ordership',
    name: 'MENU-SALES-ORDERSHIP',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-ordership.vue'),
    meta: {
      title: '販売管理システム｜受注・出荷処理メニュー',
      desc: '販売管理システムの受注・出荷処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/received-order-list',
    name: 'RECEIVED-ORDER-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-list.vue'),
    meta: {
      title: '販売管理システム｜受注一覧',
      desc: '販売管理システムの受注一覧です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/received-order-input',
    name: 'RECEIVED-ORDER-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-input.vue'),
    meta: {
      title: '販売管理システム｜受注入力',
      desc: '販売管理システムの受注入力です。',
      type: 'user',
    },
  },
  {
    path: '/received-order-input-inquiry',
    name: 'RECEIVED-ORDER-INPUT-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-input-inquiry.vue'),
    meta: {
      title: '販売管理システム｜受注入力照会',
      desc: '販売管理システムの受注入力照会です。',
      type: 'user',
    },
  },
  {
    path: '/received-order-edit',
    name: 'RECEIVED-ORDER-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-edit.vue'),
    meta: {
      title: '販売管理システム｜受注修正',
      desc: '販売管理システムの受注修正です。',
      type: 'user',
    },
  },
  {
    path: '/scheduled-stock-inquiry',
    name: 'SCHEDULED-STOCK-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/scheduled-stock-inquiry.vue'),
    meta: {
      title: '販売管理システム｜入荷予定照会',
      desc: '販売管理システムの入荷予定照会です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/set-stock-inquiry',
    name: 'SET-STOCK-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/set-stock-inquiry.vue'),
    meta: {
      title: '販売管理システム｜セット品在庫照会',
      desc: '販売管理システムのセット品在庫照会です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/ships-scheduled-route',
    name: 'SHIPS-SCHEDULED-ROUTE',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-scheduled-route.vue'),
    meta: {
      title: '販売管理システム｜ルート別出荷処理予定表',
      desc: '販売管理システムのルート別出荷処理予定表です。',
      type: 'user',
    },
  },
  {
    path: '/ships-scheduled-route-print',
    name: 'SHIPS-SCHEDULED-ROUTE-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/ships-scheduled-route-print.vue'),
    meta: {
      title: 'ルート別出荷処理予定表 - 印刷プレビュー',
      desc: '販売管理システムのルート別出荷処理予定表印刷です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-auto',
    name: 'SHIPS-CONFIRM-AUTO',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-confirm-auto.vue'),
    meta: {
      title: '販売管理システム｜自動出荷確定',
      desc: '販売管理システムの自動出荷確定です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-input',
    name: 'SHIPS-CONFIRM-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-confirm-input.vue'),
    meta: {
      title: '販売管理システム｜出荷確定入力',
      desc: '販売管理システムの出荷確定入力です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-edit',
    name: 'SHIPS-CONFIRM-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-confirm-edit.vue'),
    meta: {
      title: '販売管理システム｜出荷確定修正',
      desc: '販売管理システムの出荷確定修正です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-picking',
    name: 'SHIPS-CONFIRM-PICKING',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-confirm-picking.vue'),
    meta: {
      title: '販売管理システム｜出荷確定ピッキング表',
      desc: '販売管理システムの出荷確定ピッキング表です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-picking-print',
    name: 'SHIPS-CONFIRM-PICKING-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/ships-confirm-picking-print.vue'),
    meta: {
      title: 'ルート別ピッキング表 - 印刷プレビュー',
      desc: '販売管理システムのルート別ピッキング表印刷です。',
      type: 'user',
    },
  },
  {
    path: '/sales-slip-print-auto',
    name: 'SALES-SLIP-PRINT-AUTO',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-slip-print-auto.vue'),
    meta: {
      title: '販売管理システム｜自動売上伝票発行',
      desc: '販売管理システムの自動売上伝票発行です。',
      type: 'user',
    },
  },
  {
    path: '/sales-slip-nouhin-auto',
    name: 'SALES-SLIP-NOUHIN-AUTO',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/sales-slip-nouhin-auto.vue'),
    meta: {
      title: '納品書 - 印刷プレビュー',
      desc: '販売管理システムの自動売上伝票発行印刷です。',
      type: 'user',
    },
  },
  {
    path: '/sales-slip-print',
    name: 'SALES-SLIP-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-slip-print.vue'),
    meta: {
      title: '販売管理システム｜売上伝票発行',
      desc: '販売管理システムの売上伝票発行です。',
      type: 'user',
    },
  },
  {
    path: '/sales-slip-nouhin',
    name: 'SALES-SLIP-NOUHIN',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/sales-slip-nouhin.vue'),
    meta: {
      title: '納品書 - 印刷プレビュー',
      desc: '販売管理システムの売上伝票発行印刷です。',
      type: 'user',
    },
  },
  {
    path: '/sales-direct-input',
    name: 'SALES-DIRECT-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-direct-input.vue'),
    meta: {
      title: '販売管理システム｜直送売上入力',
      desc: '販売管理システムの直送売上入力です。',
      type: 'user',
    },
  },
  {
    path: '/sales-direct-nouhin',
    name: 'SALES-DIRECT-NOUHIN',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/sales-direct-nouhin.vue'),
    meta: {
      title: '納品書 - 印刷プレビュー',
      desc: '販売管理システムの売上伝票発行印刷です。',
      type: 'user',
    },
  },
  {
    path: '/sales-tentative-nouhin',
    name: 'SALES-TENTATIVE-NOUHIN',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/sales-tentative-nouhin.vue'),
    meta: {
      title: '納品書 - 印刷プレビュー',
      desc: '販売管理システムの売上伝票（仮伝票）発行印刷です。',
      type: 'user',
    },
  },
  {
    path: '/received-order-stock-reserve-list',
    name: 'RECEIVED-ORDER-STOCK-RESERVE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-stock-reserve-list.vue'),
    meta: {
      title: '販売管理システム｜受注在庫引当一覧',
      desc: '販売管理システムの受注在庫引当一覧です。',
      type: 'user',
    },
  },
  {
    path: '/received-order-stock-reserve-change',
    name: 'RECEIVED-ORDER-STOCK-RESERVE-CHANGE',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-stock-reserve-change.vue'),
    meta: {
      title: '販売管理システム｜受注在庫引当変更',
      desc: '販売管理システムの受注在庫引当変更です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-orderstock',
    name: 'MENU-SALES-ORDERSTOCK',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-orderstock.vue'),
    meta: {
      title: '販売管理システム｜発注・入荷処理メニュー',
      desc: '販売管理システムの発注・入荷処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/order-slip-print',
    name: 'ORDER-SLIP-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-slip-print.vue'),
    meta: {
      title: '販売管理システム｜発注書発行',
      desc: '販売管理システムの発注書発行です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/order-stock-list',
    name: 'ORDER-STOCK-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-stock-list.vue'),
    meta: {
      title: '販売管理システム｜入荷 一覧/入力/修正',
      desc: '販売管理システムの入荷 一覧/入力/修正です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/order-stock-input',
    name: 'ORDER-STOCK-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-stock-input.vue'),
    meta: {
      title: '販売管理システム｜入荷入力',
      desc: '販売管理システムの入荷入力です。',
      type: 'user',
    },
  },
  {
    path: '/order-stock-edit',
    name: 'ORDER-STOCK-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-stock-edit.vue'),
    meta: {
      title: '販売管理システム｜入荷修正',
      desc: '販売管理システムの入荷修正です。',
      type: 'user',
    },
  },
  {
    path: '/order-list',
    name: 'ORDER-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-list.vue'),
    meta: {
      title: '販売管理システム｜発注 一覧/入力/修正/削除',
      desc: '販売管理システムの発注 一覧/入力/修正/削除です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/order-input',
    name: 'ORDER-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-input.vue'),
    meta: {
      title: '販売管理システム｜発注入力',
      desc: '販売管理システムの発注入力です。',
      type: 'user',
    },
  },
  {
    path: '/order-input-inquiry',
    name: 'ORDER-INPUT-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-input-inquiry.vue'),
    meta: {
      title: '販売管理システム｜発注入力照会',
      desc: '販売管理システムの発注入力照会です。',
      type: 'user',
    },
  },
  {
    path: '/order-edit',
    name: 'ORDER-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-edit.vue'),
    meta: {
      title: '販売管理システム｜発注修正',
      desc: '販売管理システムの発注修正です。',
      type: 'user',
    },
  },
  {
    path: '/order-stock-confirm-input',
    name: 'ORDER-STOCK-CONFIRM-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-stock-confirm-input.vue'),
    meta: {
      title: '販売管理システム｜入荷確定入力',
      desc: '販売管理システムの入荷確定入力です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-query',
    name: 'MENU-SALES-QUERY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-query.vue'),
    meta: {
      title: '販売管理システム｜QUERYメニュー',
      desc: '販売管理システムのQUERYメニューです。',
      type: 'user',
    },
  },
  {
    path: '/query-show-record',
    name: 'QUERY-SHOW-RECORD',
    component: () => import(/* webpackChunkName: "about" */ '../views/query-show-record.vue'), 
    meta: {
      title: '販売管理システム｜得意先別売上額照会',
      desc: '販売管理システムの得意先別売上額照会です。',
      type: 'user',
    },
  },
  {
    path: '/query-client-sales-by-month',
    name: 'QUERY-CLIENT-SALES-BY-MONTH',
    component: () => import(/* webpackChunkName: "about" */ '../views/query-client-sales-by-month.vue'), 
    meta: {
      title: '販売管理システム｜得意先別売上額（年月指定）照会',
      desc: '販売管理システムの得意先別売上額（年月指定）照会です。',
      type: 'user',
    },
  },
  {
    path: '/query-sales-by-month',
    name: 'QUERY-SALES-BY-MONTH',
    component: () => import(/* webpackChunkName: "about" */ '../views/query-sales-by-month.vue'), 
    meta: {
      title: '販売管理システム｜担当者別売上額（年月指定）照会',
      desc: '販売管理システムの担当者別売上額（年月指定）照会です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-inquiry',
    name: 'MENU-SALES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-inquiry.vue'),
    meta: {
      title: '販売管理システム｜問合せ処理メニュー',
      desc: '販売管理システムの問合せ処理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/sales-slip-inquiry',
    name: 'SALES-SLIP-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-slip-inquiry.vue'),
    meta: {
      title: '販売管理システム｜売上伝票照会',
      desc: '販売管理システムの売上伝票照会です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/invoice-inquiry',
    name: 'INVOICE-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice-inquiry.vue'),
    meta: {
      title: '販売管理システム｜得意先元帳照会',
      desc: '販売管理システムの得意先元帳照会です。',
      type: 'user',
    },
  },
  {
    path: '/client-ledger-inquiry',
    name: 'CLIENT-LEDGER-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-ledger-inquiry.vue'),
    meta: {
      title: '販売管理システム｜仕入先元帳照会',
      desc: '販売管理システムの仕入先元帳照会です。',
      type: 'user',
    },
  },
  {
    path: '/ships-confirm-inquiry',
    name: 'SHIPS-CONFIRM-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/ships-confirm-inquiry.vue'),
    meta: {
      title: '販売管理システム｜出荷確定照会',
      desc: '販売管理システムの出荷確定照会です。',
      type: 'user',
    },
  },
  {
    path: '/order-inquiry',
    name: 'ORDER-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/order-inquiry.vue'),
    meta: {
      title: '販売管理システム｜発注照会',
      desc: '販売管理システムの発注照会です。',
      type: 'user',
    },
  },
  {
    path: '/received-order-inquiry',
    name: 'RECEIVED-ORDER-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/received-order-inquiry.vue'),
    meta: {
      title: '販売管理システム｜受注照会 通常/製品別/チェック用',
      desc: '販売管理システムの受注照会 通常/製品別/チェック用です。',
      type: 'user',
    },
  },
  {
    path: '/menu-master-maintenance',
    name: 'MENU-MASTER-MAINTENACE',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-master-maintenance.vue'),
    meta: {
      title: '販売管理システム｜マスタ保守',
      desc: '販売管理システムのマスタ保守です。',
      type: 'user',
    },
  },
  {
    path: '/staff-list',
    name: 'STAFF-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/staff-list.vue'),
    meta: {
      title: '販売管理システム｜担当者保守一覧表',
      desc: '販売管理システムの担当者保守一覧表です。',
      type: 'user',
    },
  },
  {
    path: '/staff-input',
    name: 'STAFF-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/staff-input.vue'),
    meta: {
      title: '販売管理システム｜担当者保守入力',
      desc: '販売管理システムの担当者保守入力です。',
      type: 'user',
    },
  },
  {
    path: '/staff-edit',
    name: 'STAFF-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/staff-edit.vue'),
    meta: {
      title: '販売管理システム｜担当者保守編集',
      desc: '販売管理システムの担当者保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/staff-restore',
    name: 'STAFF-RESTORE',
    component: () => import(/* webpackChunkName: "about" */ '../views/staff-edit.vue'),
    meta: {
      title: '販売管理システム｜担当者保守復旧',
      desc: '販売管理システムの担当者保守復旧です。',
      type: 'user',
    },
  },
  {
    path: '/staffs-results-maintenance',
    name: 'STAFFS-RESULTS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/staffs-results-maintenance.vue'),
    meta: {
      title: '販売管理システム｜担当者実績保守',
      desc: '販売管理システムの担当者実績保守です。',
      type: 'user',
    },
  },
  {
    path: '/staffs-results-inquiry',
    name: 'STAFFS-RESULTS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/staffs-results-inquiry.vue'),
    meta: {
      title: '販売管理システム｜担当者実績保守照会',
      desc: '販売管理システムの担当者実績保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/staffs-results-edit',
    name: 'STAFFS-RESULTS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/staffs-results-edit.vue'),
    meta: {
      title: '販売管理システム｜担当者実績保守編集',
      desc: '販売管理システムの担当者実績保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/suppliers-sites-maintenance',
    name: 'SUPPLIERS-SITES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/suppliers-sites-maintenance.vue'),
    meta: {
      title: '販売管理システム｜仕入先現場コード保守',
      desc: '販売管理システムの仕入先現場コード保守です。',
      type: 'user',
    },
  },
  {
    path: '/suppliers-sites-input',
    name: 'SUPPLIERS-SITES-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/suppliers-sites-input.vue'),
    meta: {
      title: '販売管理システム｜仕入先現場コード保守入力',
      desc: '販売管理システムの仕入先現場コード保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/suppliers-sites-inquiry',
    name: 'SUPPLIERS-SITES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/suppliers-sites-inquiry.vue'),
    meta: {
      title: '販売管理システム｜仕入先現場コード保守照会',
      desc: '販売管理システムの仕入先現場コード保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/suppliers-sites-edit',
    name: 'SUPPLIERS-SITES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/suppliers-sites-edit.vue'),
    meta: {
      title: '販売管理システム｜仕入先現場コード保守編集',
      desc: '販売管理システムの仕入先現場コード保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/payments-balances-maintenance',
    name: 'PAYMENTS-BALANCES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/payments-balances-maintenance.vue'),
    meta: {
      title: '販売管理システム｜支払残高保守',
      desc: '販売管理システムの支払残高保守です。',
      type: 'user',
    },
  },
  {
    path: '/payments-balances-inquiry',
    name: 'PAYMENTS-BALANCES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/payments-balances-inquiry.vue'),
    meta: {
      title: '販売管理システム｜支払残高保守照会',
      desc: '販売管理システムの支払残高保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/payments-balances-edit',
    name: 'PAYMENTS-BALANCES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/payments-balances-edit.vue'),
    meta: {
      title: '販売管理システム｜支払残高保守編集',
      desc: '販売管理システムの支払残高保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/billings-balances-maintenance',
    name: 'BILLINGS-BALANCES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/billings-balances-maintenance.vue'),
    meta: {
      title: '販売管理システム｜請求残高保守',
      desc: '販売管理システムの請求残高保守です。',
      type: 'user',
    },
  },
  {
    path: '/billings-balances-inquiry',
    name: 'BILLINGS-BALANCES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/billings-balances-inquiry.vue'),
    meta: {
      title: '販売管理システム｜請求残高保守照会',
      desc: '販売管理システムの請求残高保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/billings-balances-edit',
    name: 'BILLINGS-BALANCES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/billings-balances-edit.vue'),
    meta: {
      title: '販売管理システム｜請求残高保守編集',
      desc: '販売管理システムの請求残高保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/client-maintenance',
    name: 'CLIENT-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-maintenance.vue'),
    meta: {
      title: '販売管理システム｜取引先保守',
      desc: '販売管理システムの取引先保守です。',
      type: 'user',
    },
  },
  {
    path: '/client-input',
    name: 'CLIENT-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-input.vue'),
    meta: {
      title: '販売管理システム｜取引先保守入力',
      desc: '販売管理システムの取引先保守入力です。',
      type: 'user',
    },
  },
  {
    path: '/client-inquiry',
    name: 'CLIENT-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-inquiry.vue'),
    meta: {
      title: '販売管理システム｜取引先保守照会',
      desc: '販売管理システムの取引先保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/client-edit',
    name: 'CLIENT-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-edit.vue'),
    meta: {
      title: '販売管理システム｜取引先保守編集',
      desc: '販売管理システムの取引先保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/site-maintenance',
    name: 'SITE-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/site-maintenance.vue'),
    meta: {
      title: '販売管理システム｜現場保守',
      desc: '販売管理システムの現場保守です。',
      type: 'user',
    },
  },
  {
    path: '/site-input',
    name: 'SITE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/site-input.vue'),
    meta: {
      title: '販売管理システム｜現場保守入力',
      desc: '販売管理システムの現場保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/site-inquiry',
    name: 'SITE-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/site-inquiry.vue'),
    meta: {
      title: '販売管理システム｜現場保守照会',
      desc: '販売管理システムの現場保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/site-edit',
    name: 'SITE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/site-edit.vue'),
    meta: {
      title: '販売管理システム｜現場保守編集',
      desc: '販売管理システムの現場保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products-maintenance',
    name: 'PRODUCTS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品保守',
      desc: '販売管理システムの製品保守です。',
      type: 'user',
    },
  },
  {
    path: '/products-input',
    name: 'PRODUCTS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-input.vue'),
    meta: {
      title: '販売管理システム｜製品保守入力',
      desc: '販売管理システムの製品保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products-inquiry',
    name: 'PRODUCTS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-inquiry.vue'),
    meta: {
      title: '販売管理システム｜製品保守照会',
      desc: '販売管理システムの製品保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products-edit',
    name: 'PRODUCTS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-edit.vue'),
    meta: {
      title: '販売管理システム｜製品保守編集',
      desc: '販売管理システムの製品保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/route-maintenance',
    name: 'ROUTE-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/route-maintenance.vue'), 
    meta: {
      title: '販売管理システム｜ルート保守',
      desc: '販売管理システムのルート保守です。',
      type: 'user',
    },
  },
  {
    path: '/route-inquiry',
    name: 'ROUTE-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/route-inquiry.vue'), 
    meta: {
      title: '販売管理システム｜ルート保守',
      desc: '販売管理システムのルート保守です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/route-input',
    name: 'ROUTE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/route-input.vue'),
    meta: {
      title: '販売管理システム｜ルート保守入力',
      desc: '販売管理システムのルート保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/route-edit',
    name: 'ROUTE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/route-edit.vue'),
    meta: {
      title: '販売管理システム｜ルート保守編集',
      desc: '販売管理システムのルート保守編集です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/deliverys-designs-classes-maintenance',
    name: 'DELIVERYS-DESIGNS-CLASSES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/deliverys-designs-classes-maintenance.vue'),
    meta: {
      title: '販売管理システム｜納品指定区分保守',
      desc: '販売管理システムの納品指定区分保守です。',
      type: 'user',
    },
  },
  {
    path: '/deliverys-designs-classes-input',
    name: 'DELIVERYS-DESIGNS-CLASSES-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/deliverys-designs-classes-input.vue'),
    meta: {
      title: '販売管理システム｜納品指定区分保守入力',
      desc: '販売管理システムの納品指定区分保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/deliverys-designs-classes-inquiry',
    name: 'DELIVERYS-DESIGNS-CLASSES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/deliverys-designs-classes-inquiry.vue'),
    meta: {
      title: '販売管理システム｜納品指定区分保守照会',
      desc: '販売管理システムの納品指定区分保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/deliverys-designs-classes-edit',
    name: 'DELIVERYS-DESIGNS-CLASSES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/deliverys-designs-classes-edit.vue'),
    meta: {
      title: '販売管理システム｜納品指定区分保守編集',
      desc: '販売管理システムの納品指定区分保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-classes-maintenance',
    name: 'PRODUCTS9A-CLASSES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-classes-maintenance.vue'),
    meta: {
      title: '販売管理システム｜9Aコード管理保守',
      desc: '販売管理システムの9Aコード保守です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-classes-input',
    name: 'PRODUCTS9A-CLASSES-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-classes-input.vue'),
    meta: {
      title: '販売管理システム｜9Aコード管理保守入力',
      desc: '販売管理システムの9Aコード保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products9A-classes-inquiry',
    name: 'PRODUCTS9A-CLASSES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-classes-inquiry.vue'),
    meta: {
      title: '販売管理システム｜9Aコード管理保守照会',
      desc: '販売管理システムの9Aコード保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-classes-edit',
    name: 'PRODUCTS9A-CLASSES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-classes-edit.vue'),
    meta: {
      title: '販売管理システム｜9Aコード管理保守編集',
      desc: '販売管理システムの9Aコード保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-maintenance',
    name: 'PRODUCTS9A-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-maintenance.vue'),
    meta: {
      title: '販売管理システム｜9A製品保守',
      desc: '販売管理システムの9A製品保守です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-input',
    name: 'PRODUCTS9A-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-input.vue'),
    meta: {
      title: '販売管理システム｜9A製品保守入力',
      desc: '販売管理システムの9A製品保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products9A-inquiry',
    name: 'PRODUCTS9A-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-inquiry.vue'),
    meta: {
      title: '販売管理システム｜9A製品保守照会',
      desc: '販売管理システムの9A製品保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products9A-edit',
    name: 'PRODUCTS9A-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products9A-edit.vue'),
    meta: {
      title: '販売管理システム｜9A製品保守編集',
      desc: '販売管理システムの9A製品保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/calendar-master-maintenance',
    name: 'CALENDAR-MASTER-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/calendar-master-maintenance.vue'),
    meta: {
      title: '販売管理システム｜カレンダーマスタ保守',
      desc: '販売管理システムのカレンダーマスタ保守です。',
      type: 'user',
    },
  },
  {
    path: '/control-master-update',
    name: 'CONTROL-MASTER-UPDATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/control-master-update.vue'),
    meta: {
      title: '販売管理システム｜コントロール保守',
      desc: '販売管理システムのコントロール保守です。',
      type: 'user',
    },
  },
  {
    path: '/service-classes-maintenance',
    name: 'SERVICE-CLASSES-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/service-classes-maintenance.vue'),
    meta: {
      title: '販売管理システム｜サービス区分名保守',
      desc: '販売管理システムのサービス区分名保守です。',
      type: 'user',
    },
  },
  {
    path: '/service-classes-input',
    name: 'SERVICE-CLASSES-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/service-classes-input.vue'),
    meta: {
      title: '販売管理システム｜サービス区分名保守入力',
      desc: '販売管理システムのサービス区分名保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/service-classes-inquiry',
    name: 'SERVICE-CLASSES-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/service-classes-inquiry.vue'),
    meta: {
      title: '販売管理システム｜サービス区分名保守照会',
      desc: '販売管理システムのサービス区分名保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/service-classes-edit',
    name: 'SERVICE-CLASSES-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/service-classes-edit.vue'),
    meta: {
      title: '販売管理システム｜サービス区分名保守編集',
      desc: '販売管理システムのサービス区分名保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/office-list',
    name: 'OFFICE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/office-list.vue'),
    meta: {
      title: '販売管理システム｜営業所保守一覧表',
      desc: '販売管理システムの営業所保守一覧表です。',
      type: 'user',
    },
  },
  {
    path: '/office-input',
    name: 'OFFICE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/office-input.vue'),
    meta: {
      title: '販売管理システム｜営業所保守入力',
      desc: '販売管理システムの営業所保守入力です。',
      type: 'user',
    },
  },
  {
    path: '/office-edit',
    name: 'OFFICE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/office-edit.vue'),
    meta: {
      title: '販売管理システム｜営業所保守編集',
      desc: '販売管理システムの営業所保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/stocks-maintenance',
    name: 'STOCKS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/stocks-maintenance.vue'),
    meta: {
      title: '販売管理システム｜在庫保守',
      desc: '販売管理システムの在庫保守です。',
      type: 'user',
    },
  },
  {
    path: '/stocks-inquiry',
    name: 'STOCKS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/stocks-inquiry.vue'),
    meta: {
      title: '販売管理システム｜在庫保守照会',
      desc: '販売管理システムの在庫保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/client-code-switch-maintenance',
    name: 'CLIENT-CODE-SWITCH-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-code-switch-maintenance.vue'),
    meta: {
      title: '販売管理システム｜取引先コード切替',
      desc: '販売管理システムの取引先コード切替です。',
      type: 'user',
    },
  },
  {
    path: '/client-code-switch-edit',
    name: 'CLIENT-CODE-SWITCH-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/client-code-switch-edit.vue'),
    meta: {
      title: '販売管理システム｜取引先コード切替編集',
      desc: '販売管理システムの取引先コード切替編集です。',
      type: 'user',
    },
  },
  {
    path: '/product-code-switch-maintenance',
    name: 'PRODUCT-CODE-SWITCH-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/product-code-switch-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品コード切替',
      desc: '販売管理システムの製品コード切替です。',
      type: 'user',
    },
  },
  {
    path: '/product-code-switch-edit',
    name: 'PRODUCT-CODE-SWITCH-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/product-code-switch-edit.vue'),
    meta: {
      title: '販売管理システム｜製品コード切替編集',
      desc: '販売管理システムの製品コード切替編集です。',
      type: 'user',
    },
  },
  {
    path: '/products-compositions-maintenance',
    name: 'PRODUCTS-COMPOSITIONS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-compositions-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品構成保守',
      desc: '販売管理システムの製品構成保守です。',
      type: 'user',
    },
  },
  {
    path: '/products-compositions-input',
    name: 'PRODUCTS-COMPOSITIONS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-compositions-input.vue'),
    meta: {
      title: '販売管理システム｜製品構成保守入力',
      desc: '販売管理システムの製品構成保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products-compositions-inquiry',
    name: 'PRODUCTS-COMPOSITIONS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-compositions-inquiry.vue'),
    meta: {
      title: '販売管理システム｜製品構成保守照会',
      desc: '販売管理システムの製品構成保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products-compositions-edit',
    name: 'PRODUCTS-COMPOSITIONS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-compositions-edit.vue'),
    meta: {
      title: '販売管理システム｜製品構成保守編集',
      desc: '販売管理システムの製品構成保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products-results-maintenance',
    name: 'PRODUCTS-RESULTS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-results-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品実績保守',
      desc: '販売管理システムの製品実績保守です。',
      type: 'user',
    },
  },
  {
    path: '/products-results-inquiry',
    name: 'PRODUCTS-RESULTS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-results-inquiry.vue'),
    meta: {
      title: '販売管理システム｜製品実績保守照会',
      desc: '販売管理システムの製品実績保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products-results-edit',
    name: 'PRODUCTS-RESULTS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-results-edit.vue'),
    meta: {
      title: '販売管理システム｜製品実績保守編集',
      desc: '販売管理システムの製品実績保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products-units-conversions-maintenance',
    name: 'PRODUCTS-UNITS-CONVERSIONS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-units-conversions-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品単位変換保守',
      desc: '販売管理システムの製品単位変換保守です。',
      type: 'user',
    },
  },
  {
    path: '/products-units-conversions-input',
    name: 'PRODUCTS-UNITS-CONVERSIONS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-units-conversions-input.vue'),
    meta: {
      title: '販売管理システム｜製品単位変換保守入力',
      desc: '販売管理システムの製品単位変換保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products-units-conversions-inquiry',
    name: 'PRODUCTS-UNITS-CONVERSIONS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-units-conversions-inquiry.vue'),
    meta: {
      title: '販売管理システム｜製品単位変換保守照会',
      desc: '販売管理システムの製品単位変換保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products-units-conversions-edit',
    name: 'PRODUCTS-UNITS-CONVERSIONS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-units-conversions-edit.vue'),
    meta: {
      title: '販売管理システム｜製品単位変換保守編集',
      desc: '販売管理システムの製品単位変換保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/products-prices-groups-maintenance',
    name: 'PRODUCTS-PRICES-GROUPS-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-prices-groups-maintenance.vue'),
    meta: {
      title: '販売管理システム｜製品単価グループ保守',
      desc: '販売管理システムの製品単価グループ保守です。',
      type: 'user',
    },
  },
  {
    path: '/products-prices-groups-input',
    name: 'PRODUCTS-PRICES-GROUPS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-prices-groups-input.vue'),
    meta: {
      title: '販売管理システム｜製品単価グループ保守入力',
      desc: '販売管理システムの製品単価グループ保守入力です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/products-prices-groups-inquiry',
    name: 'PRODUCTS-PRICES-GROUPS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-prices-groups-inquiry.vue'),
    meta: {
      title: '販売管理システム｜製品単価グループ保守照会',
      desc: '販売管理システムの製品単価グループ保守照会です。',
      type: 'user',
    },
  },
  {
    path: '/products-prices-groups-edit',
    name: 'PRODUCTS-PRICES-GROUPS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/products-prices-groups-edit.vue'),
    meta: {
      title: '販売管理システム｜製品単価グループ保守編集',
      desc: '販売管理システムの製品単価グループ保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/slip-no-maintenance',
    name: 'SLIP-NO-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/slip-no-maintenance.vue'),
    meta: {
      title: '販売管理システム｜伝票No管理保守',
      desc: '販売管理システムの伝票No管理保守です。',
      type: 'user',
    },
  },
  {
    path: '/slip-no-maintenance-edit',
    name: 'SLIP-NO-MAINTENANCE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/slip-no-maintenance-edit.vue'),
    meta: {
      title: '販売管理システム｜伝票No管理保守編集',
      desc: '販売管理システムの伝票No管理保守編集です。',
      type: 'user',
    },
  },
  {
    path: '/menu-sales-estimate',
    name: 'MENU-SALES-ESTIMATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-sales-estimate.vue'),
    meta: {
      title: '販売管理システム｜見積管理メニュー',
      desc: '販売管理システムの見積管理メニューです。',
      type: 'user',
    },
  },
  {
    path: '/estimate-list',
    name: 'ESTIMATE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-list.vue'),
    meta: {
      title: '販売管理システム｜見積・発注一覧',
      desc: '販売管理システムの見積・発注一覧です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/estimate-input',
    name: 'ESTIMATE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-input.vue'),
    meta: {
      title: '販売管理システム｜見積入力',
      desc: '販売管理システムの見積入力です。',
      type: 'user',
    },
  },
  {
    path: '/estimate-inquiry',
    name: 'ESTIMATE-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-inquiry.vue'),
    meta: {
      title: '販売管理システム｜見積照会',
      desc: '販売管理システムの見積照会です。',
      type: 'user',
    },
  },
  {
    path: '/estimate-edit',
    name: 'ESTIMATE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-edit.vue'),
    meta: {
      title: '販売管理システム｜見積修正',
      desc: '販売管理システムの見積修正です。',
      type: 'user',
    },
  },
  {
    path: '/estimate',
    name: 'ESTIMATE',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/estimate.vue'),
    meta: {
      title: '見積書 - 印刷プレビュー',
      desc: '販売管理システムの見積書印刷です。',
      type: 'user',
    },
  },
  {
    path: '/estimate-orders-input',
    name: 'ESTIMATE-ORDERS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-orders-input.vue'),
    meta: {
      title: '販売管理システム｜発注入力',
      desc: '販売管理システムの発注入力です。',
      type: 'user',
    },
  },
  {
    path: '/estimate-orders-inquiry',
    name: 'ESTIMATE-ORDERS-INQUIRY',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-orders-inquiry.vue'),
    meta: {
      title: '販売管理システム｜発注照会',
      desc: '販売管理システムの発注照会です。',
      type: 'user',
    },
  },
  {
    path: '/estimate-orders-edit',
    name: 'ESTIMATE-ORDERS-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/estimate-orders-edit.vue'),
    meta: {
      title: '販売管理システム｜発注修正',
      desc: '販売管理システムの発注修正です。',
      type: 'user',
    },
  },
  {
    path: '/order',
    name: 'ORDER',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/order.vue'),
    meta: {
      title: '発注書 - 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
  },
  {
    path: '/purchase-order',
    name: 'PURCHASE-ORDER',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/purchase-order.vue'),
    meta: {
      title: '発注書（入力分） - 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/purchase-order-normal',
    name: 'PURCHASE-ORDER-NORMAL',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/purchase-order-normal.vue'),
    meta: {
      title: '発注書 - 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/purchase-order-normal-reissue',
    name: 'PURCHASE-ORDER-NORMAL-REISSUE',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/purchase-order-normal-reissue.vue'),
    meta: {
      title: '発注書 - 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/purchase-order-sales',
    name: 'PURCHASE-ORDER-SALES',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/purchase-order-sales.vue'),
    meta: {
      title: '発注書発行（特別売上分）- 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/purchase-order-direct-delivery',
    name: 'PURCHASE-ORDER-DIRECT-DELIVERY',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/purchase-order-direct-delivery.vue'),
    meta: {
      title: '発注書（直送分） - 印刷プレビュー',
      desc: '販売管理システムの発注書印刷です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/clients-products-master-list',
    name: 'CLIENTS-PRODUCTS-MASTER-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients-products-master-list.vue'),
    meta: {
      title: '販売管理システム｜取引先製品マスタ 一覧',
      desc: '販売管理システムの取引先製品マスタ 一覧です。',
      type: 'user',
    },
  },
  {
    path: '/clients-products-master-input',
    name: 'CLIENTS-PRODUCTS-MASTER-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients-products-master-input.vue'),
    meta: {
      title: '販売管理システム｜取引先製品マスタ入力',
      desc: '販売管理システムの取引先製品マスタ入力です。',
      type: 'user',
    },
  },
  {
    path: '/clients-products-master-edit',
    name: 'CLIENTS-PRODUCTS-MASTER-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients-products-master-edit.vue'),
    meta: {
      title: '販売管理システム｜取引先製品マスタ修正',
      desc: '販売管理システムの取引先製品マスタ修正です。',
      type: 'user',
    },
  },
  {
    path: '/clients-parent-child-master-list',
    name: 'CLIENTS-PARENT-CHILD-MASTER-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients-parent-child-master-list.vue'),
    meta: {
      title: '販売管理システム｜親取引先マスタ一覧',
      desc: '販売管理システムの親取引先マスタ一覧です。',
      type: 'user',
    },
    props:true
  },
  {
    path: '/clients-parent-child-master-input',
    name: 'CLIENTS-PARENT-CHILD-MASTER-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients-parent-child-master-input.vue'),
    meta: {
      title: '販売管理システム｜親取引先マスタ入力',
      desc: '販売管理システムの親取引先マスタ入力です。',
      type: 'user',
    },
  },
  {
    path: '/sales-unit-price-copy',
    name: 'SALES-UNIT-PRICT-COPY',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales-unit-price-copy.vue'),
    meta: {
      title: '販売管理システム｜単価自動コピー（個別）',
      desc: '販売管理システムの単価自動コピー（個別）です。',
      type: 'user',
    },
  },
  {
    path: '/menu-inventory',
    name: 'MENU-INVENTORY',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-inventory.vue'),
    meta: {
      title: '販売管理システム｜棚卸メニュー',
      desc: '販売管理システムの棚卸メニューです。',
      type: 'user',
    },
  },
  {
    path: '/inventory-preprocess',
    name: 'INVENTORY-PREPROCESS',
    component: () => import(/* webpackChunkName: "about" */ '../views/inventory-preprocess.vue'),
    meta: {
      title: '販売管理システム｜棚卸前処理',
      desc: '販売管理システムの棚卸前処理です。',
      type: 'user',
    },
  },
  {
    path: '/inventory-print',
    name: 'INVENTORY-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../views/inventory-print.vue'),
    meta: {
      title: '販売管理システム｜棚卸表印刷',
      desc: '販売管理システムの棚卸表印刷です。',
      type: 'user',
    },
  },
  {
    path: '/inventory-print-sheet',
    name: 'INVENTORY-PRINT-SHEET',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/inventory-print-sheet.vue'),
    meta: {
      title: '販売管理システム｜棚卸表印刷プレビュー',
      desc: '販売管理システムの棚卸表の印刷プレビューです。',
      type: 'user',
    },
  },
  {
    path: '/inventory-input',
    name: 'INVENTORY-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/inventory-input.vue'),
    meta: {
      title: '販売管理システム｜棚卸入力',
      desc: '販売管理システムの棚卸入力です。',
      type: 'user',
    },
  },
  {
    path: '/inventory-stock-variance',
    name: 'INVENTORY-STOCK-VARIANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/inventory-stock-variance.vue'),
    meta: {
      title: '販売管理システム｜棚卸差異表',
      desc: '販売管理システムの棚卸差異表です。',
      type: 'user',
    },
  },
  {
    path: '/inventory-stock-variance-print',
    name: 'INVENTORY-STOCK-VARIANCE-PRINT',
    component: () => import(/* webpackChunkName: "about" */ '../components/chouhyou/inventory-stock-variance-print.vue'),
    meta: {
      title: '販売管理システム｜棚卸差異表 - 印刷プレビュー',
      desc: '販売管理システムの棚卸差異表印刷です。',
      type: 'user',
    },
    props: true
  },
  {
    path: '/inventory-stock-bulk-update',
    name: 'INVENTORY-STOCK-BULK-UPDATE',
    component: () => import(/* webpackChunkName: "about" */ '../views/inventory-stock-bulk-update.vue'),
    meta: {
      title: '販売管理システム｜棚卸在庫一括更新',
      desc: '販売管理システムの棚卸在庫一括更新です。',
      type: 'user',
    },
  },
  {
    path: '/inquiry-report-maintenance',
    name: 'INQUIRY-REPORT-MAINTENANCE',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry-report-maintenance.vue'),
    meta: {
      title: 'その他｜照会レポート定義一覧',
      desc: '照会レポート定義一覧です。',
      type: 'user',
    }
  },
  {
    path: '/inquiry-report-input',
    name: 'INQUIRY-REPORT-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry-report-input-edit.vue'),
    meta: {
      title: '販売管理システム｜照会レポート定義入力',
      desc: '販売管理システムの照会レポート定義入力です。',
      type: 'user',
    }
  },
  {
    path: '/inquiry-report-edit',
    name: 'INQUIRY-REPORT-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry-report-input-edit.vue'),
    meta: {
      title: '販売管理システム｜照会レポート定義編集',
      desc: '販売管理システムの照会レポート定義編集です。',
      type: 'user',
    }
  },
  {
    path: '/menu-csv-import',
    name: 'MENU-CSV-IMPORT',
    component: () => import(/* webpackChunkName: "about" */ '../views/menu-csv-import.vue'),
    meta: {
      title: '販売管理システム｜CSVファイルインポートメニュー',
      desc: '販売管理システムのCSVファイルインポートメニューです。',
      type: 'user',
    },
  },
  {
    path: '/csv-import-sales-purchase-price',
    name: 'CSV-IMPORT-SALES-PURCHASE-PRICE',
    component: () => import(/* webpackChunkName: "about" */ '../views/csv-import-sales-purchase-price.vue'),
    meta: {
      title: '販売管理システム｜売価・仕入単価一括更新',
      desc: '販売管理システムの売価・仕入単価一括更新です。',
      type: 'user',
    },
  },
  {
    path: '/csv-import-clients-sales-price',
    name: 'CSV-IMPORT-CLIENTS-SALES-PRICE',
    component: () => import(/* webpackChunkName: "about" */ '../views/csv-import-clients-sales-price.vue'),
    meta: {
      title: '販売管理システム｜取引先製品単価一括更新',
      desc: '販売管理システムの取引先製品単価一括更新です。',
      type: 'user',
    },
  },
  {
    path: '/save-documents-list',
    name: 'SAVE-DOCUMENTS-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/save-documents-list.vue'),
    meta: {
      title: '販売管理システム｜保存電子書類一覧',
      desc: '販売管理システムの保存電子書類一覧です。',
      type: 'user',
    },
  },
  {
    path: '/save-documents-input',
    name: 'SAVE-DOCUMENTS-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/save-documents-input.vue'),
    meta: {
      title: '販売管理システム｜保存電子書類入力',
      desc: '販売管理システムの保存電子書類入力です。',
      type: 'user',
    },
  },
  {
    path: '/save-documents-maintenance-list',
    name: 'SAVE-DOCUMENTS-MAINTENANCE-LIST',
    component: () => import(/* webpackChunkName: "about" */ '../views/save-documents-maintenance-list.vue'),
    meta: {
      title: '販売管理システム｜電子書類保守一覧',
      desc: '販売管理システムの電子書類保守一覧です。',
      type: 'user',
    },
  },
  {
    path: '/save-documents-maintenance-input',
    name: 'SAVE-DOCUMENTS-MAINTENANCE-INPUT',
    component: () => import(/* webpackChunkName: "about" */ '../views/save-documents-maintenance-input.vue'),
    meta: {
      title: '販売管理システム｜電子書類保守入力',
      desc: '販売管理システムの電子書類保守入力です。',
      type: 'user',
    },
  },
  {
    path: '/save-documents-maintenance-edit',
    name: 'SAVE-DOCUMENTS-MAINTENANCE-EDIT',
    component: () => import(/* webpackChunkName: "about" */ '../views/save-documents-maintenance-edit.vue'),
    meta: {
      title: '販売管理システム｜電子書類保守修正',
      desc: '販売管理システムの電子書類保守修正です。',
      type: 'user',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// ルーティングトリガー時処理
router.beforeEach(async (to, from, next) => {
  try {
    if (to.name !== 'Menu' && to.path === from.path) {
      // 同じ画面に遷移する場合は画面遷移を行わない
      return;
    }
    // ローディングエフェクトON
    store.commit('setLoading', true);

    // ログイン済ユーザー情報取得
    // 未ログインの場合errorを投げるのでここでAPIコール
    await Auth.currentAuthenticatedUser();
    // Amplify cacheのチェック
    // cacheにユーザー情報が存在した場合、キャッシュの有効期限を更新
    const cachedUser = Cache.getItem('user');
    if (cachedUser) {
      // キャッシュ更新
      let date = new Date();
      // const.jsで定義した保持日数をセット
      date.setDate(date.getDate() + AUTH_EXPIRATION_DAYS);
      //const expiringTime = date.getTime();
      //console.log({expiringTime});
      Cache.setItem('user', cachedUser, { expires: date.getTime() });

      // Vuexチェック
      // storeにユーザー情報が存在しない場合、Cacheのデータをセット
      if (!store.getters.user) {
        store.commit('setUser', cachedUser);
      }
    } else {
      // cacheにユーザー情報が存在しない場合、サインアウト
      // Cognitoサインアウト
      await Auth.signOut();
      // エラーを投げてVuexとCacheを初期化
      throw 'Cache Expired';
    }
    // ログイン済であればページ遷移
    next();
  } catch {
    // Vuexクリア
    store.commit('setUser', null);
    // バージョンキャッシュは残したいのでclearは行わない
    Cache.removeItem('user');
    // 未ログインの場合ログイン画面に遷移
    if (to.path !== '/login') {
      next({ path: '/login' });
    } else {
      next();
    }
  }
})

export default router;
