import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    user: null,
    userType: null,
    facilityID: null,
    // 全て営業所の選択肢があります,営業所名にid付く
    officeOption: [],
    // 全て営業所の選択肢がありません,営業所名にid付く
    officeOptionEscapeAll: [],
    // 全て営業所の選択肢があります
    officeHonestOption: [],
    // 全て営業所の選択肢がありません
    officeHonestOptionEscapeAll: [],
  },
  getters: {
    loading: (state) => {
      return state.loading;
    },
    user: state => {
      return state.user;
    },
    officeOption: state => state.officeOption,
    officeOptionEscapeAll: state => state.officeOptionEscapeAll,
    officeHonestOption: state => state.officeHonestOption,
    officeHonestOptionEscapeAll: state => state.officeHonestOptionEscapeAll,
    // userType: state => {
    //   return state.userType;
    // },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUser(state, user) {
      state.user = user
    },
    setOffice(state, officeList) {
      let officeOptionArray = [{value: '0', text: '全ての営業所'}]
      let officeOptionEscapeAllArray = []
      let officeHonestOptionArray = [{value: '0', text: '全ての営業所'}]
      let officeHonestOptionEscapeAllArray = []
      officeList.forEach(office => {
        // 営業所選択肢に追加
        officeOptionArray.push({ value: office.office_id, text: `${office.office_id}:${office.office_name_kanji}` })
        // 営業所選択肢に追加
        officeOptionEscapeAllArray.push({ value: office.office_id, text: `${office.office_id}:${office.office_name_kanji}` })
        // 営業所選択肢に追加
        officeHonestOptionArray.push({ value: office.office_id, text: office.office_name_kanji })
        // 営業所選択肢に追加
        officeHonestOptionEscapeAllArray.push({ value: office.office_id, text: office.office_name_kanji })
      });
      state.officeOption = officeOptionArray
      state.officeOptionEscapeAll = officeOptionEscapeAllArray
      state.officeHonestOption = officeHonestOptionArray
      state.officeHonestOptionEscapeAll = officeHonestOptionEscapeAllArray
    }
    // setUserType(state, userType) {
    //   state.userType = userType
    // },
  },
  actions: {},
  modules: {},
});
