<template>
  <div id="app">
    <!-- ローディング -->
    <Loading />
    <router-view />
  </div>
</template>
<script>
import { API, Cache, graphqlOperation } from 'aws-amplify';
import Loading from '@/components/effect/loading.vue';
import { getM_versions } from '@/graphql/queries';
import { addOperationLogs } from '@/assets/js/common.js';
export default {
  components: {
    Loading,
  },
  // 以下ページタイトル・メタタグ設定処理
  mounted() {
    this.setPageTitle(this.$route);
  },
  watch: {
    $route(to) {
      this.setPageTitle(to);

      if (this.$router.history.current.path !== '/login') {
        /** 
         * ログイン画面以外ならキャッシュチェック
         */
        this.checkCacheClear();
      }
    },
  },
  methods: {
    setPageTitle(to) {
      // タイトルを設定
      if (to.meta.title) {
        document.title = to.meta.title;
      } else {
        document.title = 'sanwa';
      }

      // メタタグdescription設定
      if (to.meta.desc) {
        document.querySelector('meta[name=\'description\']').setAttribute('content', to.meta.desc);
      } else {
        document.querySelector('meta[name=\'description\']').setAttribute('content', 'sanwa');
      }
    },
    /**
     * アプリのバージョンを確認して差分があった場合リソースを再取得する
     */
    async checkCacheClear() {
      // console.log('checkCacheClear');
      const cacheName = 'version'
      // DBに保存しているバージョン取得
      const versionDb = await this.getAppVersion();
      // console.log({versionDb});
      if (versionDb) {
        // Cookieに保存しているバージョン取得
        const versionCache = Cache.getItem(cacheName);
        // console.log({versionCache});
        // Cookie更新
        Cache.setItem(cacheName, versionDb);
        if (versionDb != versionCache) {
          // ServiceWorkerのregistrationを削除
          const registrations = await window.navigator.serviceWorker.getRegistrations();
          if (registrations) {
            for(let registration of registrations) {
              await registration.unregister();
            }
            if (versionCache) {
              // 新旧バージョンをログ出力
              await addOperationLogs( 'Info', 'app', 'checkCacheClear', {message: JSON.stringify( {OldVersion: versionCache, NewVersion: versionDb })});
            }
          }
          // キャッシュクリアリロード
          window.location.reload(true);
        }
        // console.log('cache not cleared');
      }
    },
    /**
     * アプリのバージョン取得処理
     */
    async getAppVersion() {
      let result = [];
      const condition = { type_name: 'app'};

      try {
        result = await API.graphql(graphqlOperation( getM_versions, condition ));
        // console.log({result});
      } catch (error) {
        // console.log({error});
        await addOperationLogs( 'Error', 'app', 'getAppVersion', {}, error );
        return null;
      }
      // 対象データが存在しない場合
      if( !result.data.getM_versions ) {
        // 異常系操作ログの登録
        await addOperationLogs( 'Error', 'app', 'getAppVersion', {}, 'no version data' );
        return null;
      } else {
        if (result.errors) {
          // console.log('result.errors', result.errors);
          // データを取得できなかった場合
          await addOperationLogs( 'Error', 'app', 'getAppVersion', {}, result.errors );
          return null;
        } else {
          // データ0件時
          return result.data.getM_versions.version_number;
        }
      }
    },
  },
};
</script>
<style src="./assets/css/bootstrap-vue-wrapper.css"></style>