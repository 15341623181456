import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import aws_exports from './aws-exports';
import Amplify from 'aws-amplify';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueI18n from 'vue-i18n';
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueShortKey from 'vue-shortkey';
import { ValidationObserver, ValidationProvider, extend, configure, localize } from 'vee-validate';
import { alpha, alpha_dash, alpha_num, alpha_spaces, between, confirmed, digits, dimensions, email, excluded, ext, image, oneOf, integer, is, is_not, length, max , max_value, mimes, min, min_value, numeric, regex, required, required_if, size, double } from 'vee-validate/dist/rules';
import ja from 'vee-validate/dist/locale/ja.json';
import { dateConsistency, splitMultiRowString, formatCurDate, getNullStr } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';

Amplify.configure(aws_exports);
Vue.use(VueI18n);
Vue.use(BootstrapVue, {
  BFormDatepicker: {
    closeButton      : true,
    hideHeader       : true,
    labelCloseButton : '閉じる',
    labelCurrentMonth: '今月',
    labelHelp        : '',
    labelNextMonth   : '翌月',
    labelNextYear    : '翌年',
    labelPrevMonth   : '前月',
    labelPrevYear    : '前年',
    labelResetButton : '未選択',
    labelTodayButton : '今日を選択',
    locale           : 'ja',
    placeholder      : '日付を選択',
    resetButton      : true,
    todayButton      : true
  },
  BTable: {
    bordered         : true,
    captionTop       : true,
    emptyFilteredText: '該当するデータがありません。',
    emptyText        : 'データが登録されていません。',
    hover            : true,
    responsive       : true,
    headVariant      : 'light',
    theadClass       : 'text-center',
  },
  BTableSimple: {
    bordered: true,
  },
});
Vue.use(VueShortKey);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'ja',
});

new Vue({
  router,
  store,
  // css,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// Object.keys(rules).forEach(rule => {
//   extend(rule, {
//     ...rules[rule], // copies rule configuration
//   });
// });

// メッセージを追加
localize('ja',ja);

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
  }
})

// vee-validate ルール

// 必須チェック
extend('required', {
  ...required,
  message: '必須項目です。'
})

// 範囲チェック
extend('between', {
  ...between,
  message: '{min}以上{max}以下で入力してください。'
})

extend('alpha', {
  ...alpha,
  message: '英字のみ入力してください。'
})

extend('alpha_dash', {
  ...alpha_dash,
  message: '英数字、´(ダッシュ)、_(アンダースコア)のみ入力してください。'
})

extend('alpha_num', {
  ...alpha_num,
  message: '英数字のみ入力してください。',
  validate: (value) => {
    return /^[a-zA-Z0-9]+$/.test(value)
  }
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: '英字、スペースのみ入力してください。'
})

extend('confirmed', {
  ...confirmed,
  message: '{target}と同じ値を入力してください。'
})

extend('digits', {
  ...digits,
  message: '{length}桁で入力してください。'
})

extend('dimensions', {
  ...dimensions,
  message: 'アップロードできる画像サイズは{width}×{height}までとなります。'
})

extend('email', {
  ...email,
  message: 'メールアドレスの入力形式が正しくありません。'
})

extend('excluded', {
  ...excluded,
  message: '不正な値です。'
})

extend('ext', {
  ...ext,
  message: '{ext}形式のファイルを選択してください。'
})

extend('image', {
  ...image,
  message: '画像ファイルを選択してください。'
})

extend('oneOf', {
  ...oneOf,
  message: '不正な値です。'
})

extend('integer', {
  ...integer,
  message: '整数のみ入力してください。'
})

extend('is', {
  ...is,
  message: '入力した値が一致しません。'
})

extend('is_not', {
  ...is_not,
  message: '入力した値はすでに使用済みの値です。'
})

extend('length', {
  ...length,
  message: '{length}文字で入力してください。'
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください。'
})

extend('max_value', {
  ...max_value,
  message: '{max}以内で入力してください。'
})

extend('mimes', {
  ...mimes,
  message: '{mimes}形式のファイルを選択してください。'
})

extend('min', {
  ...min,
  message: '{length}文字以上で入力してください。'
})

extend('min_value', {
  ...min_value,
  message: '{min}以上で入力してください。'
})

extend('numeric', {
  ...numeric,
  message: '数字のみ入力してください。'
})

extend('regex', {
  ...regex,
  message: '不正な値です。'
})

extend('required_if', {
  ...required_if,
  message: '必須項目です。'
})

extend('size', {
  ...size,
  message: 'ファイルサイズの上限({size}KB)を超えています。'
})

extend('double', {
  ...double,
  message: '不正な値です。'
})

extend('place', {
  message: '1A-2B-3C-4Dの形ハイフン区切りで入力してください。',
  validate: (value) => {
    const valueTemp = String(value).split('-')
    for (let index = 1; index < valueTemp.length; index++) {
      const curNode = valueTemp[index];
      const preNode = valueTemp[index - 1];
      // 中抜きパターンは「ダメ」
      if (curNode != '' && preNode == '') return false
    }
    // 各場所2桁まで
    return /^([0-9]|[a-z]){0,2}-([0-9]|[a-z]){0,2}-([0-9]|[a-z]){0,2}-([0-9]|[a-z]){0,2}$/i.test(value)
  }
})

extend('phone', {
  message: '数字をハイフン区切りで入力してください。',
  validate: (value) => {
    return /^0[1-9][0-9]{0,5}-[0-9]{1,7}-[0-9]{1,7}$/.test(value)
  }
})

extend('consistency', {
  params: ['toVal'],
  validate(value, {toVal}) {
    if (value && (toVal || (toVal != null && toVal.toString().length > 0))) {
      return value <= toVal;
    } else {
      return true;
    }
  },
  message: '大小関係が不正です。'
})

extend('numConsistency', {
  params: ['toVal'],
  validate(value, {toVal}) {
    try {
      if (value != null && toVal != null) {
        let strValue = value.toString();
        let strToVal = toVal.toString();
        if (strValue.length > 0 && strToVal.length > 0 && isNaN(strValue) == false && isNaN(strToVal) == false) {
          return Number(strValue) <= Number(strToVal);
        }
      }
    } catch {
      return true;
    }
    return true;
  },
  message: '大小関係が不正です。'
})

extend('dateConsistency', {
  params: ['toDate'],
  validate(value, {toDate}) {
    if (value && toDate && getNullStr(value) != '' && getNullStr(toDate) != '') {
      return dateConsistency(value, toDate);
    } else {
      return true;
    }
  },
  message: '日付の大小関係が不正です。'
})

extend('login_id', {
  message: '半角数字・半角英字(大文字小文字)・記号のみで入力してください。',
  validate: (value) => {
    return /^(?!.*[^!-~]).*$/.test(value)
  }
})

extend('max_row', {
  params: ['maxRow','maxRowLength'],
  validate(value, {maxRow, maxRowLength}) {
    if (splitMultiRowString(value, maxRowLength).length > maxRow) {
      return false;
    } else {
      return true;
    }
  },
  message: '1行の最大文字数を{maxRowLength}文字として、{maxRow}行以内で入力してください。'
})

extend('duplicate', {
  params: ['title','idList','key','NoCheckList'],
  validate(value, {idList,key,NoCheckList}) {
    if (NoCheckList != null && NoCheckList.length > 0) {
      if (NoCheckList.indexOf(value) != -1) {
        return true;
      }
    }
    let cnt = 0;
    for (let i = 0; i < idList.length; i++) {
      if (idList[i][key] == value) {
        cnt++;
      }
    
    }
    if (cnt > 1) {
      return false;
    } else {
      return true;
    }
  },
  message: '{title}が重複しています。'
})

extend('zip_code', {
  message: '「nnn-nnnn」形式で入力してください。',
  validate: (value) => {
    return /^\d{3}-\d{4}$/.test(value)
  }
})

extend('account_support', {
  message: '「nnn-nnn」形式で入力してください。',
  validate: (value) => {
    return /^\d{3}-\d{3}$/.test(value)
  }
})

extend('alpha_num_spaces', {
  message: '英数字、スペースのみ入力してください。',
  validate: (value) => {
    return /^[a-zA-Z0-9 ]+$/.test(value)
  }
})

extend('half_size_alpha_naka_num', {
  message: '半角の英数字大文字とカタカナ入力してください。',
  validate: (value) => {
    return /^[A-Z0-9ｦ-ﾟ]+$/.test(value)
  }
})

extend('billClassCombination', {
  params: ['billClass'],
  validate(value, {billClass}) {
    if (value == Const.ShippingTypeClass.direct && (billClass == 12 || billClass == 15)) {
      return false;
    } else {
      return true;
    }
  },
  message: '直送は通常以外は選択不可能です。'
})

extend('oldProcessMonthYear', {
  params: ['processMonthYear'],
  validate(value, {processMonthYear}) {
    if (value && processMonthYear && getNullStr(value) != '' && getNullStr(processMonthYear) != '') {
      return dateConsistency(processMonthYear + '01', value);
    } else {
      return true;
    }
  },
  message: '処理年月よりも過去を指定することは不可能です。'
})

extend('oldProcessMonthYearChange', {
  params: ['processMonthYear', 'dateInit'],
  validate(value, {processMonthYear, dateInit}) {
    if (value == dateInit) {
      // 初期日付から変更していない場合はチェックしない
      return true;
    }
    if (value && processMonthYear && getNullStr(value) != '' && getNullStr(processMonthYear) != '') {
      return dateConsistency(processMonthYear + '01', value);
    } else {
      return true;
    }
  },
  message: '処理年月よりも過去を指定することは不可能です。'
})

extend('oldProcessMonthYearYm', {
  params: ['processMonthYear'],
  validate(value, {processMonthYear}) {
    if (value && processMonthYear && getNullStr(value) != '' && getNullStr(processMonthYear) != '') {
      return dateConsistency(processMonthYear + '01', value + '-01');
    } else {
      return true;
    }
  },
  message: '処理年月よりも過去を指定することは不可能です。'
})

extend('oldDate', {
  validate(value) {
    if (value) {
      let today = formatCurDate('YYYY-MM-DD');
      return dateConsistency(today, value);
    } else {
      return true;
    }
  },
  message: '過去日を指定することは不可能です。'
})

extend('offset_client_id', {
  params: ['offsetClientId'],
  validate(value, {offsetClientId}) {
    // 金種で「7：相殺」「D：相殺消」を選択し、相殺取引先が存在しない場合
    if ((value == Const.denomination.offset || value == Const.denomination.offsetTax) &&
    offsetClientId == 0) {
      return false;
    } else {
      return true;
    }
  },
  message: '相殺相手先が存在しない取引先が選択されています。'
})

extend('orders_receives_between', {
  params: ['orderReceiveBillClass','productSundriesClass', 'shippingQuantity', 'isDeleted'],
  validate(value, {orderReceiveBillClass, productSundriesClass, shippingQuantity, isDeleted}) {
    if (isDeleted == 1) {
      return true;
    }
    let min = 0;
    let max = 0;
    if (orderReceiveBillClass == Const.OrderReceiveBillClass.return) {
      min = -99999;
      if (productSundriesClass == Const.SundriesClass.normal) {
        max = -1;
      } else {
        max = 0;
      }
      if (shippingQuantity < 0) {
        max = shippingQuantity;
      }
    } else {
      max = 99999;
      if (productSundriesClass == Const.SundriesClass.normal) {
        min = 1;
      } else {
        min = 0;
      }
      if (shippingQuantity > 0) {
        min = shippingQuantity;
      }
    }
    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message(field, args) {
    let min = 0;
    let max = 0;
    if (args.orderReceiveBillClass == Const.OrderReceiveBillClass.return) {
      min = -99999;
      if (args.productSundriesClass == Const.SundriesClass.normal) {
        max = -1;
      } else {
        max = 0;
      }
      if (args.shippingQuantity < 0) {
        max = args.shippingQuantity;
      }
    } else {
      max = 99999;
      if (args.productSundriesClass == Const.SundriesClass.normal) {
        min = 1;
      } else {
        min = 0;
      }
      if (args.shippingQuantity > 0) {
        min = args.shippingQuantity;
      }
    }
    return min + '以上' + max + '以下で入力してください。';
  },
})

extend('ships_between', {
  params: ['reserveQuantity', 'salesIssueQuantity'],
  validate(value, {reserveQuantity, salesIssueQuantity}) {
    let min = 0;
    let max = 0;
    if (reserveQuantity > salesIssueQuantity) {
      min = salesIssueQuantity;
      max = reserveQuantity;
    } else {
      min = reserveQuantity;
      max = salesIssueQuantity;
    }
    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message(field, args) {
    let min = 0;
    let max = 0;
    if (args.reserveQuantity > args.salesIssueQuantity) {
      min = args.salesIssueQuantity;
      max = args.reserveQuantity;
    } else {
      min = args.reserveQuantity;
      max = args.salesIssueQuantity;
    }
    return min + '以上' + max + '以下で入力してください。';
  },
})

extend('yyyymm', {
  message: 'YYYYMM形式で指定してください。YYYYは西暦4桁、MMは月2桁（1月の場合は01）です。',
  validate(value) {
    if (!value.match(/^\d{6}$/)) {
      return false;
    }
    const month = Number.parseInt(value.substring(4), 10);
    if (month < 1 || 12 < month) {
      return false;
    }
    return true;
  }
});

extend('orders_between', {
  params: ['productSundriesClass', 'stockQuantity', 'isDeleted'],
  validate(value, {productSundriesClass, stockQuantity, isDeleted}) {
    if (isDeleted == 1) {
      return true;
    }
    let min = 0;
    let max = 0;

    max = 99999;
    if (productSundriesClass == Const.SundriesClass.normal) {
      min = 1;
    } else {
      min = 0;
    }
    if (stockQuantity > 0) {
      min = stockQuantity;
    }

    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message(field, args) {
    let min = 0;
    let max = 0;
    max = 99999;
    if (args.productSundriesClass == Const.SundriesClass.normal) {
      min = 1;
    } else {
      min = 0;
    }
    if (args.stockQuantity > 0) {
      min = args.stockQuantity;
    }
    return min + '以上' + max + '以下で入力してください。';
  },
});

extend('purchase_between', {
  params: ['billClass','productSundriesClass'],
  validate(value, {billClass, productSundriesClass}) {
    let min = 0;
    let max = 0;
    if (billClass == Const.Tradition.purchaseReturn) {
      min = -99999;
      if (productSundriesClass == Const.SundriesClass.normal) {
        max = -1;
      } else {
        max = 0;
      }
    } else {
      max = 99999;
      if (productSundriesClass == Const.SundriesClass.normal) {
        min = 1;
      } else {
        min = 0;
      }
    }
    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message(field, args) {
    let min = 0;
    let max = 0;
    if (args.billClass == Const.Tradition.purchaseReturn) {
      min = -99999;
      if (args.productSundriesClass == Const.SundriesClass.normal) {
        max = -1;
      } else {
        max = 0;
      }
    } else {
      max = 99999;
      if (args.productSundriesClass == Const.SundriesClass.normal) {
        min = 1;
      } else {
        min = 0;
      }
    }
    return min + '以上' + max + '以下で入力してください。';
  },
});

extend('storage_between', {
  params: ['billClass','balance'],
  validate(value, {billClass, balance}) {
    let min = 1;
    let max = 99999;
    if (billClass == Const.Tradition.storageDel ||
      billClass == Const.Tradition.storageMove) {
      max = balance;
    }
    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message(field, args) {
    let min = 1;
    let max = 99999;
    if (args.billClass == Const.Tradition.storageDel ||
      args.billClass == Const.Tradition.storageMove) {
      max = args.balance;
    }
    if (max == 0) {
      return '在庫がありません。';
    } else {
      return min + '以上' + max + '以下で入力してください。';
    }
  },
});

extend('client_sundries_class', {
  params: ['sundriesClass'],
  validate(value, {sundriesClass}) {
    if (sundriesClass == Const.SundriesClass.normal) {
      // 諸口区分「0：通常」
      return true;
    } else {
      // 諸口区分「1：諸口」
      return false;
    }
  },
  message: '諸口の取引先は選択できません。'
});

extend('case_conversion_class', {
  params: ['caseConversionClass', 'checkCaseFlg'],
  validate(value, {caseConversionClass, checkCaseFlg}) {
    // ケース換算チェックが必要な場合以外はチェックしない
    // （チェック必要：「直送、発注、仕入」）
    if (checkCaseFlg == false) {
      return true;
    }
    // ケース換算区分「0:する」の場合
    if (caseConversionClass == Const.CaseConversionClassDef.conversion) {
      return false;
    } else {
      return true;
    }
  },
  message: 'ケース換算製品は選択できません。'
});

extend('minimum_quantity', {
  params: ['minimumQuantity', 'caseConversionClass', 'inventoryControlClass'],
  validate(value, {minimumQuantity, caseConversionClass, inventoryControlClass}) {
    if (inventoryControlClass == Const.InventoryControlClassDef.noInventory) {
      // 在庫管理区分「1:しない」の場合、OK
      return true;
    } else if (minimumQuantity == 0 && caseConversionClass == Const.CaseConversionClassDef.conversion) {
      // 最低発注数が0、且つ、ケース換算区分「0:する」の場合、OK
      return true;
    } else if (minimumQuantity >= 1 && caseConversionClass == Const.CaseConversionClassDef.noConversion) {
      // 最低発注数が1以上、且つ、ケース換算区分「1:しない」の場合、OK
      return true;
    } else {
      // 上記以外の場合はNG
      return false;
    }
  },
  message: 'ケース換算区分と最低発注数の組合せが不正です。'
});

extend('case_conversion_product', {
  params: ['caseConversionErr'],
  validate(value, {caseConversionErr}) {
    if (caseConversionErr == true) {
      return false;
    } else {
      return true;
    }
  },
  message: 'ケース換算部分のマスタ情報の登録が不正です。'
});

extend('set_val_between', {
  params: ['min','max','title'],
  validate(value, {min, max}) {
    if (min <= value && value <= max) {
      return true;
    } else {
      return false;
    }
  },
  message: '{title}は{min}以上{max}以下で入力してください。',
});

extend('not_same_number', {
  params: ['other', 'title'],
  validate(value, { other }) {
    if (value === '' || other === '') {
      return true;
    }
    return Number(value) !== Number(other);
  },
  message: '{title}と異なる値を入力してください。',
});

extend('estimate_suppliers_code', {
  validate: (value) => {
    const v = Number(value)
    if (v == 0) {
      return true
    }
    if (99999 < v && v < 1000000) {
      return true
    }
    return false
  },
  message: '0か6桁の数字を入力してください。',
});

extend('estimate_product_code', {
  validate: (value) => {
    const v = Number(value)
    if (v == 0) {
      return true
    }
    if (9999999 < v && v < 100000000) {
      return true
    }
    return false
  },
  message: '0か8桁の数字を入力してください。',
});

extend('order_supplier_id', {
  params: ['inventoryControlClass','caseConversionClass'],
  validate(value, {inventoryControlClass, caseConversionClass}) {
    // 在庫管理する製品で発注先コード0の製品は登録不可
    // ケース換算区分「0:する」の場合
    if (value == 0) {
      //console.log(1);
      //console.log(inventoryControlClass);
      //console.log(caseConversionClass);
      // 発注先コードが0（未設定）の製品
      if (inventoryControlClass == Const.InventoryControlClassDef.noInventory ||
      caseConversionClass == Const.CaseConversionClassDef.conversion) {
        // 在庫管理区分「1:しない」、または、ケース換算区分「0:する」の場合は発注先コードは未設定でも問題なし
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },
  message: '発注先が未設定です。'
});

// コンポーネントの登録
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
