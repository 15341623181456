/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const delete_m_9a_products_classes = /* GraphQL */ `
  mutation Delete_m_9a_products_classes($product_class_id: Int!) {
    delete_m_9a_products_classes(product_class_id: $product_class_id) {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_9a_products_classes = /* GraphQL */ `
  mutation Create_m_9a_products_classes(
    $create_m_9a_products_classesInput: Create_m_9a_products_classesInput!
  ) {
    create_m_9a_products_classes(
      create_m_9a_products_classesInput: $create_m_9a_products_classesInput
    ) {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_9a_products_classes = /* GraphQL */ `
  mutation Update_m_9a_products_classes(
    $update_m_9a_products_classesInput: Update_m_9a_products_classesInput!
  ) {
    update_m_9a_products_classes(
      update_m_9a_products_classesInput: $update_m_9a_products_classesInput
    ) {
      product_class_id
      product_class_name
      max
      min
      sequence
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_billings = /* GraphQL */ `
  mutation Delete_m_billings($site_id: Int!) {
    delete_m_billings(site_id: $site_id) {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_billings = /* GraphQL */ `
  mutation Create_m_billings($create_m_billingsInput: Create_m_billingsInput!) {
    create_m_billings(create_m_billingsInput: $create_m_billingsInput) {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_billings = /* GraphQL */ `
  mutation Update_m_billings($update_m_billingsInput: Update_m_billingsInput!) {
    update_m_billings(update_m_billingsInput: $update_m_billingsInput) {
      client_id
      site_id
      office_id
      closing_date
      billing_start_date
      billing_end_date
      billing_output_class
      client_id_first_digit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_calendar = /* GraphQL */ `
  mutation Delete_m_calendar($delete_m_calendarInput: Delete_m_calendarInput!) {
    delete_m_calendar(delete_m_calendarInput: $delete_m_calendarInput) {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_calendar = /* GraphQL */ `
  mutation Create_m_calendar($create_m_calendarInput: Create_m_calendarInput!) {
    create_m_calendar(create_m_calendarInput: $create_m_calendarInput) {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_calendar = /* GraphQL */ `
  mutation Update_m_calendar($update_m_calendarInput: Update_m_calendarInput!) {
    update_m_calendar(update_m_calendarInput: $update_m_calendarInput) {
      date
      event_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_clients = /* GraphQL */ `
  mutation Delete_m_clients($client_id: Int!) {
    delete_m_clients(client_id: $client_id) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_clients = /* GraphQL */ `
  mutation Create_m_clients($create_m_clientsInput: Create_m_clientsInput!) {
    create_m_clients(create_m_clientsInput: $create_m_clientsInput) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_clients = /* GraphQL */ `
  mutation Update_m_clients($update_m_clientsInput: Update_m_clientsInput!) {
    update_m_clients(update_m_clientsInput: $update_m_clientsInput) {
      id
      client_class
      client_id
      client_id_first_digit
      client_name_kanji
      client_name_kana
      department_1
      department_2
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      staff_id
      latest_site_id
      check_contents
      sundries_class
      closing_date
      billing_class
      previous_billing_closing_date
      business_class
      offset_client_id
      billing_output_class
      payment_class
      payment_scheduled
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      billing_type_class
      union_id
      service_order_class
      order_condition_quantity
      order_condition_amount
      discount_rate
      order_count_class
      transfer_fee
      transfer_fee_2
      transfer_fee_3
      web_issue_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_clients_parent_child = /* GraphQL */ `
  mutation Delete_m_clients_parent_child($where_clause: String!) {
    delete_m_clients_parent_child(where_clause: $where_clause) {
      client_class_parent
      client_id_parent
      client_name_kanji
      client_class_branch
      client_id_branch
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_clients_parent_child = /* GraphQL */ `
  mutation Create_m_clients_parent_child(
    $create_m_clients_parent_childInput: Create_m_clients_parent_childInput!
  ) {
    create_m_clients_parent_child(
      create_m_clients_parent_childInput: $create_m_clients_parent_childInput
    ) {
      client_class_parent
      client_id_parent
      client_name_kanji
      client_class_branch
      client_id_branch
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_clients_products = /* GraphQL */ `
  mutation Delete_m_clients_products($where_clause: String!) {
    delete_m_clients_products(where_clause: $where_clause) {
      client_class
      client_id
      product_id
      unit_price_effective_date
      sales_unit_price
      reason
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_clients_products = /* GraphQL */ `
  mutation Create_m_clients_products(
    $create_m_clients_productsInput: Create_m_clients_productsInput!
  ) {
    create_m_clients_products(
      create_m_clients_productsInput: $create_m_clients_productsInput
    ) {
      client_class
      client_id
      product_id
      unit_price_effective_date
      sales_unit_price
      reason
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_clients_products = /* GraphQL */ `
  mutation Update_m_clients_products(
    $update_m_clients_productsInput: Update_m_clients_productsInput!
  ) {
    update_m_clients_products(
      update_m_clients_productsInput: $update_m_clients_productsInput
    ) {
      client_class
      client_id
      product_id
      unit_price_effective_date
      sales_unit_price
      reason
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_clients_sites = /* GraphQL */ `
  mutation Delete_m_clients_sites($site_id: Int!) {
    delete_m_clients_sites(site_id: $site_id) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_clients_sites = /* GraphQL */ `
  mutation Create_m_clients_sites(
    $create_m_clients_sitesInput: Create_m_clients_sitesInput!
  ) {
    create_m_clients_sites(
      create_m_clients_sitesInput: $create_m_clients_sitesInput
    ) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_clients_sites = /* GraphQL */ `
  mutation Update_m_clients_sites(
    $update_m_clients_sitesInput: Update_m_clients_sitesInput!
  ) {
    update_m_clients_sites(
      update_m_clients_sitesInput: $update_m_clients_sitesInput
    ) {
      client_id
      site_id
      shipping_code
      client_site_name_kanji
      client_site_name_kana
      client_site_zip_code
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      order_print_class
      order_print_client_name
      ast_id
      client_control_site_id
      department_branch
      billings_print_class
      billings_print_client_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_control = /* GraphQL */ `
  mutation Update_m_control($update_m_controlInput: Update_m_controlInput!) {
    update_m_control(update_m_controlInput: $update_m_controlInput) {
      process_month_year
      beginning_month
      tax_rate
      new_tax_rate
      new_tax_start_date
      system_state
      monthly_update_state
      monthly_update_start_date
      monthly_update_end_date
      switch_client_id_state
      switch_client_id_start_date
      switch_client_id_end_date
      switch_product_id_state
      switch_product_id_start_date
      switch_product_id_end_date
      closing_update_state
      products_bulk_update_state
      light_tax_rate
      new_light_tax_rate
      light_tax_mark
      invoice_input_no
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_deliverys_designs_classes = /* GraphQL */ `
  mutation Delete_m_deliverys_designs_classes($delivery_design_class: String!) {
    delete_m_deliverys_designs_classes(
      delivery_design_class: $delivery_design_class
    ) {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_deliverys_designs_classes = /* GraphQL */ `
  mutation Create_m_deliverys_designs_classes(
    $create_m_deliverys_designs_classesInput: Create_m_deliverys_designs_classesInput!
  ) {
    create_m_deliverys_designs_classes(
      create_m_deliverys_designs_classesInput: $create_m_deliverys_designs_classesInput
    ) {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_deliverys_designs_classes = /* GraphQL */ `
  mutation Update_m_deliverys_designs_classes(
    $update_m_deliverys_designs_classesInput: Update_m_deliverys_designs_classesInput!
  ) {
    update_m_deliverys_designs_classes(
      update_m_deliverys_designs_classesInput: $update_m_deliverys_designs_classesInput
    ) {
      delivery_design_class
      delivery_design_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_inquiries_definitions = /* GraphQL */ `
  mutation Delete_m_inquiries_definitions($id: Int!) {
    delete_m_inquiries_definitions(id: $id) {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_inquiries_definitions = /* GraphQL */ `
  mutation Create_m_inquiries_definitions(
    $create_m_inquiries_definitionsInput: Create_m_inquiries_definitionsInput!
  ) {
    create_m_inquiries_definitions(
      create_m_inquiries_definitionsInput: $create_m_inquiries_definitionsInput
    ) {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_inquiries_definitions = /* GraphQL */ `
  mutation Update_m_inquiries_definitions(
    $update_m_inquiries_definitionsInput: Update_m_inquiries_definitionsInput!
  ) {
    update_m_inquiries_definitions(
      update_m_inquiries_definitionsInput: $update_m_inquiries_definitionsInput
    ) {
      id
      inquiry_name
      description
      froms
      wheres
      load_method
      load_direction
      output_items
      cross_left_keys
      cross_top_keys
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_service_classes = /* GraphQL */ `
  mutation Delete_m_service_classes($service_class: String!) {
    delete_m_service_classes(service_class: $service_class) {
      service_class
      service_class_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_service_classes = /* GraphQL */ `
  mutation Create_m_service_classes(
    $create_m_service_classesInput: Create_m_service_classesInput!
  ) {
    create_m_service_classes(
      create_m_service_classesInput: $create_m_service_classesInput
    ) {
      service_class
      service_class_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_service_classes = /* GraphQL */ `
  mutation Update_m_service_classes(
    $update_m_service_classesInput: Update_m_service_classesInput!
  ) {
    update_m_service_classes(
      update_m_service_classesInput: $update_m_service_classesInput
    ) {
      service_class
      service_class_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_offices = /* GraphQL */ `
  mutation Delete_m_offices($office_id: Int!) {
    delete_m_offices(office_id: $office_id) {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_offices = /* GraphQL */ `
  mutation Create_m_offices($create_m_officesInput: Create_m_officesInput!) {
    create_m_offices(create_m_officesInput: $create_m_officesInput) {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_offices = /* GraphQL */ `
  mutation Update_m_offices($update_m_officesInput: Update_m_officesInput!) {
    update_m_offices(update_m_officesInput: $update_m_officesInput) {
      office_id
      office_name_kanji
      office_name_kana
      zip_code
      address_1
      address_2
      phone_number
      fax_number
      e_mail
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_payments = /* GraphQL */ `
  mutation Delete_m_payments($office_id: Int!) {
    delete_m_payments(office_id: $office_id) {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_payments = /* GraphQL */ `
  mutation Create_m_payments($create_m_paymentsInput: Create_m_paymentsInput!) {
    create_m_payments(create_m_paymentsInput: $create_m_paymentsInput) {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_payments = /* GraphQL */ `
  mutation Update_m_payments($update_m_paymentsInput: Update_m_paymentsInput!) {
    update_m_payments(update_m_paymentsInput: $update_m_paymentsInput) {
      client_id
      office_id
      closing_date
      payment_start_date
      payment_end_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products = /* GraphQL */ `
  mutation Delete_m_products($id: Int!) {
    delete_m_products(id: $id) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products = /* GraphQL */ `
  mutation Create_m_products($create_m_productsInput: Create_m_productsInput!) {
    create_m_products(create_m_productsInput: $create_m_productsInput) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products = /* GraphQL */ `
  mutation Update_m_products($update_m_productsInput: Update_m_productsInput!) {
    update_m_products(update_m_productsInput: $update_m_productsInput) {
      id
      product_id
      product_class_id
      is_9A
      product_name_kanji
      product_name_kana
      unit
      regular_price
      sales_unit_price
      product_check_class
      sundries_class
      inventory_control_class
      product_tax_rate_class_sales
      product_tax_rate_class_purchase
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_categories = /* GraphQL */ `
  mutation Delete_m_products_categories($category_5: Int!) {
    delete_m_products_categories(category_5: $category_5) {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_categories = /* GraphQL */ `
  mutation Create_m_products_categories(
    $create_m_products_categoriesInput: Create_m_products_categoriesInput!
  ) {
    create_m_products_categories(
      create_m_products_categoriesInput: $create_m_products_categoriesInput
    ) {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_categories = /* GraphQL */ `
  mutation Update_m_products_categories(
    $update_m_products_categoriesInput: Update_m_products_categoriesInput!
  ) {
    update_m_products_categories(
      update_m_products_categoriesInput: $update_m_products_categoriesInput
    ) {
      product_id
      category_1
      category_2
      category_3
      category_4
      category_5
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_categories_names = /* GraphQL */ `
  mutation Delete_m_products_categories_names($category_5: Int!) {
    delete_m_products_categories_names(category_5: $category_5) {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_categories_names = /* GraphQL */ `
  mutation Create_m_products_categories_names(
    $create_m_products_categories_namesInput: Create_m_products_categories_namesInput!
  ) {
    create_m_products_categories_names(
      create_m_products_categories_namesInput: $create_m_products_categories_namesInput
    ) {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_categories_names = /* GraphQL */ `
  mutation Update_m_products_categories_names(
    $update_m_products_categories_namesInput: Update_m_products_categories_namesInput!
  ) {
    update_m_products_categories_names(
      update_m_products_categories_namesInput: $update_m_products_categories_namesInput
    ) {
      category_class
      category_1
      category_2
      category_3
      category_4
      category_5
      category_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_compositions = /* GraphQL */ `
  mutation Delete_m_products_compositions($component_product_id: Int!) {
    delete_m_products_compositions(
      component_product_id: $component_product_id
    ) {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_compositions = /* GraphQL */ `
  mutation Create_m_products_compositions(
    $create_m_products_compositionsInput: Create_m_products_compositionsInput!
  ) {
    create_m_products_compositions(
      create_m_products_compositionsInput: $create_m_products_compositionsInput
    ) {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_compositions = /* GraphQL */ `
  mutation Update_m_products_compositions(
    $update_m_products_compositionsInput: Update_m_products_compositionsInput!
  ) {
    update_m_products_compositions(
      update_m_products_compositionsInput: $update_m_products_compositionsInput
    ) {
      product_id
      component_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_details = /* GraphQL */ `
  mutation Delete_m_products_details($office_id: Int!) {
    delete_m_products_details(office_id: $office_id) {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_details = /* GraphQL */ `
  mutation Create_m_products_details(
    $create_m_products_detailsInput: Create_m_products_detailsInput!
  ) {
    create_m_products_details(
      create_m_products_detailsInput: $create_m_products_detailsInput
    ) {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_details = /* GraphQL */ `
  mutation Update_m_products_details(
    $update_m_products_detailsInput: Update_m_products_detailsInput!
  ) {
    update_m_products_details(
      update_m_products_detailsInput: $update_m_products_detailsInput
    ) {
      product_id
      office_id
      purchase_price
      cost_price
      place_1
      place_2
      place_3
      place_4
      picking_print_class
      appropriate_stock
      case_conversion_class
      supplier_product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_orders = /* GraphQL */ `
  mutation Delete_m_products_orders($office_id: Int!) {
    delete_m_products_orders(office_id: $office_id) {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_orders = /* GraphQL */ `
  mutation Create_m_products_orders(
    $create_m_products_ordersInput: Create_m_products_ordersInput!
  ) {
    create_m_products_orders(
      create_m_products_ordersInput: $create_m_products_ordersInput
    ) {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_orders = /* GraphQL */ `
  mutation Update_m_products_orders(
    $update_m_products_ordersInput: Update_m_products_ordersInput!
  ) {
    update_m_products_orders(
      update_m_products_ordersInput: $update_m_products_ordersInput
    ) {
      product_id
      office_id
      client_id
      minimum_quantity
      service_quantity
      lots_quantity
      lead_time
      service_whether_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_prices_groups = /* GraphQL */ `
  mutation Delete_m_products_prices_groups($product_id: Int!) {
    delete_m_products_prices_groups(product_id: $product_id) {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_prices_groups = /* GraphQL */ `
  mutation Create_m_products_prices_groups(
    $create_m_products_prices_groupsInput: Create_m_products_prices_groupsInput!
  ) {
    create_m_products_prices_groups(
      create_m_products_prices_groupsInput: $create_m_products_prices_groupsInput
    ) {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_prices_groups = /* GraphQL */ `
  mutation Update_m_products_prices_groups(
    $update_m_products_prices_groupsInput: Update_m_products_prices_groupsInput!
  ) {
    update_m_products_prices_groups(
      update_m_products_prices_groupsInput: $update_m_products_prices_groupsInput
    ) {
      product_group_id
      product_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_products_units_conversions = /* GraphQL */ `
  mutation Delete_m_products_units_conversions($loose_product_id: Int!) {
    delete_m_products_units_conversions(loose_product_id: $loose_product_id) {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_products_units_conversions = /* GraphQL */ `
  mutation Create_m_products_units_conversions(
    $create_m_products_units_conversionsInput: Create_m_products_units_conversionsInput!
  ) {
    create_m_products_units_conversions(
      create_m_products_units_conversionsInput: $create_m_products_units_conversionsInput
    ) {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_products_units_conversions = /* GraphQL */ `
  mutation Update_m_products_units_conversions(
    $update_m_products_units_conversionsInput: Update_m_products_units_conversionsInput!
  ) {
    update_m_products_units_conversions(
      update_m_products_units_conversionsInput: $update_m_products_units_conversionsInput
    ) {
      case_product_id
      loose_product_id
      quantity
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_routes = /* GraphQL */ `
  mutation Delete_m_routes($shipping_code: String!) {
    delete_m_routes(shipping_code: $shipping_code) {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_routes = /* GraphQL */ `
  mutation Create_m_routes($create_m_routesInput: Create_m_routesInput!) {
    create_m_routes(create_m_routesInput: $create_m_routesInput) {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_routes = /* GraphQL */ `
  mutation Update_m_routes($update_m_routesInput: Update_m_routesInput!) {
    update_m_routes(update_m_routesInput: $update_m_routesInput) {
      shipping_code
      shipping_name
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_staffs = /* GraphQL */ `
  mutation Delete_m_staffs($staff_id: Int!) {
    delete_m_staffs(staff_id: $staff_id) {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_staffs = /* GraphQL */ `
  mutation Create_m_staffs($create_m_staffsInput: Create_m_staffsInput!) {
    create_m_staffs(create_m_staffsInput: $create_m_staffsInput) {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_staffs = /* GraphQL */ `
  mutation Update_m_staffs($update_m_staffsInput: Update_m_staffsInput!) {
    update_m_staffs(update_m_staffsInput: $update_m_staffsInput) {
      staff_id
      staff_name_kanji
      staff_name_kana
      staff_name_stamp
      office_id
      login_id
      mail_address
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_stocks = /* GraphQL */ `
  mutation Delete_m_stocks($product_id: Int!) {
    delete_m_stocks(product_id: $product_id) {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_stocks = /* GraphQL */ `
  mutation Create_m_stocks($create_m_stocksInput: Create_m_stocksInput!) {
    create_m_stocks(create_m_stocksInput: $create_m_stocksInput) {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_stocks = /* GraphQL */ `
  mutation Update_m_stocks($update_m_stocksInput: Update_m_stocksInput!) {
    update_m_stocks(update_m_stocksInput: $update_m_stocksInput) {
      month_year
      office_id
      product_id
      balance
      monthly_purchase_stock
      monthly_tuning_stock
      monthly_salse_ship
      monthly_tuning_ship
      inventory_reserve_count
      quantity_stock
      quantity_stock_reserve
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_suppliers_offices = /* GraphQL */ `
  mutation Delete_m_suppliers_offices(
    $delete_m_suppliers_officesInput: Delete_m_suppliers_officesInput!
  ) {
    delete_m_suppliers_offices(
      delete_m_suppliers_officesInput: $delete_m_suppliers_officesInput
    ) {
      supplier_id
      office_id
      supplier_control_office_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_suppliers_offices = /* GraphQL */ `
  mutation Create_m_suppliers_offices(
    $create_m_suppliers_officesInput: Create_m_suppliers_officesInput!
  ) {
    create_m_suppliers_offices(
      create_m_suppliers_officesInput: $create_m_suppliers_officesInput
    ) {
      supplier_id
      office_id
      supplier_control_office_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_suppliers_offices = /* GraphQL */ `
  mutation Update_m_suppliers_offices(
    $update_m_suppliers_officesInput: Update_m_suppliers_officesInput!
  ) {
    update_m_suppliers_offices(
      update_m_suppliers_officesInput: $update_m_suppliers_officesInput
    ) {
      supplier_id
      office_id
      supplier_control_office_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_suppliers_sites = /* GraphQL */ `
  mutation Delete_m_suppliers_sites($site_id: Int!) {
    delete_m_suppliers_sites(site_id: $site_id) {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_suppliers_sites = /* GraphQL */ `
  mutation Create_m_suppliers_sites(
    $create_m_suppliers_sitesInput: Create_m_suppliers_sitesInput!
  ) {
    create_m_suppliers_sites(
      create_m_suppliers_sitesInput: $create_m_suppliers_sitesInput
    ) {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_suppliers_sites = /* GraphQL */ `
  mutation Update_m_suppliers_sites(
    $update_m_suppliers_sitesInput: Update_m_suppliers_sitesInput!
  ) {
    update_m_suppliers_sites(
      update_m_suppliers_sitesInput: $update_m_suppliers_sitesInput
    ) {
      supplier_id
      client_id
      site_id
      supplier_control_site_id
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_m_versions = /* GraphQL */ `
  mutation Delete_m_versions($type_name: String!) {
    delete_m_versions(type_name: $type_name) {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_m_versions = /* GraphQL */ `
  mutation Create_m_versions($create_m_versionsInput: Create_m_versionsInput!) {
    create_m_versions(create_m_versionsInput: $create_m_versionsInput) {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_m_versions = /* GraphQL */ `
  mutation Update_m_versions($update_m_versionsInput: Update_m_versionsInput!) {
    update_m_versions(update_m_versionsInput: $update_m_versionsInput) {
      type_name
      version_number
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_billings_balances = /* GraphQL */ `
  mutation Delete_t_billings_balances($site_id: Int!) {
    delete_t_billings_balances(site_id: $site_id) {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_billings_balances = /* GraphQL */ `
  mutation Create_t_billings_balances(
    $create_t_billings_balancesInput: Create_t_billings_balancesInput!
  ) {
    create_t_billings_balances(
      create_t_billings_balancesInput: $create_t_billings_balancesInput
    ) {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_billings_balances = /* GraphQL */ `
  mutation Update_t_billings_balances(
    $update_t_billings_balancesInput: Update_t_billings_balancesInput!
  ) {
    update_t_billings_balances(
      update_t_billings_balancesInput: $update_t_billings_balancesInput
    ) {
      billing_month_year
      client_id
      site_id
      office_id
      client_id_first_digit
      billing_start_date
      billing_end_date
      todo_1
      todo_2
      todo_3
      todo_4
      todo_5
      todo_6
      todo_7
      todo_8
      todo_9
      todo_10
      todo_11
      todo_12
      todo_13
      todo_14
      todo_15
      todo_16
      todo_17
      payment_scheduled
      billing_output_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_billings_remainings = /* GraphQL */ `
  mutation Delete_t_billings_remainings($billing_date: AWSDate!) {
    delete_t_billings_remainings(billing_date: $billing_date) {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_billings_remainings = /* GraphQL */ `
  mutation Create_t_billings_remainings(
    $create_t_billings_remainingsInput: Create_t_billings_remainingsInput!
  ) {
    create_t_billings_remainings(
      create_t_billings_remainingsInput: $create_t_billings_remainingsInput
    ) {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_billings_remainings = /* GraphQL */ `
  mutation Update_t_billings_remainings(
    $update_t_billings_remainingsInput: Update_t_billings_remainingsInput!
  ) {
    update_t_billings_remainings(
      update_t_billings_remainingsInput: $update_t_billings_remainingsInput
    ) {
      client_id
      billing_date
      billing_amount
      billing_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_billings_unsettled = /* GraphQL */ `
  mutation Delete_t_billings_unsettled($billing_no: Int!) {
    delete_t_billings_unsettled(billing_no: $billing_no) {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_billings_unsettled = /* GraphQL */ `
  mutation Create_t_billings_unsettled(
    $create_t_billings_unsettledInput: Create_t_billings_unsettledInput!
  ) {
    create_t_billings_unsettled(
      create_t_billings_unsettledInput: $create_t_billings_unsettledInput
    ) {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_billings_unsettled = /* GraphQL */ `
  mutation Update_t_billings_unsettled(
    $update_t_billings_unsettledInput: Update_t_billings_unsettledInput!
  ) {
    update_t_billings_unsettled(
      update_t_billings_unsettledInput: $update_t_billings_unsettledInput
    ) {
      client_class
      client_id
      billing_date
      billing_no
      billing_row
      denominations
      amount
      summary
      account_support
      settlement_scheduled
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_estimate = /* GraphQL */ `
  mutation Delete_t_estimate($estimate_id: Int!) {
    delete_t_estimate(estimate_id: $estimate_id) {
      estimate_id
      estimate_row
      office_id
      office_name_kanji
      client_class
      client_id
      client_name_kanji
      client_print_name
      estimate_date
      client_staff_id
      client_staff_name_kanji
      client_staff_name_stamp
      created_staff_id
      created_staff_name_kanji
      created_staff_name_stamp
      estimate_kind
      tax_type
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      contents
      delivery_date
      estimate_amount
      tax
      site_id
      client_site_name_kanji
      payment_way
      estimate_period
      estimate_period_sentence
      summary
      product_id
      product_name_kanji
      product_note
      product_quantity
      product_unit
      product_sales_unit_price
      product_purchase_price
      product_amount
      created_date
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_estimate = /* GraphQL */ `
  mutation Create_t_estimate($create_t_estimateInput: Create_t_estimateInput!) {
    create_t_estimate(create_t_estimateInput: $create_t_estimateInput) {
      estimate_id
      estimate_row
      office_id
      office_name_kanji
      client_class
      client_id
      client_name_kanji
      client_print_name
      estimate_date
      client_staff_id
      client_staff_name_kanji
      client_staff_name_stamp
      created_staff_id
      created_staff_name_kanji
      created_staff_name_stamp
      estimate_kind
      tax_type
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      contents
      delivery_date
      estimate_amount
      tax
      site_id
      client_site_name_kanji
      payment_way
      estimate_period
      estimate_period_sentence
      summary
      product_id
      product_name_kanji
      product_note
      product_quantity
      product_unit
      product_sales_unit_price
      product_purchase_price
      product_amount
      created_date
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_inventories = /* GraphQL */ `
  mutation Delete_t_inventories(
    $inventory_no: Int!
    $office_id: Int!
    $product_id: Int!
  ) {
    delete_t_inventories(
      inventory_no: $inventory_no
      office_id: $office_id
      product_id: $product_id
    ) {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_inventories = /* GraphQL */ `
  mutation Create_t_inventories(
    $create_t_inventoriesInput: Create_t_inventoriesInput!
  ) {
    create_t_inventories(
      create_t_inventoriesInput: $create_t_inventoriesInput
    ) {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_inventories = /* GraphQL */ `
  mutation Update_t_inventories(
    $update_t_inventoriesInput: Update_t_inventoriesInput!
  ) {
    update_t_inventories(
      update_t_inventoriesInput: $update_t_inventoriesInput
    ) {
      inventory_no
      office_id
      product_id
      sort_order
      product_name
      inventory_count
      shelves_count
      inventory_entry_user_id
      input_inventory_datetime
      add_flg
      finished_flg
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_inventories_histories = /* GraphQL */ `
  mutation Delete_t_inventories_histories($inventory_no: Int!) {
    delete_t_inventories_histories(inventory_no: $inventory_no) {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_inventories_histories = /* GraphQL */ `
  mutation Create_t_inventories_histories(
    $create_t_inventories_historiesInput: Create_t_inventories_historiesInput!
  ) {
    create_t_inventories_histories(
      create_t_inventories_historiesInput: $create_t_inventories_historiesInput
    ) {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_inventories_histories = /* GraphQL */ `
  mutation Update_t_inventories_histories(
    $update_t_inventories_historiesInput: Update_t_inventories_historiesInput!
  ) {
    update_t_inventories_histories(
      update_t_inventories_historiesInput: $update_t_inventories_historiesInput
    ) {
      inventory_no
      office_id
      place
      preprocess_datetime
      print_inventory_sheet_datetime
      input_inventory_datetime
      print_stock_variance_datetime
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_logs = /* GraphQL */ `
  mutation Create_t_logs($create_t_logsInput: Create_t_logsInput!) {
    create_t_logs(create_t_logsInput: $create_t_logsInput) {
      id
      timestamp
      user_name
      log_level
      message
      component
      function
      user_agent
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_orders = /* GraphQL */ `
  mutation Delete_t_orders($order_row: Int!) {
    delete_t_orders(order_row: $order_row) {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_orders = /* GraphQL */ `
  mutation Create_t_orders($create_t_ordersInput: Create_t_ordersInput!) {
    create_t_orders(create_t_ordersInput: $create_t_ordersInput) {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_orders = /* GraphQL */ `
  mutation Update_t_orders($update_t_ordersInput: Update_t_ordersInput!) {
    update_t_orders(update_t_ordersInput: $update_t_ordersInput) {
      order_id
      order_row
      order_date
      order_class
      office_id
      client_class
      client_id
      staff_id
      product_id
      product_name
      note
      service_class
      order_quantity
      unit
      order_unit_amount
      order_amount
      tax
      remaining_quantity
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      stock_scheduled_reserve
      union_id
      set_class
      site_id
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      client_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_issue_class
      order_issue_date
      order_number
      order_receive_id
      order_receive_row
      is_daleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_orders_received_set = /* GraphQL */ `
  mutation Delete_t_orders_received_set($order_receive_row_branch: Int!) {
    delete_t_orders_received_set(
      order_receive_row_branch: $order_receive_row_branch
    ) {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_orders_received_set = /* GraphQL */ `
  mutation Create_t_orders_received_set(
    $create_t_orders_received_setInput: Create_t_orders_received_setInput!
  ) {
    create_t_orders_received_set(
      create_t_orders_received_setInput: $create_t_orders_received_setInput
    ) {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_orders_received_set = /* GraphQL */ `
  mutation Update_t_orders_received_set(
    $update_t_orders_received_setInput: Update_t_orders_received_setInput!
  ) {
    update_t_orders_received_set(
      update_t_orders_received_setInput: $update_t_orders_received_setInput
    ) {
      order_receive_id
      order_receive_row
      order_receive_row_branch
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      order_id
      order_row
      order_product_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_orders_receives = /* GraphQL */ `
  mutation Delete_t_orders_receives($order_receive_row: Int!) {
    delete_t_orders_receives(order_receive_row: $order_receive_row) {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_orders_receives = /* GraphQL */ `
  mutation Create_t_orders_receives(
    $create_t_orders_receivesInput: Create_t_orders_receivesInput!
  ) {
    create_t_orders_receives(
      create_t_orders_receivesInput: $create_t_orders_receivesInput
    ) {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_orders_receives = /* GraphQL */ `
  mutation Update_t_orders_receives(
    $update_t_orders_receivesInput: Update_t_orders_receivesInput!
  ) {
    update_t_orders_receives(
      update_t_orders_receivesInput: $update_t_orders_receivesInput
    ) {
      order_receive_id
      order_receive_row
      order_receive_date
      order_receive_staff_id
      shipping_type_class
      office_id
      client_class
      client_id
      client_name
      client_print_class
      client_print_name
      department_1
      department_2
      address_1
      address_2
      zip_code
      phone_number
      sundries_class
      delivery_design_date
      delivery_design_class
      delivery_design
      site_id
      shipping_code
      client_site_name
      client_site_address_1
      client_site_address_2
      delivery_location
      client_site_phone_number
      staff_id
      client_id_first_digit
      product_id
      product_name
      note
      service_class
      order_receive_quantity
      unit
      order_receive_unit_amount
      order_unit_amount
      order_receive_amount
      order_amount
      gross_profit
      tax
      product_class_id
      product_manual_input_class
      inventory_control_class
      client_amount_class
      case_conversion_class
      remaining_quantity
      reserve_quantity_incomplete
      reserve_quantity
      stock_scheduled_reserve
      stock_scheduled_reserve_receive
      shipping_quantity
      sales_tax_class
      tax_calculation_class
      tax_fractionation_class
      order_id
      order_row
      order_product_id
      change_order_client_id
      converted_quantity
      place_1
      place_2
      place_3
      place_4
      set_class
      order_receive_bill_class
      is_bill_return_print
      is_bill_return_count
      unit_price_register_class
      unit_price_effective_date
      unit_price_note
      is_deleted
      input_date
      input_staff_id
      group_register_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_payables_balances_results = /* GraphQL */ `
  mutation Delete_t_payables_balances_results(
    $month_year: Int!
    $supplier_id: Int!
  ) {
    delete_t_payables_balances_results(
      month_year: $month_year
      supplier_id: $supplier_id
    ) {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_payables_balances_results = /* GraphQL */ `
  mutation Create_t_payables_balances_results(
    $create_t_payables_balances_resultsInput: Create_t_payables_balances_resultsInput!
  ) {
    create_t_payables_balances_results(
      create_t_payables_balances_resultsInput: $create_t_payables_balances_resultsInput
    ) {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_payables_balances_results = /* GraphQL */ `
  mutation Update_t_payables_balances_results(
    $update_t_payables_balances_resultsInput: Update_t_payables_balances_resultsInput!
  ) {
    update_t_payables_balances_results(
      update_t_payables_balances_resultsInput: $update_t_payables_balances_resultsInput
    ) {
      month_year
      supplier_id
      last_month_payable_no_tax_balance_result
      last_month_payable_tax_balance_result
      monthly_payable
      monthly_payment
      monthly_check_payment
      monthly_transfer_payment
      monthly_bill_payment
      monthly_offset_payment
      monthly_other_payment
      monthly_tax_payment
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_payments_balances = /* GraphQL */ `
  mutation Delete_t_payments_balances($office_id: Int!) {
    delete_t_payments_balances(office_id: $office_id) {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_payments_balances = /* GraphQL */ `
  mutation Create_t_payments_balances(
    $create_t_payments_balancesInput: Create_t_payments_balancesInput!
  ) {
    create_t_payments_balances(
      create_t_payments_balancesInput: $create_t_payments_balancesInput
    ) {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_payments_balances = /* GraphQL */ `
  mutation Update_t_payments_balances(
    $update_t_payments_balancesInput: Update_t_payments_balancesInput!
  ) {
    update_t_payments_balances(
      update_t_payments_balancesInput: $update_t_payments_balancesInput
    ) {
      payment_month_year
      client_id
      office_id
      payment_start_date
      payment_end_date
      pre_no_tax_payment_balances
      pre_tax_payment_balances
      closing_date_payable
      closing_date_bulk_tax
      closing_date_bill_tax
      closing_date_tax
      closing_date_payments
      closing_date_tax_payments
      closing_date_forward_no_tax_payments
      closing_date_forward_tax_payments
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_products_prices_histories = /* GraphQL */ `
  mutation Delete_t_products_prices_histories($price_update_datetime: String!) {
    delete_t_products_prices_histories(
      price_update_datetime: $price_update_datetime
    ) {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_products_prices_histories = /* GraphQL */ `
  mutation Create_t_products_prices_histories(
    $create_t_products_prices_historiesInput: Create_t_products_prices_historiesInput!
  ) {
    create_t_products_prices_histories(
      create_t_products_prices_historiesInput: $create_t_products_prices_historiesInput
    ) {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_products_prices_histories = /* GraphQL */ `
  mutation Update_t_products_prices_histories(
    $update_t_products_prices_historiesInput: Update_t_products_prices_historiesInput!
  ) {
    update_t_products_prices_histories(
      update_t_products_prices_historiesInput: $update_t_products_prices_historiesInput
    ) {
      product_id
      office_id
      price_update_datetime
      purchase_price
      summary
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_products_deleted_log = /* GraphQL */ `
  mutation Delete_t_products_deleted_log($product_id: Int!) {
    delete_t_products_deleted_log(product_id: $product_id) {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_products_deleted_log = /* GraphQL */ `
  mutation Create_t_products_deleted_log(
    $create_t_products_deleted_logInput: Create_t_products_deleted_logInput!
  ) {
    create_t_products_deleted_log(
      create_t_products_deleted_logInput: $create_t_products_deleted_logInput
    ) {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_products_deleted_log = /* GraphQL */ `
  mutation Update_t_products_deleted_log(
    $update_t_products_deleted_logInput: Update_t_products_deleted_logInput!
  ) {
    update_t_products_deleted_log(
      update_t_products_deleted_logInput: $update_t_products_deleted_logInput
    ) {
      product_id
      is_deleted
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_products_results = /* GraphQL */ `
  mutation Delete_t_products_results($todo3: Int!) {
    delete_t_products_results(todo3: $todo3) {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_products_results = /* GraphQL */ `
  mutation Create_t_products_results(
    $create_t_products_resultsInput: Create_t_products_resultsInput!
  ) {
    create_t_products_results(
      create_t_products_resultsInput: $create_t_products_resultsInput
    ) {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_products_results = /* GraphQL */ `
  mutation Update_t_products_results(
    $update_t_products_resultsInput: Update_t_products_resultsInput!
  ) {
    update_t_products_results(
      update_t_products_resultsInput: $update_t_products_resultsInput
    ) {
      month_year
      office_class_id
      product_id
      product_class_id
      purchase_stock_count
      purchase_stock_return_count
      purchase_stock_total_count
      purchase_stock_amount
      purchase_stock_return_amount
      purchase_stock_total_amount
      sales_count
      sales_return_count
      sales_total_count
      sales_amount
      sales_return_amount
      sales_total_amount
      gross_profit
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_receivables_balances_results = /* GraphQL */ `
  mutation Delete_t_receivables_balances_results(
    $month_year: Int!
    $class_id: Int!
  ) {
    delete_t_receivables_balances_results(
      month_year: $month_year
      class_id: $class_id
    ) {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_receivables_balances_results = /* GraphQL */ `
  mutation Create_t_receivables_balances_results(
    $create_t_receivables_balances_resultsInput: Create_t_receivables_balances_resultsInput!
  ) {
    create_t_receivables_balances_results(
      create_t_receivables_balances_resultsInput: $create_t_receivables_balances_resultsInput
    ) {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_receivables_balances_results = /* GraphQL */ `
  mutation Update_t_receivables_balances_results(
    $update_t_receivables_balances_resultsInput: Update_t_receivables_balances_resultsInput!
  ) {
    update_t_receivables_balances_results(
      update_t_receivables_balances_resultsInput: $update_t_receivables_balances_resultsInput
    ) {
      month_year
      client_id
      staff_id
      client_id_first_digit
      last_month_receivable_no_tax_balance_result
      last_month_receivable_tax_balance_result
      monthly_sales
      monthly_deposit
      monthly_check_deposit
      monthly_transfer_deposit
      monthly_bill_deposit
      monthly_offset_deposit
      monthly_other_deposit
      monthly_tax_deposit
      monthly_bulk_tax
      monthly_billing_tax
      monthly_tax
      monthly_gross_profit
      billing_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_ships = /* GraphQL */ `
  mutation Delete_t_ships($order_receive_row: Int!) {
    delete_t_ships(order_receive_row: $order_receive_row) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_ships = /* GraphQL */ `
  mutation Create_t_ships($create_t_shipsInput: Create_t_shipsInput!) {
    create_t_ships(create_t_shipsInput: $create_t_shipsInput) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_ships = /* GraphQL */ `
  mutation Update_t_ships($update_t_shipsInput: Update_t_shipsInput!) {
    update_t_ships(update_t_shipsInput: $update_t_shipsInput) {
      order_receive_id
      order_receive_row
      shipping_confirm_date
      sales_record_date
      office_id
      shipping_type_class
      shipping_code
      shipping_order_number
      client_class
      client_id
      site_id
      product_id
      shipping_quantity
      order_receive_quantity
      reserve_quantity
      sales_issue_class
      sales_issue_date
      sales_number
      sales_number_row
      place_1
      place_2
      place_3
      place_4
      shipping_date
      details_unit_class
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_staffs_results = /* GraphQL */ `
  mutation Delete_t_staffs_results($staff_id: Int!) {
    delete_t_staffs_results(staff_id: $staff_id) {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_staffs_results = /* GraphQL */ `
  mutation Create_t_staffs_results(
    $create_t_staffs_resultsInput: Create_t_staffs_resultsInput!
  ) {
    create_t_staffs_results(
      create_t_staffs_resultsInput: $create_t_staffs_resultsInput
    ) {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_staffs_results = /* GraphQL */ `
  mutation Update_t_staffs_results(
    $update_t_staffs_resultsInput: Update_t_staffs_resultsInput!
  ) {
    update_t_staffs_results(
      update_t_staffs_resultsInput: $update_t_staffs_resultsInput
    ) {
      staff_id
      sales
      returned
      discount
      net_sales
      gross_profit
      next_month_sales
      next_month_returned
      next_month_discount
      next_month_net_sales
      next_month_gross_profit
      current_term_sales
      current_term_returned
      current_term_discount
      current_term_net_sales
      current_term_gross_profit
      net_sales_1
      net_sales_2
      net_sales_3
      net_sales_4
      net_sales_5
      net_sales_6
      net_sales_7
      net_sales_8
      net_sales_9
      net_sales_10
      net_sales_11
      net_sales_12
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_t_stocks = /* GraphQL */ `
  mutation Delete_t_stocks($order_row: Int!) {
    delete_t_stocks(order_row: $order_row) {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_t_stocks = /* GraphQL */ `
  mutation Create_t_stocks($create_t_stocksInput: Create_t_stocksInput!) {
    create_t_stocks(create_t_stocksInput: $create_t_stocksInput) {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_t_stocks = /* GraphQL */ `
  mutation Update_t_stocks($update_t_stocksInput: Update_t_stocksInput!) {
    update_t_stocks(update_t_stocksInput: $update_t_stocksInput) {
      order_id
      order_row
      stock_date
      office_id
      client_class
      client_id
      product_id
      stock_quantity
      stock_scheduled
      stock_scheduled_class
      order_number
      stock_confirm_class
      stock_confirm_date
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const create_w_clients_sales_file = /* GraphQL */ `
  mutation Create_w_clients_sales_file(
    $create_w_clients_sales_fileInput: Create_w_clients_sales_fileInput!
  ) {
    create_w_clients_sales_file(
      create_w_clients_sales_fileInput: $create_w_clients_sales_fileInput
    ) {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const update_w_clients_sales_file = /* GraphQL */ `
  mutation Update_w_clients_sales_file(
    $update_w_clients_sales_fileInput: Update_w_clients_sales_fileInput!
  ) {
    update_w_clients_sales_file(
      update_w_clients_sales_fileInput: $update_w_clients_sales_fileInput
    ) {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const delete_w_clients_sales_file = /* GraphQL */ `
  mutation Delete_w_clients_sales_file($client_id: Int!) {
    delete_w_clients_sales_file(client_id: $client_id) {
      client_id
      control_break_level
      is_overflow
      one_hundredth_rtdtdn
      rtknur_amount
      rtknar_amount
      created
      created_user
      updated
      updated_user
    }
  }
`;
export const execute_monthly_update = /* GraphQL */ `
  mutation Execute_monthly_update($input: Execute_monthly_updateInput!) {
    execute_monthly_update(input: $input) {
      execution_id
    }
  }
`;
export const execute_client_code_switch = /* GraphQL */ `
  mutation Execute_client_code_switch(
    $input: Execute_client_code_switchInput!
  ) {
    execute_client_code_switch(input: $input) {
      execution_id
    }
  }
`;
export const execute_product_code_switch = /* GraphQL */ `
  mutation Execute_product_code_switch(
    $input: Execute_product_code_switchInput!
  ) {
    execute_product_code_switch(input: $input) {
      execution_id
    }
  }
`;
export const executeTransactSql = /* GraphQL */ `
  mutation ExecuteTransactSql($SQLs: [String]!) {
    executeTransactSql(SQLs: $SQLs) {
      statusCode
      body
    }
  }
`;
export const executeSql = /* GraphQL */ `
  mutation ExecuteSql($executeSqlInputs: [ExecuteSqlInput]!) {
    executeSql(executeSqlInputs: $executeSqlInputs) {
      statusCode
      body
    }
  }
`;
export const createCsvOnS3BySelect = /* GraphQL */ `
  mutation CreateCsvOnS3BySelect(
    $createCsvOnS3BySelectInput: CreateCsvOnS3BySelectInput!
  ) {
    createCsvOnS3BySelect(
      createCsvOnS3BySelectInput: $createCsvOnS3BySelectInput
    ) {
      statusCode
      body
    }
  }
`;
export const createSelectDataOnS3 = /* GraphQL */ `
  mutation CreateSelectDataOnS3(
    $createSelectDataOnS3Input: CreateSelectDataOnS3Input!
  ) {
    createSelectDataOnS3(
      createSelectDataOnS3Input: $createSelectDataOnS3Input
    ) {
      statusCode
      body
    }
  }
`;
export const saveDocumentsStorageOperation = /* GraphQL */ `
  mutation SaveDocumentsStorageOperation(
    $saveDocumentsStorageOperationInput: SaveDocumentsStorageOperationInput!
  ) {
    saveDocumentsStorageOperation(
      saveDocumentsStorageOperationInput: $saveDocumentsStorageOperationInput
    ) {
      statusCode
      body
    }
  }
`;
export const createQueryStatus = /* GraphQL */ `
  mutation CreateQueryStatus(
    $input: CreateQueryStatusInput!
    $condition: ModelQueryStatusConditionInput
  ) {
    createQueryStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Keys
      Error
    }
  }
`;
export const updateQueryStatus = /* GraphQL */ `
  mutation UpdateQueryStatus(
    $input: UpdateQueryStatusInput!
    $condition: ModelQueryStatusConditionInput
  ) {
    updateQueryStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Keys
      Error
    }
  }
`;
export const deleteQueryStatus = /* GraphQL */ `
  mutation DeleteQueryStatus(
    $input: DeleteQueryStatusInput!
    $condition: ModelQueryStatusConditionInput
  ) {
    deleteQueryStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Keys
      Error
    }
  }
`;
export const createTransactSqlStatus = /* GraphQL */ `
  mutation CreateTransactSqlStatus(
    $input: CreateTransactSqlStatusInput!
    $condition: ModelTransactSqlStatusConditionInput
  ) {
    createTransactSqlStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Key
      Error
      createdAt
      updatedAt
    }
  }
`;
export const updateTransactSqlStatus = /* GraphQL */ `
  mutation UpdateTransactSqlStatus(
    $input: UpdateTransactSqlStatusInput!
    $condition: ModelTransactSqlStatusConditionInput
  ) {
    updateTransactSqlStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Key
      Error
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransactSqlStatus = /* GraphQL */ `
  mutation DeleteTransactSqlStatus(
    $input: DeleteTransactSqlStatusInput!
    $condition: ModelTransactSqlStatusConditionInput
  ) {
    deleteTransactSqlStatus(input: $input, condition: $condition) {
      ProcessID
      StatusClass
      TypeClass
      S3Key
      Error
      createdAt
      updatedAt
    }
  }
`;
