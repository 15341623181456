<template>
  <div class="modal-mask" v-show="$store.getters.loading">
    <div class="loading">
      <vue-loading type="spin" color="#0CA287" :size="{ width: '100px', height: '100px' }"></vue-loading>
    </div>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template';
export default {
  name: 'Loading',
  components: {
    VueLoading,
  },
};
</script>

<style scoped>
.modal-mask {
  /* �ő�l */
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
