/**
 * 共通JS
 */
import $ from 'jquery';
import moment from 'moment';
import 'datatables';
import store from '@/store';
import { API, graphqlOperation, Auth, Cache } from 'aws-amplify';
import { list_t_orders_receives, list_t_orders, list_m_control, list_m_staffs, list_m_offices, list_m_products_units_conversions, list_m_products_compositions, list_m_calendar, list_m_routes, list_m_products, list_m_clients_parent_child } from '@/graphql/queries';
import { list_t_billings_unsettled } from '@/graphql/queries';
import { executeTransactSql, executeSql, create_t_logs, saveDocumentsStorageOperation } from '@/graphql/mutations';
import { AUTH_EXPIRATION_DAYS, MAX_LENGTH_LOG_MESSAGE } from '@/assets/js/const';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { Storage } from 'aws-amplify';
import { PDFDocument } from 'pdf-lib';
import html2pdf from 'html2pdf.js';

// Ajaxのレスポンスハンドリング
// function ajaxResponseHandling(xhr) {
//       var responseUrl = xhr.getResponseHeader('Response-Url') || "";
//       console.log('●●●レスポンス：'+ responseUrl);
//       // リダイレクト結果がログイン画面の場合、Ajax反映領域に埋め込まれないよう再リダイレクト
//       if (responseUrl.indexOf('/login') > -1) {
//           console.log('●●●要ログイン');
//           document.location.href = "login";
//       // リダイレクト結果がシステムメンテナンス画面の場合、Ajax反映領域に埋め込まれないよう再リダイレクト
//       } else if (responseUrl.indexOf('/system_maintenance') > -1) {
//           console.log('●●●システムメンテナンス中');
//           // システムメンテナンス画面への直接遷移は禁止しているため、ログイン画面でのメンテチェックを行う
//           document.location.href = "login";
//       }
//       // 上記以外は何も行わない
//   }

// ポップアップイメージ作成
export function addPopUpImage(id, type) {
  if (type == 'W') {
    $(id).removeClass().addClass('fas fa-exclamation-triangle fa-3x fa-yellow');
  }
  if (type == 'E') {
    $(id).removeClass().addClass('fas fa-times-circle  fa-3x fa-red');
  }
  if (type == 'Q') {
    $(id).removeClass().addClass('fas fa-question-circle  fa-3x fa-grey');
  }
  if (type == 'I') {
    $(id).removeClass().addClass('fas fa-info-circle  fa-3x fa-blue');
  }
}

export function showModal(id) {
  $(id).modal({
    keyboard: false,
    backdrop: 'static',
  });
}

// navbar改行表示時の高さ調整
$(window).on('load resize', function () {
  // navbarの高さを取得する
  var height = $('.navbar').height();
  height = height + 0;
  // bodyのpaddingにnavbar分の高さを設定する
  $('body').css('padding-top', height);
});

export function init() {
  // console.log("初期化処理開始");
  // navbarの高さを取得する
  var height = $('.navbar').height();
  height = height + 0;
  // bodyのpaddingにnavbar分の高さを設定する
  $('body').css('padding-top', height);
  navDatatableFix();
  scrollTo(0,0);
  // console.log("初期化処理終了");
}

export function initCalendar() {
  // console.log("カレンダー初期化処理開始");
  // navbarの高さを取得する
  var height = $('.navbar').height();
  height = height + 0;
  // bodyのpaddingにnavbar分の高さを設定する
  $('body').css('padding-top', height);
  navDatatableFix();

  // カレンダー作成
  $('#rangeStart').datetimepicker({
    dayViewHeaderFormat: 'YYYY年 MMMM',
    locale: 'ja',
    showTodayButton: true,
    showClose: true,
    tooltips: {
      today: '現在日時',
      close: '閉じる',
      selectMonth: '月を選択',
      selectYear: '年を選択',
      selectTime: '時間を選択',
      selectDate: '日付を選択',
      selectDecade: '期間を選択',
      pickHour: '時間を取得',
      incrementHour: '時間を増加',
      decrementHour: '時間を減少',
      pickMinute: '分を取得',
      incrementMinute: '分を増加',
      decrementMinute: '分を減少',
    },
  });
  $('#rangeEnd').datetimepicker({
    dayViewHeaderFormat: 'YYYY年 MMMM',
    locale: 'ja',
    showTodayButton: true,
    showClose: true,
    tooltips: {
      today: '現在日時',
      close: '閉じる',
      selectMonth: '月を選択',
      selectYear: '年を選択',
      selectTime: '時間を選択',
      selectDate: '日付を選択',
      selectDecade: '期間を選択',
      pickHour: '時間を取得',
      incrementHour: '時間を増加',
      decrementHour: '時間を減少',
      pickMinute: '分を取得',
      incrementMinute: '分を増加',
      decrementMinute: '分を減少',
    },
  });

  if ($('#rangeStart').length) {
    // 期間の入力値を当日に更新
    var today = new Date();
    let todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    $('#rangeStart').data('DateTimePicker').defaultDate(todayStart);
    let todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59);
    $('#rangeEnd').data('DateTimePicker').defaultDate(todayEnd);
  }

  // console.log("カレンダー初期化処理終了");
}

export function drawDatatable(tableData, descNum) {
  // console.log("Datatable描画処理開始");

  // 検索結果表
  $('#dataList').DataTable({
    autoWidth: false,
    data: tableData.data,
    columnDefs: tableData.columnDefs,
    order: [[descNum, 'desc']],
    aLengthMenu: [
      [5, 10, 25, 50, 100, -1],
      [5, 10, 25, 50, 100, 'All'],
    ],
    iDisplayLength: 10,
    language: {
      emptyTable: 'データが登録されていません。',
      info: '_TOTAL_ 件中 _START_ 件から _END_ 件までを表示',
      infoEmpty: '',
      infoFiltered: '(_MAX_ 件からの絞り込み表示)',
      infoPostFix: '',
      thousands: ',',
      lengthMenu: '1ページあたりの表示件数： _MENU_',
      loadingRecords: 'ロード中',
      processing: '処理中...',
      search: '',
      searchPlaceholder: '\uf002 検索ワードを入力',
      zeroRecords: '該当するデータが見つかりませんでした。',
      paginate: {
        first: '先頭',
        previous: '前へ',
        next: '次へ',
        last: '末尾',
      },
    },
    fnDrawCallback: function () {
      // ページネーション後にtooltipを再適用
      // $('[data-toggle="tooltip"]').tooltip();
    },
  });

  // $('[data-toggle="tooltip"]').tooltip();

  // console.log("Datatable描画処理終了");
}

export function redrawDatatable(data) {
  // console.log("Datatable再描画処理開始");

  let datatable = $('#dataList').DataTable();
  datatable.clear().draw();
  datatable.rows.add(data); // Add new data
  datatable.columns.adjust().draw(); // Redraw the DataTable

  // $('[data-toggle="tooltip"]').tooltip();

  // console.log("Datatable再描画処理終了");
}

// bootstrapのナビゲーションドロップダウンのイベントとdatatableのイベントが競合することの回避
export function navDatatableFix() {
  $('nav .dropdown').each(function (index, el) {
    $(el).on('click', function () {
      $(el).find('.dropdown-toggle').dropdown('toggle');
    });
  });
}

// 受注番号取得
export async function ordersReceivesMaxId(){
  console.log('受注番号取得');
  try {
    let where_clause = 'AND order_receive_id = (SELECT MAX(order_receive_id) FROM t_orders_receives) Group By order_receive_id';
    let condition = {where_clause: where_clause};
    console.log(condition);
    let result = await API.graphql(graphqlOperation(list_t_orders_receives,condition));
    let resultData = result.data.list_t_orders_receives;
    console.log(resultData);
    return resultData[0].order_receive_id + 1;
  } catch (error) {
    console.log(error);
  }
}

// 受注番号取得
export async function ordersMaxId(){
  console.log('発注番号取得');
  try {
    let where_clause = 'AND order_id = (SELECT MAX(order_id) FROM t_orders) Group By order_id';
    let condition = {where_clause: where_clause};
    console.log(condition);
    let result = await API.graphql(graphqlOperation(list_t_orders,condition));
    let resultData = result.data.list_t_orders;
    console.log(resultData);
    return resultData[0].order_id + 1;
  } catch (error) {
    console.log(error);
  }
}

// 小数点表示用メソッド
export function rounding(value, num) {
  let temp = 1;
  if (num > 0) {
    for (let i = 0; i < num; i++) {
      temp = temp * 10;
    }
    return Math.round(value / temp) * temp;
  } else {
    num = num * -1;
    for (let i = 0; i < num; i++) {
      temp = temp * 10;
    }
    return Math.round(value * temp) / temp;
  }
}
/* 各種チェック */
// 必須チェック
export function requireCheck(target){
  console.log(target);
  if(!(target === '' || target === null)){
    return true;
  }else{
    return false;
  }
}
// 文字数チェック
export function lengthCheck(target,count){
  if(target.length <= count){
    return true;
  }else{
    return false;
  }
}
// 区分値チェック
export function categoryValueCheck(list, target){
  console.log(list);
  console.log(target);
  let listKey = Object.keys(list[0]);
  for(let i = 0; i < list.length; i++){
    if((list[i][listKey[0]] === target)){
      return true;
    }
  }
  return false;
}
// 数値チェック
export function isNumber(target){
  let regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
  return regexp.test(target);
}

// 日付整合性チェック
export function dateConsistency(startDate, endDate){
  if(moment(startDate).isSameOrBefore(endDate)){
    return true;
  }else{
    return false;
  }
}

/**
 * 操作ログの登録
 * @param {*} operation 
 * @param {*} param 
 */
export async function addOperationLogs(logLevel, component, func, param, e=null) {
  // エラーデータ
  if( e != null ) {
    Object.assign( param, { exception: e } );
  }

  // ユーザー
  let user = '';
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    user = cognitoUser.username;
  } catch (error) {
    // 何もしない
  }

  const input = {
    user_name: user,
    log_level: logLevel,
    message: JSON.stringify(param).substring(0,MAX_LENGTH_LOG_MESSAGE),
    component: component,
    function: func,
    user_agent: navigator.userAgent,
    created_user: user,
    updated_user: user,
  }
  try {
    // console.log({input});
    const result = await API.graphql({
      query: create_t_logs,
      variables: { create_t_logsInput: input },
      authMode: 'AWS_IAM'
    });
    console.log(result);
  } catch(error) {
    console.log(error);
    // 何もしない。
  }
}

// 受注番号取得
export async function billingsUnsettledMaxId(){
  console.log('伝票番号取得');
  try {
    let where_clause = 'AND billing_no = (SELECT MAX(billing_no) FROM t_billings_unsettled) Group By billing_no';
    let condition = {where_clause: where_clause};
    console.log(condition);
    let result = await API.graphql(graphqlOperation(list_t_billings_unsettled,condition));
    let resultData = result.data.list_t_billings_unsettled;
    console.log(resultData);
    return resultData[0].billing_no + 1;
  } catch (error) {
    console.log(error);
  } 
}

/**
 * 伝票種別と配送種別の組み合わせて、伝票発行時の区分に変換
 * 伝票種別が 0,12,15
 * https://psol.backlog.jp/alias/wiki/902248#loom-header-9 配送種別と伝票種別毎の伝票種別（ラベル）を参考してください
 * @param {int} shippingTypeClass 配送種別
 * @param {int} orderReceiveBillClass 伝票種別
 * @returns 伝票発行時の区分
 */
export function orderReceiveBillClassConversion(shippingTypeClass, orderReceiveBillClass){
  let billClass = 12
  if (orderReceiveBillClass == 12) {
    if (shippingTypeClass == 4) {
      return billClass = null
    } else {
      return billClass
    }
  }
  if (orderReceiveBillClass == 0) {
    if (shippingTypeClass == 4) {
      return billClass = 14 
    } else {
      return billClass = 11
    }
  } 
  if (orderReceiveBillClass == 15) {
    if (shippingTypeClass == 4) {
      return billClass = null
    } else {
      return billClass = 11
    }
  } 
  
}

/**
 * 印刷レイアウトを設定します。
 * @param {String} size ページサイズ
 */
function setPaperCommon(size) {

  // bodyに帳票用のスタイルを設定
  document.body.className = [
    'chouhyou-body', // index.htmlのstyleのセレクタ
    size // paper.cssのセレクタ
  ].join(' ');

  // @pageの設定
  // （vueファイルで@pageがある外部CSSを読み込んだり、直接@pageを記載すると単一スコープ化されず重複してしまう問題の回避策）
  // margin:paper.cssでコメントアウトした部分
  // size  :ページサイズ
  document.head.insertAdjacentHTML('beforeend', '<style>@page{margin:0;size:' + size + ';}@media print {header {display: none;}}</style>');
}

/** 印刷レイアウトをA4縦に設定します。 */
export function setPaperA4(){
  setPaperCommon('A4');
}

/** 印刷レイアウトをA4横に設定します。 */
export function setPaperA4Landscape(){
  setPaperCommon('A4 landscape');
}

export function windowPrint(document, window, pageInstance, isLandscape=false){
  console.log('windowPrint');
  // 最初にデフォルト値を保持
  let defBodyClassName = document.body.className;
  let defDocTitle = document.title;
  // 印刷ファイルのデフォルト名
  document.title = pageInstance.title;
  if (isLandscape) {
    // A4縦の印刷サイズに合わせる
    setPaperA4()
  } else {
    // A4横の印刷サイズに合わせる
    setPaperA4Landscape();
  }
  // 印刷ダイアログ表示
  window.print();
  // 印刷ダイアログが閉じたらbodyタグを最初の状態に戻す
  document.body.className = defBodyClassName;
  // タイトルを元に戻す
  document.title = defDocTitle;
  // print終了
  pageInstance.printStatus = false;
}

/**
 * 帳票のbodyタグのスタイル設定
 */
export function setChouhyouBodyStyle() {
  document.body.style['margin'] = '0';
  document.body.style['padding'] = '0';
  document.body.style['text-align'] = 'center';
}

/**
 * シングルクオーテーションのエスケープ処理
 * @param {*} text 
 */
export async function escapeQuote(text) {
  try {
    return text.replace(/'/g, '\'\'').replace(/\\/g, '\\\\');
  } catch(error) {
    // TODO 異常系操作ログの登録
    return null;
  }
}

/**
 * シングルクォート ダブルクォーテーション変換
 * 特殊文字 _ % 変換
 * @param {*} text 
 */
export function convertSqlLikeSpecialChar(text) {
  try {
    // eslint-disable-next-line quotes
    text = text.replace(/'/g, "''").replace(/"/g, '\\\\\\"')
    text = text.replace(/_/g, '\\\\_').replace(/%/g, '\\\\%')
    return text
  } catch(error) {
    // TODO 異常系操作ログの登録
    return null;
  }
}

/**
 * 日付オブジェクトを「YYYY/MM/DD」の形式で返却
 * @param {*} dateObject momentで認識可能な日付オブジェクト（文字列型、日付型等）
 * @param {String} format フォーマット形式、未指定の場合は「YYYY/MM/DD」となる。
 * @returns 指定された引数を用いた日付形式の文字列を返却
 */
export function formatDate(dateObject, format = 'YYYY/MM/DD') {
  // null値や空白の場合は空白を返却
  if(dateObject == null ||
    dateObject.toString() == '') {
    return '';
  }

  try {
    return moment(dateObject).format(format);
  } catch(error) {
    // 日付型に変換不可の場合はそのままの値を返却
    return dateObject;
  }
}

/**
 * 日付オブジェクトを操作して「YYYY/MM/DD」の形式で返却
 * @param {*} dateObject momentで認識可能な日付オブジェクト（文字列型、日付型等）
 * @param {Int} addYears 加算年：指定された分の年を加算する。0やマイナスも可。
 * @param {Int} addMonths 加算月：指定された分の月を加算する。0やマイナスも可。
 * @param {Int} addDays 加算日：指定された分の日を加算する。0やマイナスも可。
 * @param {boolean} monthEndFlg 月末フラグ。加算後、日付を同月内の月末にして返却。
 * @param {String} format フォーマット形式、未指定の場合は「YYYY/MM/DD」となる。
 * @returns 指定された引数を用いた日付形式の文字列を返却
 */
export function formatDateCalc(dateObject, addYears, addMonths, addDays, monthEndFlg = false, format = 'YYYY/MM/DD') {
  // null値や空白の場合は空白を返却
  if(dateObject == null ||
    dateObject.toString() == '') {
    return '';
  }

  try {
    if (monthEndFlg == true) {
      return moment(dateObject).add(addYears, 'y').add(addMonths, 'M').add(addDays, 'd').endOf('month').format(format);
    } else {
      return moment(dateObject).add(addYears, 'y').add(addMonths, 'M').add(addDays, 'd').format(format);
    }
  } catch(error) {
    // 日付型に変換不可の場合はそのままの値を返却
    return dateObject;
  }
}

/**
 * 現在日時の文字列をフォーマットを指定して返却
 * @param {String} format フォーマット形式、未指定の場合は「YYYY/MM/DD」となる。
 * @returns 指定された引数を用いた日付形式の現在日時文字列を返却
 */
export function formatCurDate(format = 'YYYY/MM/DD') {
  // new Date()を直接momentに入れると警告が出るため、文字列に直した上でmomentに入れる
  let today = new Date();
  let curDateTime = '';
  curDateTime += today.getFullYear();
  curDateTime += '-';
  curDateTime += ('00' + (today.getMonth() + 1)).slice(-2);
  curDateTime += '-';
  curDateTime += ('00' + today.getDate()).slice(-2);
  curDateTime += ' ';
  curDateTime += ('00' + today.getHours()).slice(-2);
  curDateTime += ':';
  curDateTime += ('00' + today.getMinutes()).slice(-2);
  curDateTime += ':';
  curDateTime += ('00' + today.getSeconds()).slice(-2);

  return moment(curDateTime).format(format);
}

/**
 * パラメータを元にINSERT文を作成し、返却
 * @param {[{col,val,type}]} colList 下記ののキーを持ったリストを指定
 *        col：カラム名（物理）、val：カラムに対する登録値、type：データタイプ（'VARCHAR'、'NUMBER'、'DATE'、'DATETIME'を指定）
 * @param {String} part INSERT文のどの部分を返却するか、'col'、'full'、'val'のいずれかを設定
 *        'col'：カラム部分、'full'：全体、'val'：値部分
 * @param {String} tableName テーブル名（fullの場合のみ使用）
 * @returns 指定された引数を用いたINSERT文を返却
 */
export function CreateInsertSql(colList, part, tableName) {
  // 変数宣言
  let sqlCol = '';
  let sqlVal = '';
  /* INSERT文のカラム部分を作成 */
  // 値部分の返却の場合は不要
  if (part.toLowerCase() != 'val') {
    for (let i = 0; i < colList.length; i++) {
      if (i != 0) {
        sqlCol += ',';
      }
      sqlCol += colList[i].col;
    }
  }
  /* INSERT文の登録値部分を作成 */
  // カラム名部分の返却の場合は不要
  if (part.toLowerCase() != 'col') {
    for (let i = 0; i < colList.length; i++) {
      if (i != 0) {
        sqlVal += ',';
      }
      switch (colList[i].type.toString().toUpperCase()) {
      // 文字列型
      case 'VARCHAR':
        if (colList[i].val == null) {
          // null値の場合はNULLを設定
          sqlVal += 'NULL';
        } else {
          sqlVal += '\'' + colList[i].val + '\'';
        }
        break;
      // 日付型
      case 'DATE':
        if (colList[i].val == '' || colList[i].val == null) {
          // 空欄、または、null値の場合はNULLを設定
          sqlVal += 'NULL';
        } else if (colList[i].val == 'CURDATE()') {
          sqlVal += colList[i].val;
        } else if (moment(colList[i].val).isValid() == true) {
          // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
          sqlVal += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD') + '\',\'%Y/%m/%d\')';
        } else {
          // 日付として正しくない場合はそのまま追加（CURDATE()等を想定）
          sqlVal += colList[i].val;
        }
        break;
      // 日時型
      case 'DATETIME':
        if (colList[i].val == '' || colList[i].val == null) {
          // 空欄、または、null値の場合はNULLを設定
          sqlVal += 'NULL';
        } else if (colList[i].val == 'CURRENT_TIMESTAMP()' || colList[i].val == 'NOW()') {
          sqlVal += colList[i].val;
        } else if (moment(colList[i].val).isValid() == true) {
          // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
          sqlVal += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD HH:mm:ss') + '\',\'%Y/%m/%d %H:%i:%s\')';
        } else {
          // 日付として正しくない場合はそのまま追加（NOW()等を想定）
          sqlVal += colList[i].val;
        }
        break;
      // 指定値がない場合は数値型として処理
      default:
        if (colList[i].val == '' || colList[i].val == null) {
          // 空欄、または、null値の場合はNULLを設定
          sqlVal += 'NULL';
        } else {
          sqlVal += colList[i].val;
        }
        break;
      }
    }
  }
  // 指定したデータ型に適したSQL文を返却
  if (part.toLowerCase() == 'col') {
    // カラム名をカンマで連結した値（バルクINSERT等で使用）
    return sqlCol;
  } else if (part.toLowerCase() == 'val') {
    // 登録値をカンマで連結した値（バルクINSERT等で使用）
    return sqlVal;
  } else {
    // INSERT文を返却
    return 'INSERT INTO ' + tableName + ' (' + sqlCol + ') VALUES (' + sqlVal + ')';
  }
}

/**
 * パラメータを元にINSERT文の後ろに付与するMERGE文を作成し、返却
 * @param {[{col,val,type}]} colList 下記のキーを持ったリストを指定
 *        col：カラム名（物理）、val：カラムに対する登録値、type：データタイプ（'VARCHAR'、'NUMBER'、'DATE'、'DATETIME'を指定）
 * @param {String} tableName テーブル名
 * @param {String} refTableName 参照テーブル名
 *        更新テーブルの更新値やWHEREに使用する値を取ってくるテーブル（副問い合わせも可）
 *        デフォルト値は空欄（空欄の場合は参照テーブルを指定しない）
 * @returns 指定された引数を用いたUPDATE文を返却
 */
export function CreateUpdateSql(colList, tableName, refTableName = '') {
  // 変数宣言
  let sqlUpdate = 'UPDATE ' + tableName;
  if (refTableName != '') {
    sqlUpdate += ',' + refTableName;
  }
  sqlUpdate += ' SET ';

  /* 値の設定部分を作成 */
  for (let i = 0; i < colList.length; i++) {
    if (i != 0) {
      sqlUpdate += ',';
    }
    // カラム
    sqlUpdate += colList[i].col + ' = '
    switch (colList[i].type.toString().toUpperCase()) {
    // 文字列型
    case 'VARCHAR':
      if (colList[i].val == null) {
        // null値の場合はNULLを設定
        sqlUpdate += 'NULL';
      } else {
        sqlUpdate += '\'' + colList[i].val + '\'';
      }
      break;
    // 日付型
    case 'DATE':
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlUpdate += 'NULL';
      } else if (colList[i].val == 'CURDATE()') {
        sqlUpdate += colList[i].val;
      } else if (moment(colList[i].val).isValid() == true) {
        // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
        sqlUpdate += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD') + '\',\'%Y/%m/%d\')';
      } else {
        // 日付として正しくない場合はそのまま追加（NULL、CURDATE()等を想定）
        sqlUpdate += colList[i].val;
      }
      break;
    // 日時型
    case 'DATETIME':
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlUpdate += 'NULL';
      } else if (colList[i].val == 'CURRENT_TIMESTAMP()' || colList[i].val == 'NOW()') {
        sqlUpdate += colList[i].val;
      } else if (moment(colList[i].val).isValid() == true) {
        // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
        sqlUpdate += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD HH:mm:ss') + '\',\'%Y/%m/%d %H:%i:%s\')';
      } else {
        // 日付として正しくない場合はそのまま追加（NULL、NOW()等を想定）
        sqlUpdate += colList[i].val;
      }
      break;
    // 指定値がない場合は数値型として処理
    default:
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlUpdate += 'NULL';
      } else {
        sqlUpdate += colList[i].val;
      }
      break;
    }
  }

  return sqlUpdate;
}

/**
 * パラメータを元にINSERT文の後ろに付与するMERGE文を作成し、返却
 * @param {[{col,val,type}]} colList 下記のキーを持ったリストを指定
 *        col：カラム名（物理）、val：カラムに対する登録値、type：データタイプ（'VARCHAR'、'NUMBER'、'DATE'、'DATETIME'、'COLUMN'を指定）
 * @returns 指定された引数を用いたINSERT文の後ろにつけてMERGE文とするSQL文を返却
 */
export function CreateMergeSql(colList) {
  // 変数宣言
  let sqlMerge = ' ON DUPLICATE KEY UPDATE ';

  /* 値の設定部分を作成 */
  for (let i = 0; i < colList.length; i++) {
    if (i != 0) {
      sqlMerge += ',';
    }
    // カラム
    sqlMerge += colList[i].col + ' = '
    switch (colList[i].type.toString().toUpperCase()) {
    // INSERT時にカラムに入れるはずの値を指定（値にはカラム名を入れる）
    case 'COLUMN':
      sqlMerge += 'VALUES(' + colList[i].val + ')';
      break;
    // 文字列型
    case 'VARCHAR':
      if (colList[i].val == null) {
        // null値の場合はNULLを設定
        sqlMerge += 'NULL';
      } else {
        sqlMerge += '\'' + colList[i].val + '\'';
      }
      break;
    // 日付型
    case 'DATE':
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlMerge += 'NULL';
      } else if (colList[i].val == 'CURDATE()') {
        sqlMerge += colList[i].val;
      } else if (moment(colList[i].val).isValid() == true) {
        // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
        sqlMerge += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD') + '\',\'%Y/%m/%d\')';
      } else {
        // 日付として正しくない場合はそのまま追加（NULL等を想定）
        sqlMerge += colList[i].val;
      }
      break;
    // 日時型
    case 'DATETIME':
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlMerge += 'NULL';
      } else if (colList[i].val == 'CURRENT_TIMESTAMP()' || colList[i].val == 'NOW()') {
        sqlMerge += colList[i].val;
      } else if (moment(colList[i].val).isValid() == true) {
        // 日付として正しい場合はYYYY/MM/DDのフォーマットで追加
        sqlMerge += 'STR_TO_DATE(\'' + moment(colList[i].val).format('YYYY/MM/DD HH:mm:ss') + '\',\'%Y/%m/%d %H:%i:%s\')';
      } else {
        // 日付として正しくない場合はそのまま追加（NULL等を想定）
        sqlMerge += colList[i].val;
      }
      break;
    // 指定値がない場合は数値型として処理
    default:
      if (colList[i].val == '' || colList[i].val == null) {
        // 空欄、または、null値の場合はNULLを設定
        sqlMerge += 'NULL';
      } else {
        sqlMerge += colList[i].val;
      }
      break;
    }
  }

  return sqlMerge;
}

/**
 * CreateInsertSql、CreateMergeSqlのcolListに入るレコードを作成
 * @param {String} col カラム名（物理）
 * @param {*} val 登録値
 * @param {String} データタイプ（'VARCHAR'、'NUMBER'、'DATE'、'DATETIME'を指定）
 * @returns 引数をcol,val,typeに入れた値を保持する配列を返却
 */
export function CreateColRow(col,val,type) {
  return { col: col, val: (val == null ? null : val.toString()), type: type };
}

/**
 * パラメータのSQLを同一トランザクションで実行
 * （true：成功、false：失敗）
 * ※単純にトランザクションSQLを実行する場合に使用
 * （「LAST_INSERT_ID()」を使用する等の複雑な処理をする場合は別に作成すること）
 * @param {[String]} sqlList SQL文字列の配列
 * @param {String} MODULE_NAME 呼出元のファイル名（エラーログ出力時に設定。指定しない場合は「common」）
 * @param {String} functionName 呼出元の関数名（エラーログ出力時に設定。指定しない場合は「executeTransactSqlList」）
 * @returns SQLが成功した場合はtrue、失敗した場合はfalse
 */
export async function executeTransactSqlList(sqlList, MODULE_NAME = 'common', functionName = 'executeTransactSqlList') {
  let retResult = false;
  // 引数のプロパティは「SQLs」
  let condition = { SQLs: sqlList };
  console.info({condition});
  try {
    // executeTransactSqlで実行
    let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
    // レスポンスデータを取得できた際の処理
    if(result) {
      // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
      const responseBody = JSON.parse(result.data.executeTransactSql.body);

      // SQL実行でエラーが発生した場合の処理
      if(result.data.executeTransactSql.statusCode > 200) {
        console.info({responseBody});
        // レスポンスメッセージ
        let message = responseBody.message;
        console.error({message});
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({errorMessage});
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList,
          result: result
        });
        return retResult;
      } else {
        // SQLが正常に終了した際の処理
        if (responseBody.data) {
          // SELECT文の結果はresponseBody.dataとして返却される
          // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
          for (const rows of responseBody.data) {
            console.dir(rows, {depth: null});
          }
        }

        retResult = true;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList,
          result: result
        });
      }
      console.info(JSON.parse(result.data.executeTransactSql.body));
    } else {
      // レスポンスデータを取得できなかった際の処理
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: sqlList
      });
    }
  } catch (error) {
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      graphqlOperation: 'executeTransactSql',
      SQLs: sqlList
    }, error);
    console.error(error);
  }

  return retResult;
}

/**
 * パラメータのSQLを同一トランザクションで実行
 * ※単純にトランザクションSQLを実行する場合に使用（executeTransactSqlListとほぼ同じ。但し、「FOR UPDATE」を使用したい場合はこちらを使用する）
 * （「LAST_INSERT_ID()」を使用する等の複雑な処理をする場合は別に作成すること）
 * @param {[{sql: String, forUpdateFlg: Int}]} sqlList（sql:SQL文字列の配列、forUpdateFlg:0or1、1の場合、最後に「FOR UPDATE」追加（SELECT文のロック用））
 * @returns 返却値無し（SQLが成功した場合は何もなし、失敗した場合はエラーをthrow。（呼出元でcatchすること））
 */
export async function executeSqlList(sqlList) {
  // 引数のプロパティは「executeSqlInputs」
  let condition = { executeSqlInputs: sqlList };
  //console.info({condition});
  try {
    // executeSqlで実行
    let result = await API.graphql(graphqlOperation(executeSql, condition));
    // レスポンスデータを取得できた際の処理
    if(result) {
      // result.data.executeSql.bodyにJSON形式で結果が返ってくる
      const responseBody = JSON.parse(result.data.executeSql.body);

      // SQL実行でエラーが発生した場合の処理
      if(result.data.executeSql.statusCode > 200) {
        console.info({responseBody});
        // レスポンスメッセージ
        let message = responseBody.message;
        console.error({message});
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({errorMessage});
        let param = {
          graphqlOperation: 'executeSql',
          executeSqlInputs: sqlList,
          result: result
        }
        throw param;
      } else {
        // SQLが正常に終了した際の処理
        /*
        if (responseBody.data) {
          // SELECT文の結果はresponseBody.dataとして返却される
          // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
          for (const rows of responseBody.data) {
            console.dir(rows, {depth: null});
          }
        }
        */
      }
      if (result.errors) {
        let param = {
          graphqlOperation: 'executeSql',
          executeSqlInputs: sqlList,
          result: result
        }
        throw param;
      }
      //console.info(JSON.parse(result.data.executeSql.body));
    } else {
      // レスポンスデータを取得できなかった際の処理
      let param = {
        graphqlOperation: 'executeSql',
        executeSqlInputs: sqlList
      }
      throw param;
    }
  } catch (error) {
    console.error(error);
    let param = {
      graphqlOperation: 'executeSql',
      executeSqlInputs: sqlList,
      error: error
    }
    throw param;
  }
}

/**
 * パラメータのSELECT文を実行してその結果を返却
 * @param {String} selectSql SELECT文
 * @returns SELECT文の返却値の配列、エラーが発生した場合はエラーをthrow。（呼出元でcatchすること）
 */
export async function executeSelectSql(selectSql) {
  let retResult = null;
  // 引数のプロパティは「SQLs」
  let condition = { SQLs: [selectSql] };
  //console.info({condition});
  try {
    // executeTransactSqlで実行
    let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
    // レスポンスデータを取得できた際の処理
    if(result) {
      // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
      const responseBody = JSON.parse(result.data.executeTransactSql.body);

      // SQL実行でエラーが発生した場合の処理
      if(result.data.executeTransactSql.statusCode > 200) {
        console.info({responseBody});
        // レスポンスメッセージ
        let message = responseBody.message;
        console.error({message});
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({errorMessage});
        let param = {
          graphqlOperation: 'executeTransactSql',
          SQLs: [selectSql],
          result: result
        }
        throw param;
      } else {
        // SQLが正常に終了した際の処理
        if (responseBody.data && responseBody.data.length) {
          // SELECT文の結果を格納
          retResult = responseBody.data[0];
        }
      }
      if (result.errors) {
        let param = {
          graphqlOperation: 'executeTransactSql',
          SQLs: [selectSql],
          result: result
        }
        throw param;
      }
      //console.info(JSON.parse(result.data.executeTransactSql.body));
    } else {
      // レスポンスデータを取得できなかった際の処理
      let param = {
        graphqlOperation: 'executeTransactSql',
        SQLs: [selectSql],
      }
      throw param;
    }
  } catch (error) {
    console.error(error);
    let param = {
      graphqlOperation: 'executeTransactSql',
      SQLs: [selectSql],
      error: error
    }
    throw param;
  }

  return retResult;
}

/**
 * 改行（\r\n,\n）を含む文字列を行毎の文字列配列に変換して返却
 * @param {[String]} value 対象の文字列
 * @param {[Int]} maxLengthRow 行毎の最大文字数（超過した場合は次の行に移動）
 * @returns 改行箇所で配列分けされた文字配列を返却。（\r\n,\n、または、1行の最大文字数を超過した箇所を改行）
 */
export function splitMultiRowString(value, maxLengthRow) {
  // nullの場合は空文字として扱う
  if (value == null) {
    value = '';
  }
  // \r\nを\nに変換した上で\n毎に分割
  let aryValue = value.replace(/\r\n/g, '\n').split('\n');
  // 各行を確認し、最大文字列長を超過している行がないか確認するループ
  for (let i = 0; i < aryValue.length; i++) {
    // 参照中の行が最大文字数を超過している場合
    if (aryValue[i].length > maxLengthRow) {
      // 超過部分を次の行に移動
      aryValue.splice(i + 1, 0, aryValue[i].substr(maxLengthRow));
      // 次の行に移動した分は現行から除外
      aryValue[i] = aryValue[i].substr(0, maxLengthRow);
    }
  }
  //console.log(aryValue);
  return aryValue;
}

/**
 * 配列に対してキーを指定し、キーと同じ要素内の値を返却する。
 * @param {Array} objList 値を取り出す対象の配列
 * @param {*} key 指定キー（配列のキーの形式と同じ形式を指定）
 * @param {String} listKeyName キーの要素名（指定しない場合は「id」）
 * @param {String} listValName 取り出したい値の要素名（指定しない場合は「name」）
 * @param {boolean} defFlg 指定したキーがnullや空白、または、リストに存在しない場合にデフォルト値を返すかどうか（指定しない場合は返さない）
 * @param {boolean} defVal デフォルト値を返すように指定した場合に返却されるデフォルト値（指定しない場合は空白を返却）
 * @returns 配列に指定されたキー要素がある場合、その値を返却。キー要素がない場合、デフォルト値がある場合はデフォルト値、それ以外はnullを返却。
 */
export function getListValue(objList, key, listKeyName = 'id', listValName = 'name', defFlg = false, defVal = '') {
  // defFlgがtrueの場合、指定したキーがnullか空白の場合はデフォルト値を返却
  if (defFlg == true && (key == null || key == '')) {
    return defVal;
  }
  // リスト内に指定したキーと同じキーを探すためのループ
  for (let i = 0; i < objList.length; i++) {
    if (key == objList[i][listKeyName]) {
      // 指定されたキーの値を返却
      return objList[i][listValName];
    }
  }
  // リストに指定したキーと同じ値がなかった場合
  if (defFlg == true) {
    return defVal;
  } else {
    return null;
  }
}

/**
 * カウンタテーブルのカウンタ取得処理（取得と同時にカウンタのインクリメントも行う）
 * @param {Int} counterClass 帳票カウンタマスタのカウンタを指定するカウンタ区分
 * @param {Int} freeClass1 帳票カウンタマスタのカウンタを指定するフリー区分１
 * @param {String} freeClass2 帳票カウンタマスタのカウンタを指定するフリー区分２
 * @param {String} updatedUser 更新時の更新ユーザー
 * @param {String} MODULE_NAME 呼出元のファイル名（エラーログ出力時に設定。指定しない場合は「common」）
 * @param {String} functionName 呼出元の関数名（エラーログ出力時に設定。指定しない場合は「getFormCounter」）
 * @returns 配列に指定されたキー要素がある場合、その値を返却。キー要素がない場合、デフォルト値がある場合はデフォルト値、それ以外はnullを返却。
 */
export async function getFormCounter(counterClass, freeClass1, freeClass2, updatedUser, MODULE_NAME = 'common', functionName = 'getFormCounter') {
  let transactSqlList = [];
  // 対象のカウンタ条件文
  let where_clause = ' WHERE ';
  where_clause += 'counter_class = ' + counterClass + ' ';
  where_clause += 'AND free_class_1 = ' + freeClass1 + ' ';
  where_clause += 'AND free_class_2 = \'' + freeClass2 + '\' ';
  // カウント（現在）取得用SELECT文
  let selectSql = 'SELECT';
  selectSql += ' count_cur';
  selectSql += ' FROM ';
  selectSql += 'm_form_counter ';
  selectSql += where_clause;
  //console.info(selectSql);
  transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
  // カウントを進める用UPDATE文
  let colList = [];
  // カウント（現在）
  let strWork = 'CASE WHEN count_cur + 1 > count_max THEN count_min ELSE count_cur + 1 END';
  colList.push(CreateColRow('count_cur', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('updated_user', updatedUser, 'VARCHAR'));
  let updateSql = CreateUpdateSql(colList, 'm_form_counter');
  updateSql += where_clause;
  //console.info(updateSql);
  transactSqlList.push({sql: updateSql, forUpdateFlg: 0});

  let retResult = null;
  // 引数のプロパティは「executeSqlInputs」
  let condition = { executeSqlInputs: transactSqlList };
  try {
    // executeSqlで実行
    let result = await API.graphql(graphqlOperation(executeSql, condition));
    // レスポンスデータを取得できた際の処理
    if(result) {
      // result.data.executeSql.bodyにJSON形式で結果が返ってくる
      const responseBody = JSON.parse(result.data.executeSql.body);

      // SQL実行でエラーが発生した場合の処理
      if(result.data.executeSql.statusCode > 200) {
        console.info({responseBody});
        // レスポンスメッセージ
        let message = responseBody.message;
        console.error({message});
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({errorMessage});
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeSql',
          executeSqlInputs: transactSqlList,
          result: result
        });
        return retResult;

      } else {
        // SQLが正常に終了した際の処理
        if (responseBody.data && responseBody.data.length) {
          // SELECT文の結果を格納
          retResult = responseBody.data[0];
        }
      }
      //console.info(JSON.parse(result.data.executeSql.body));
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeSql',
          executeSqlInputs: transactSqlList,
          result: result
        });
      }
    } else {
      // レスポンスデータを取得できなかった際の処理
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'executeSql',
        executeSqlInputs: transactSqlList
      });
    }
  } catch (error) {
    console.error(error);
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      graphqlOperation: 'executeSql',
      executeSqlInputs: transactSqlList
    }, error);
  }

  if (retResult != null && retResult.length > 0) {
    return retResult[0].count_cur;
  } else {
    return null;
  }
}

/**
 * カウンタテーブルのカウンタ取得処理（取得と同時にカウンタのインクリメントも行う）※複数カウント上昇版
 * @param {Int} counterClass 帳票カウンタマスタのカウンタを指定するカウンタ区分
 * @param {Int} freeClass1 帳票カウンタマスタのカウンタを指定するフリー区分１
 * @param {String} freeClass2 帳票カウンタマスタのカウンタを指定するフリー区分２
 * @param {Int} incrementCnt インクリメントカウント（１以上（count_max-count_min）未満であること）
 * @param {String} updatedUser 更新時の更新ユーザー
 * @param {String} MODULE_NAME 呼出元のファイル名（エラーログ出力時に設定。指定しない場合は「common」）
 * @param {String} functionName 呼出元の関数名（エラーログ出力時に設定。指定しない場合は「getFormCounter」）
 * @returns 更新前と更新後の値の整合性が取れている場合は{countCur、countMax、countMin}の形式で返却、それ以外はnullを返却。
 */
export async function getFormCounterMultiple(counterClass, freeClass1, freeClass2, incrementCnt, updatedUser, MODULE_NAME = 'common', functionName = 'getFormCounter') {
  let transactSqlList = [];
  // 対象のカウンタ条件文
  let where_clause = ' WHERE ';
  where_clause += 'counter_class = ' + counterClass + ' ';
  where_clause += 'AND free_class_1 = ' + freeClass1 + ' ';
  where_clause += 'AND free_class_2 = \'' + freeClass2 + '\' ';
  // カウント（現在）取得用SELECT文
  let selectSql = 'SELECT';
  selectSql += ' count_cur';
  selectSql += ',count_max';
  selectSql += ',count_min';
  selectSql += ' FROM ';
  selectSql += 'm_form_counter ';
  selectSql += where_clause;
  //console.info(selectSql);
  transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
  // カウントを進める用UPDATE文
  let colList = [];
  // カウント（現在）
  let strWork = 'CASE WHEN count_cur + ' + incrementCnt + ' > count_max THEN count_min - (count_max - count_cur) - 1 + ' + incrementCnt + '  ELSE count_cur + ' + incrementCnt + ' END';
  colList.push(CreateColRow('count_cur', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('updated_user', updatedUser, 'VARCHAR'));
  let updateSql = CreateUpdateSql(colList, 'm_form_counter');
  updateSql += where_clause;
  //console.info(updateSql);
  transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
  transactSqlList.push({sql: selectSql, forUpdateFlg: 0});

  let retResult = null;
  let retResultAfterUpdate = null;
  // 引数のプロパティは「executeSqlInputs」
  let condition = { executeSqlInputs: transactSqlList };
  try {
    // executeSqlで実行
    let result = await API.graphql(graphqlOperation(executeSql, condition));
    // レスポンスデータを取得できた際の処理
    if(result) {
      // result.data.executeSql.bodyにJSON形式で結果が返ってくる
      const responseBody = JSON.parse(result.data.executeSql.body);

      // SQL実行でエラーが発生した場合の処理
      if(result.data.executeSql.statusCode > 200) {
        console.info({responseBody});
        // レスポンスメッセージ
        let message = responseBody.message;
        console.error({message});
        // エラー内容
        const errorMessage = responseBody.error;
        console.error({errorMessage});
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeSql',
          executeSqlInputs: transactSqlList,
          result: result
        });
        return retResult;

      } else {
        // SQLが正常に終了した際の処理
        if (responseBody.data && responseBody.data.length) {
          // SELECT文の結果を格納
          //console.log(responseBody.data);
          retResult = responseBody.data[0];
          retResultAfterUpdate = responseBody.data[1];
        }
      }
      //console.info(JSON.parse(result.data.executeSql.body));
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeSql',
          executeSqlInputs: transactSqlList,
          result: result
        });
      }
    } else {
      // レスポンスデータを取得できなかった際の処理
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'executeSql',
        executeSqlInputs: transactSqlList
      });
    }
  } catch (error) {
    console.error(error);
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      graphqlOperation: 'executeSql',
      executeSqlInputs: transactSqlList
    }, error);
  }
  //console.log(retResult);
  //console.log(retResultAfterUpdate);
  // 複数人が同時に更新しようとした場合、正しく更新できない可能性があるため、正しく更新できた人のみ成功とする
  if (retResult != null && retResult.length > 0 &&
    retResultAfterUpdate != null && retResultAfterUpdate.length > 0) {
    if (retResult[0].count_cur + incrementCnt > retResult[0].count_max) {
      // インクリメントすると最大値を超過する場合
      if (retResultAfterUpdate[0].count_cur == retResult[0].count_min - (retResult[0].count_max - retResult[0].count_cur) - 1 + incrementCnt) {
        // 更新後の値が最小値に戻りインクリメントした値と等しい場合、正しい
        let ret = {
          countCur: retResult[0].count_cur,
          countMax: retResult[0].count_max,
          countMin: retResult[0].count_min,
        };
        return ret;
      }
    } else {
      // インクリメントしても最大値を超過しない場合
      if (retResultAfterUpdate[0].count_cur == retResult[0].count_cur + incrementCnt) {
        // 更新後の値が更新前の値＋インクリメント値の場合、正しい
        let ret = {
          countCur: retResult[0].count_cur,
          countMax: retResult[0].count_max,
          countMin: retResult[0].count_min,
        };
        return ret;
      }
    }
  }
  return null;
}

/**
 * カウンタテーブルから複数カウントからの返却値を番号に直して返却する関数（getFormCounterMultipleとセットで使用）
 * @param {{countCur、countMax、countMin}}} retFormCounterMultiple getFormCounterMultipleからの返却値
 * @param {Int} index 複数カウントの何番目の値かを指定
 * @returns 引数に合った番号を返却する（countMaxを超過する場合、countMinに戻った値を返却）。
 */
export function getFormCounterMultipleReturnNo(retFormCounterMultiple, index) {
  let countCur = retFormCounterMultiple.countCur;
  let countMax = retFormCounterMultiple.countMax;
  let countMin = retFormCounterMultiple.countMin;
  if (countCur + index > countMax) {
    return countMin - (countMax - countCur) - 1 + index;
  } else {
    return countCur + index;
  }
}

/**
 * 消費税計算処理
 * @param {String} kijunDate 基準日：消費税率と新消費税率のどちらを使うかを決めるために、新消費税開始日と比較する日付
 * @param {Int} totalMoney 合計金額：消費税を出すために税率を掛ける値
 * @param {String} MODULE_NAME 呼出元のファイル名（エラーログ出力時に設定。指定しない場合は「common」）
 * @param {String} functionName 呼出元の関数名（エラーログ出力時に設定。指定しない場合は「calcTax」）
 * @param {Float} prmTaxRate 消費税率：nullの場合、コントロールマスタテーブルから取得する
 * @param {Float} prmNewTaxRate 新消費税率：基準日が新消費税開始日に達していた場合使用
 * @param {String} prmNewTaxStartDate 新消費税開始日：基準日と比較して新消費税率を使うかどうか決める
 * @returns 消費税（失敗した場合はエラーをthrow。（呼出元でcatchすること））
 */
export async function calcTax(kijunDate, totalMoney, MODULE_NAME = 'common', functionName = 'calcTax', prmTaxRate = null, prmNewTaxRate = null, prmNewTaxStartDate = null) {
  let curTaxRate = 0;
  let newTaxStartDate = '';
  let taxRate = 0;
  let newTaxRate = 0;
  if (prmTaxRate == null) {
    // 現在処理年月取得
    let controlData = null;
    try {
      controlData = await getControlMaster();
    } catch (error) {
      await addOperationLogs('Error', MODULE_NAME, functionName, {
        graphqlOperation: 'list_m_control'
      }, error);
      throw '消費税の計算に失敗しました。';
    }
    taxRate = controlData.tax_rate;
    newTaxRate = controlData.new_tax_rate;
    newTaxStartDate = controlData.new_tax_start_date;
  } else {
    taxRate = prmTaxRate;
    newTaxRate = prmNewTaxRate;
    newTaxStartDate = prmNewTaxStartDate;
  }
  if (newTaxRate == null ||
    dateConsistency(newTaxStartDate, kijunDate) == false) {
    // 新消費税率が設定されていない、または、
    // 売上計上日が新消費税開始日に達していない場合
    curTaxRate = taxRate;
  } else {
    // 上記以外（売上計上日が新消費税開始日に達している場合）
    curTaxRate = newTaxRate;
  }
  return Math.trunc(totalMoney * curTaxRate / 100);
}

/**
 * 消費税計算処理
 * @param {String} kijunDate 基準日：消費税率と新消費税率のどちらを使うかを決めるために、新消費税開始日と比較する日付
 * @param {Int} moneySubTotalNormal 通常消費税金額小計：通常消費税を出すために税率を掛ける値
 * @param {Int} moneySubTotalLight 軽減消費税金額小計：軽減消費税を出すために税率を掛ける値
 * @param {Float} taxRate 消費税率
 * @param {Float} newTaxRate 新消費税率：基準日が新消費税開始日に達していた場合、消費税率の代わりに使用
 * @param {Float} lightTaxRate 軽減消費税率
 * @param {Float} newLightTaxRate 新軽減消費税率：基準日が新消費税開始日に達していた場合、軽減消費税率の代わりに使用
 * @param {String} newTaxStartDate 新消費税開始日：基準日と比較して新消費税率を使うかどうか決める
 * @returns 消費税（失敗した場合はエラーをthrow。（呼出元でcatchすること））
 */
export function calcTaxNew(kijunDate, moneySubTotalNormal, moneySubTotalLight, taxRate, newTaxRate, lightTaxRate, newLightTaxRate, newTaxStartDate) {
  let curTaxRate = 0;
  let curLightTaxRate = 0;
  if (newTaxRate == null ||
    dateConsistency(newTaxStartDate, kijunDate) == false) {
    // 新消費税率が設定されていない、または、
    // 売上計上日が新消費税開始日に達していない場合
    curTaxRate = taxRate;
    curLightTaxRate = lightTaxRate;
  } else {
    // 上記以外（売上計上日が新消費税開始日に達している場合）
    curTaxRate = newTaxRate;
    curLightTaxRate = newLightTaxRate;
  }
  // 軽減消費税と通常消費税の合計値を返却
  return Math.trunc(moneySubTotalNormal * curTaxRate / 100) + Math.trunc(moneySubTotalLight * curLightTaxRate / 100);
}

/**
 * 新規と更新ユーザのカラムデータを返す処理
 * @param {String} username ユーザー
 * @param {String} type 新規と更新、あるいは両方を返す
 * @returns 
 */
export async function getUserCol(userName, type='update') {
  let colUpdateCommon = []
  let colCreateCommon = []

  let username = await escapeQuote(userName);
  //  更新ユーザー
  colUpdateCommon.push(CreateColRow('updated_user', username, 'VARCHAR'));
  // 更新日
  colUpdateCommon.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'))
  //  新規ユーザー
  colCreateCommon.push(CreateColRow('created_user', username, 'VARCHAR'));
  // 新規日
  colCreateCommon.push(CreateColRow('created', 'CURRENT_TIMESTAMP()', 'DATETIME'))
  switch (type) {
  case 'create':
    return colCreateCommon
  case 'update':
    return colUpdateCommon
  case 'both':
    return colUpdateCommon.concat(colCreateCommon)
  default:
    return colUpdateCommon
  }
}

/**
 * 新規と更新ユーザの対象データを返す処理
 * @param {String} username ユーザー
 * @param {String} type 新規と更新、あるいは両方を返す
 * @returns 
 */
export async function getUserObj(userName, type='both') {
  let date = moment().format('YYYY-MM-DD HH:mm:ss')
  let username = await escapeQuote(userName);

  //  新規ユーザー
  const createUser = {
    created: date,
    created_user: username,
  }
  //  更新ユーザー
  const updatedUser = {
    updated: date,
    updated_user: username,
  }
 
  switch (type) {
  case 'create':
    return {...createUser}
  case 'update':
    return {...updatedUser}
  case 'both':
    return {...createUser, ...updatedUser}
  default:
    return {...updatedUser}
  }
}

/**
 * 営業所をstoreに保存処理
 */
export async function setOfficeListOption() {
  const functionName = 'setOfficeListOption';
  try {
    // データ取得
    let result = await API.graphql(graphqlOperation(list_m_offices));
    // 結果判定
    if (result.data.list_m_offices) {
      // データを取得できた場合、取得結果を格納
      let resultData = result.data.list_m_offices;
      store.commit('setOffice', resultData);
    } else {
      // データを取得できなかった場合
      await addOperationLogs('Error', 'common', functionName, {
        graphqlOperation: 'list_m_offices',
        result
      });
    }
  } catch (error) {
    console.log(error);
    await addOperationLogs('Error', 'common', functionName, {}, error);
  }
}

/**
 * コントロールマスタのデータを返す処理
 * @returns コントロールマスタのデータ配列（失敗した場合はエラーをthrow。（呼出元でcatchすること））
 */
export async function getControlMaster() {
  let result = await API.graphql(graphqlOperation(list_m_control));
  let resultData = result.data.list_m_control;
  if(resultData != null && resultData.length > 0) {
    return resultData[0];
  } else {
    throw 'コントロールマスタの取得に失敗しました。';
  }
}

/**
 * 製品コード判断の処理
 * @param {Int} productId 製品コード
 * @returns {Boolean} true製品コードです、false製品コードではない
 */
export function checkProductId(productId) {
  return /[0-9]{8}/.test(productId)  
}
/**
 * 取引先コード判断の処理
 * @param {Int} clientId 取引先コード
 * @returns {Boolean} true取引先コードです、false取引先コードではない
 */
export function checkClientId(clientId) {
  // 数字かつ6桁以下
  return /[0-9]{6}/.test(clientId)  
}
/**
 * 現場コード判断の処理
 * @param {Int} siteId 現場コード
 * @returns {Boolean} true現場コードです、false現場コードではない
 */
export function checkSiteId(siteId) {
  // 数字かつ4桁以下
  return /^[0-9]{0,4}$/.test(siteId)  
}
/**
 * Amplify Cacheへユーザー情報（担当者マスタ）をセット
 * @param {String} username Cognitoのユーザー名
 */
export async function setUserData(username) {
  const functionName = 'setUserData';
  try {
    console.log('setUserData');
    // 検索条件
    let condition = {};
    condition = {
      where_clause:`AND login_id = '${username}'`
    };

    // データ取得
    const result = await API.graphql(graphqlOperation(list_m_staffs, condition));
    // 結果判定
    if (result.data.list_m_staffs) {
      // データを取得できた場合、取得結果を格納
      let resultData = result.data.list_m_staffs[0];
      // 暫時対応：担当者マスタのデータに加えて、usernameのプロパティ追加
      // 画面側で使用しているusernameをlogin_idに置換後、削除
      resultData.username = resultData.login_id;
      store.commit('setUser', resultData);
      let date = new Date();
      // const.jsで定義した保持日数をセット
      date.setDate(date.getDate() + AUTH_EXPIRATION_DAYS);
      Cache.setItem('user', resultData, { expires: date.getTime() });
      return true;
    } else {
      // データを取得できなかった場合
      await addOperationLogs('Error', 'common', functionName, {
        graphqlOperation: 'list_m_staffs',
        result: result
      });
      return false;
    }
  } catch (error) {
    console.log(error);
    await addOperationLogs('Error', 'common', functionName, {}, error);
    return false;
  }
}
/**
 * String型を返却（nullは空白に変換）
 * @param {*} val 変換値
 * @returns 引数の変換値
 */
export function getNullStr(val) {
  return val == null ? '' : val.toString();
}

/**
 * 製品の受注引当
 * @param {Int} officeId 営業所コード
 * @param {Int} productId 製品コード（換算前、換算後の全製品がCSV形式で繋がる）
 * @param {Int} processMonthYear 現在処理年月
 * @param {String} user ユーザーID
 * @param {String} MODULE_NAME 呼出元のファイル名
 * @param {Int} orderReceiveId 受注番号（受注番号を指定する場合のみ指定、未指定の場合は受注番号の小さい順に引当を行う。）
 * @returns エラーの場合は引数のproductIdを返却
 */
export async function reserveOrdersReceives(officeId, productId, processMonthYear, user, MODULE_NAME, orderReceiveId = 0) {
  //console.log('Start:' + productId);
  const functionName = 'reserveOrdersReceives';
  // 初期処理
  let balance = 1;
  let sqlList = [];
  let where_clause = '';
  let condition = {};
  let updateSql = '';
  let csvSkipOrderReceiveId = [];
  let selectSql = '';
  try {
    while(balance > 0) {
      // 初期化
      sqlList = [];
      // SELECT句
      selectSql = 'SELECT ';
      selectSql += ' stocks.balance';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'stocks.month_year = ' + processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + officeId + ' ';
      selectSql += 'AND stocks.product_id IN (' + productId + ') ';
      selectSql += 'AND stocks.balance > 0 ';
      //console.log(selectSql);
      let allStocksResultData = await executeSelectSql(selectSql);
      // 在庫がない場合は完了（バラの在庫とケースの在庫両方を見る）
      if (allStocksResultData != null && allStocksResultData.length > 0) {
        let stockFlg = false;
        for (let i = 0; i < allStocksResultData.length; i++) {
          if (allStocksResultData[i].balance > 0) {
            stockFlg = true;
          }
        }
        if (stockFlg == false) {
          // 換算前、換算後、全てが在庫にない場合は完了
          break;
        }
      } else {
        // 在庫がない場合は完了
        break;
      }
      // 指定された営業所と製品コードを保持する未引当のある受注の中で最も受注番号が小さい受注を取得
      selectSql = makeHikiateMiReceiveSql(officeId, productId, orderReceiveId, csvSkipOrderReceiveId);
      //console.log(selectSql);
      let hikiateMiReceiveResultData = await executeSelectSql(selectSql);
      //console.log(hikiateMiReceiveResultData);
      // 未引当の受注がない場合は完了
      if (hikiateMiReceiveResultData == null || hikiateMiReceiveResultData.length == 0) {
        break;
      }
      csvSkipOrderReceiveId.push({orderReceiveId: hikiateMiReceiveResultData[0].order_receive_id, orderReceiveRow: hikiateMiReceiveResultData[0].order_receive_row});
      if (hikiateMiReceiveResultData[0].set_class == Const.SetClass.set &&
        hikiateMiReceiveResultData[0].case_conversion_class == Const.CaseConversionClassDef.conversion) {
        //console.log('セット品の引当');
        // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
        selectSql = makeSelectSqlSetHikiate(officeId, processMonthYear, hikiateMiReceiveResultData[0].order_receive_id, hikiateMiReceiveResultData[0].order_receive_row);
        //console.log(selectSql);
        let setResultData = await executeSelectSql(selectSql);
        //console.log(setResultData);
        if (setResultData != null && setResultData.length > 0) {
          // 在庫マスタと受注セット品データの確認
          //・在庫で0を超過する製品がないか
          //・セット品に未引当の製品は存在するか
          let reserveKanoFlg = false;
          let reserveZumiFlg = true;
          for (let i = 0; i < setResultData.length; i++) {
            if (setResultData[i].reserve_quantity_incomplete > 0) {
              reserveZumiFlg = false;
              if (setResultData[i].balance > 0) {
                reserveKanoFlg = true;
              }
            }
          }
          if (reserveZumiFlg == true) {
            // 既にセット品は全て引き当て済みの場合
            // セット製品の引当を行う
            updateSql = await updateOrdersReceivesSetReserve(hikiateMiReceiveResultData[0].order_receive_id, hikiateMiReceiveResultData[0].order_receive_row, hikiateMiReceiveResultData[0].product_id, user);
            sqlList.push({sql: updateSql, forUpdateFlg: 0});
          } else if (reserveKanoFlg == true) {
            // 部材に引当可能な在庫がある場合
            // 部材の引当を行う
            for (let i = 0; i < setResultData.length; i++) {
              if (setResultData[i].reserve_quantity_incomplete > 0 &&
              setResultData[i].balance > 0) {
                // 未引当の製品があり、且つ、在庫がある場合
                // 受注セット品データ引当SQL作成
                updateSql = updateOrdersReceivesSetBuzaiReserve(officeId, setResultData[i].order_receive_id, setResultData[i].order_receive_row, setResultData[i].order_receive_row_branch, setResultData[i].reserve_quantity_incomplete, processMonthYear, user);
                sqlList.push({sql: updateSql, forUpdateFlg: 0});
              }
            }
            // セット製品の引当を行う
            updateSql = await updateOrdersReceivesSetReserve(hikiateMiReceiveResultData[0].order_receive_id, hikiateMiReceiveResultData[0].order_receive_row, hikiateMiReceiveResultData[0].product_id, user);
            sqlList.push({sql: updateSql, forUpdateFlg: 0});
          }
        }
      } else if (hikiateMiReceiveResultData[0].set_class == Const.SetClass.noSet &&
        hikiateMiReceiveResultData[0].case_conversion_class == Const.CaseConversionClassDef.conversion) {
        //console.log('バラ製品の引当');
        // バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
        // 指定されたバラ製品コードを保持する製品単位変換マスタを確認
        where_clause = 'AND loose_product_id = ' + hikiateMiReceiveResultData[0].product_id + ' ';
        condition = {where_clause: where_clause};
        let conversionsResult = await API.graphql(graphqlOperation(list_m_products_units_conversions, condition));
        let conversionsResultData = conversionsResult.data.list_m_products_units_conversions;
        //console.log(conversionsResultData);
        // 対応する製品単位変換マスタが登録されていない場合は完了（マスタが変更されているため、引当不可能）
        if (conversionsResultData == null || conversionsResultData.length == 0) {
          continue;
        }
        // 指定された営業所とバラ製品コード、ケース製品コードを保持する在庫マスタを確認
        // SELECT句
        selectSql = 'SELECT ';
        selectSql += ' stocks.product_id';
        selectSql += ',stocks.balance';
        selectSql += ',orders_receives.reserve_quantity_incomplete';
        // FROM句
        selectSql += ' FROM ';
        selectSql += 'm_stocks AS stocks ';
        selectSql += 'INNER JOIN t_orders_receives AS orders_receives ';
        selectSql += 'ON orders_receives.order_receive_id = ' + hikiateMiReceiveResultData[0].order_receive_id + ' ';
        selectSql += 'AND orders_receives.order_receive_row = ' + hikiateMiReceiveResultData[0].order_receive_row + ' ';
        // WHERE句
        selectSql += ' WHERE ';
        selectSql += 'stocks.month_year = ' + processMonthYear + ' ';
        selectSql += 'AND stocks.office_id = ' + officeId + ' ';
        let csvProductId = conversionsResultData[0].loose_product_id + ',' + conversionsResultData[0].case_product_id;
        selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';
        //console.log(selectSql);
        let stocksResultData = await executeSelectSql(selectSql);
        //console.log(stocksResultData);
        // 在庫がない場合、または、未引当数がない場合は完了（バラの在庫とケースの在庫両方を見る）
        if (stocksResultData == null || stocksResultData.length != 2 || (stocksResultData[0].balance <= 0 && stocksResultData[1].balance <= 0) || stocksResultData[0].reserve_quantity_incomplete <= 0) {
          continue;
        }
        // 在庫のどちらがケースでどちらがバラかを確認
        let looseIndex = 0;
        let caseIndex = 0;
        if (stocksResultData[0].product_id == conversionsResultData[0].loose_product_id) {
          // 配列の１番目がバラ、２番目がケースの場合
          looseIndex = 0;
          caseIndex = 1;
        } else {
          // 上記以外（配列の１番目がケース、２番目がバラ）
          looseIndex = 1;
          caseIndex = 0;
        }
        // バラの在庫で未引当分を全て引き当てられない場合（ケースをバラす）、且つ、
        // ケースの在庫が存在する場合
        if (stocksResultData[looseIndex].balance < stocksResultData[0].reserve_quantity_incomplete && stocksResultData[caseIndex].balance > 0) {
          let caseOpenCnt = 1; // ケースを開ける数
          for (caseOpenCnt = 1; caseOpenCnt < stocksResultData[caseIndex].balance; caseOpenCnt++) {
            // ケースを開けてバラにした場合の数が未引当数に全て引き当てられる場合
            if (stocksResultData[looseIndex].balance + caseOpenCnt * conversionsResultData[0].quantity >= stocksResultData[0].reserve_quantity_incomplete) {
              // ケースを開ける数を保持して抜ける（ここに入らない場合は全ケースを開ける）
              break;
            }
          }
          // ケースを開けてバラを増加
          updateStocksMasterConversion(officeId, conversionsResultData[0].loose_product_id, conversionsResultData[0].case_product_id, caseOpenCnt, conversionsResultData[0].quantity, processMonthYear, user, sqlList);
        }
        // ケースを開ける判断が終わったら、開け終わった後のバラで引当を行う
        // 受注引当SQL作成
        updateSql = updateOrdersReceivesReserve(hikiateMiReceiveResultData[0].order_receive_id, hikiateMiReceiveResultData[0].order_receive_row, stocksResultData[0].reserve_quantity_incomplete, processMonthYear, user);
        sqlList.push({sql: updateSql, forUpdateFlg: 0});
      } else {
        //console.log('ケース換算しない製品の引当');
        // 上記以外（ケース換算しない）
        // 指定された営業所と製品コードを保持する在庫マスタを確認
        // SELECT句
        selectSql = 'SELECT ';
        selectSql += ' stocks.product_id';
        selectSql += ',stocks.balance';
        selectSql += ',orders_receives.reserve_quantity_incomplete';
        // FROM句
        selectSql += ' FROM ';
        selectSql += 'm_stocks AS stocks ';
        selectSql += 'INNER JOIN t_orders_receives AS orders_receives ';
        selectSql += 'ON orders_receives.order_receive_id = ' + hikiateMiReceiveResultData[0].order_receive_id + ' ';
        selectSql += 'AND orders_receives.order_receive_row = ' + hikiateMiReceiveResultData[0].order_receive_row + ' ';
        // WHERE句
        selectSql += ' WHERE ';
        selectSql += 'stocks.month_year = ' + processMonthYear + ' ';
        selectSql += 'AND stocks.office_id = ' + officeId + ' ';
        selectSql += 'AND stocks.product_id = ' + hikiateMiReceiveResultData[0].product_id + ' ';
        //console.log(selectSql);
        let stocksResultData = await executeSelectSql(selectSql);
        //console.log(stocksResultData);
        // 在庫がない場合は完了
        if (stocksResultData == null || stocksResultData.length == 0 || stocksResultData[0].balance <= 0) {
          continue;
        }

        if (stocksResultData[0].reserve_quantity_incomplete > 0) {
          // 在庫も受注もある場合、引当を行う
          // 受注引当SQL作成
          updateSql = updateOrdersReceivesReserve(hikiateMiReceiveResultData[0].order_receive_id, hikiateMiReceiveResultData[0].order_receive_row, stocksResultData[0].reserve_quantity_incomplete, processMonthYear, user);
          sqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
      if (sqlList.length > 0) {
        // SQL実行
        await executeSqlList(sqlList);
      }
    }
  } catch (error) {
    //console.log(error);
    //console.log('End:' + productId);
    await addOperationLogs('Error', MODULE_NAME, functionName, {errCsvProductId: productId}, error);
    return productId;
  }
  //console.log('End:' + productId);
  return '';
}

/**
 * 指定された営業所と製品コードを保持する未引当のある受注の中で最も受注番号が小さい受注を取得するSELECT文の条件
 * @param {Int} officeId 営業所コード
 * @param {Int} productId 製品コード
 * @param {Int} orderReceiveId 受注番号
 * @param {[{orderReceiveId,orderReceiveRow}]} csvSkipOrderReceiveId 引当を試みた受注番号と受注行番号の組合せ
 * @returns 検索条件
 */
export function makeHikiateMiReceiveSql(officeId, productId, orderReceiveId, csvSkipOrderReceiveId) {
  let selectSql = '';
  // SELECT句
  selectSql += 'SELECT ';
  selectSql += ' order_receive_id';
  selectSql += ',order_receive_row';
  selectSql += ',product_id';
  selectSql += ',case_conversion_class';
  selectSql += ',set_class';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 't_orders_receives ';
  // WHERE句
  selectSql += ' WHERE ';
  // 営業所コード
  selectSql += 'office_id = ' + officeId + ' ';
  // 製品コード
  selectSql += 'AND product_id IN (' + productId + ') ';
  // 受注伝票種別（返品も特別受注も引当数は0以下のため未指定でも問題なし）
  selectSql += 'AND order_receive_bill_class = ' + Const.OrderReceiveBillClass.normal + ' ';
  // 配送種別区分（直送の引当数は0のため未指定でも問題なし）
  selectSql += 'AND shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
  // 在庫管理区分
  selectSql += 'AND inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
  // 未引当数
  selectSql += 'AND reserve_quantity_incomplete > 0 ';
  // 削除済みフラグ
  selectSql += 'AND is_deleted = 0 ';
  // 受注番号
  if (orderReceiveId != 0) {
    selectSql += 'AND order_receive_id <= ' + orderReceiveId + ' ';
  }
  // 引当を試みた受注番号と受注行番号の組合せ
  if (csvSkipOrderReceiveId.length > 0) {
    let skipReceiveSql = 'AND NOT (';
    for (let i = 0; i < csvSkipOrderReceiveId.length; i++) {
      if (i > 0) {
        skipReceiveSql += 'OR '
      }
      skipReceiveSql += '(order_receive_id = '+ csvSkipOrderReceiveId[i].orderReceiveId + ' '
      skipReceiveSql += 'AND order_receive_row = '+ csvSkipOrderReceiveId[i].orderReceiveRow + ') '
    }
    skipReceiveSql += ') '
    selectSql += skipReceiveSql;
  }
  /* ORDER BY句 */
  selectSql += ' ORDER BY ';
  selectSql += ' order_receive_id';
  selectSql += ',order_receive_row';
  /* LIMIT */
  selectSql += ' LIMIT 1';

  return selectSql;
}

/**
 * 受注セット製品の引当情報取得SQL文作成
 * @param {Int} officeId 営業所コード
 * @param {Int} processMonthYear 現在処理年月
 * @param {Int} orderReceiveId 受注番号
 * @param {Int} orderReceiveRow 受注行番号
 * @returns SELECT文
 */
export function makeSelectSqlSetHikiate(officeId, processMonthYear, orderReceiveId, orderReceiveRow) {
  let selectSql = '';
  // SELECT句
  selectSql += 'SELECT ';
  selectSql += ' orders_received_set.order_receive_id';
  selectSql += ',orders_received_set.order_receive_row';
  selectSql += ',orders_received_set.order_receive_row_branch';
  selectSql += ',orders_received_set.product_id';
  selectSql += ',orders_received_set.reserve_quantity_incomplete';
  selectSql += ',stocks.balance';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 't_orders_received_set AS orders_received_set ';
  selectSql += 'INNER JOIN m_stocks AS stocks ';
  selectSql += 'ON stocks.month_year = ' + processMonthYear + ' ';
  selectSql += 'AND stocks.office_id = ' + officeId + ' ';
  selectSql += 'AND stocks.product_id = orders_received_set.product_id ';
  // WHERE句
  selectSql += ' WHERE ';
  selectSql += 'orders_received_set.order_receive_id = ' + orderReceiveId + ' ';
  selectSql += 'AND orders_received_set.order_receive_row = ' + orderReceiveRow + ' ';

  return selectSql;
}

/**
 * 受注データ更新SQL作成（引当）
 * @param {Int} orderReceiveId 受注番号
 * @param {Int} orderReceiveRow 受注行番号
 * @param {Int} reserveQuantityIncomplete 未引当数
 * @param {Int} processMonthYear 現在処理年月
 * @param {String} user ユーザーID
 * @returns UPDATE文
 */
export function updateOrdersReceivesReserve(orderReceiveId, orderReceiveRow, reserveQuantityIncomplete, processMonthYear, user) {
  //console.log('受注データ更新SQL作成（引当）');
  // 変数宣言
  let colList = [];
  let strWork = '';
  // 未引当数
  strWork = 'CASE WHEN stocks.balance >= ' + reserveQuantityIncomplete + ' THEN 0';
  strWork += ' ELSE ' + reserveQuantityIncomplete + ' - stocks.balance END';
  colList.push(CreateColRow('orders_receives.reserve_quantity_incomplete', strWork, 'NUMBER'));
  // 引当済数
  strWork = 'CASE WHEN stocks.balance >= ' + reserveQuantityIncomplete + ' THEN orders_receives.reserve_quantity + ' + reserveQuantityIncomplete;
  strWork += ' ELSE orders_receives.reserve_quantity + stocks.balance END';
  colList.push(CreateColRow('orders_receives.reserve_quantity', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('orders_receives.updated_user', user, 'VARCHAR'));
  // 在庫引当数
  strWork = 'CASE WHEN stocks.balance - ' + reserveQuantityIncomplete + ' >= 0 THEN stocks.inventory_reserve_count + ' + reserveQuantityIncomplete;
  strWork += ' ELSE stocks.inventory_reserve_count + stocks.balance END';
  colList.push(CreateColRow('stocks.inventory_reserve_count', strWork, 'NUMBER'));
  // 残高数
  strWork = 'CASE WHEN stocks.balance - ' + reserveQuantityIncomplete + ' >= 0 THEN stocks.balance - ' + reserveQuantityIncomplete;
  strWork += ' ELSE 0 END';
  colList.push(CreateColRow('stocks.balance', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('stocks.updated_user', user, 'VARCHAR'));
  let updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives', 'm_stocks AS stocks');
  updateSql += ' WHERE ';
  updateSql += 'orders_receives.order_receive_id = ' + orderReceiveId + ' ';
  updateSql += 'AND orders_receives.order_receive_row = ' + orderReceiveRow + ' ';
  updateSql += 'AND orders_receives.reserve_quantity_incomplete = ' + reserveQuantityIncomplete + ' ';
  updateSql += 'AND stocks.month_year = ' + processMonthYear + ' ';
  updateSql += 'AND orders_receives.office_id = stocks.office_id ';
  updateSql += 'AND orders_receives.product_id = stocks.product_id ';
  updateSql += 'AND stocks.balance > 0 ';
  //console.log(updateSql);

  return updateSql;
}

/**
 * 受注セット品データ更新SQL作成（引当）
 * @param {Int} officeId 営業所コード
 * @param {Int} orderReceiveId 受注番号
 * @param {Int} orderReceiveRow 受注行番号
 * @param {Int} orderReceiveRowBranch 受注行枝番
 * @param {Int} reserveQuantityIncomplete 未引当数
 * @param {Int} processMonthYear 現在処理年月
 * @param {String} user ユーザーID
 * @returns UPDATE文
 */
export function updateOrdersReceivesSetBuzaiReserve(officeId, orderReceiveId, orderReceiveRow, orderReceiveRowBranch, reserveQuantityIncomplete, processMonthYear, user) {
  //console.log('受注セット品データ更新SQL作成（引当）');
  // 変数宣言
  let colList = [];
  let strWork = '';
  // 未引当数
  strWork = 'CASE WHEN stocks.balance >= ' + reserveQuantityIncomplete + ' THEN 0';
  strWork += ' ELSE ' + reserveQuantityIncomplete + ' - stocks.balance END';
  colList.push(CreateColRow('orders_received_set.reserve_quantity_incomplete', strWork, 'NUMBER'));
  // 引当済数
  strWork = 'CASE WHEN stocks.balance >= ' + reserveQuantityIncomplete + ' THEN orders_received_set.reserve_quantity + ' + reserveQuantityIncomplete;
  strWork += ' ELSE orders_received_set.reserve_quantity + stocks.balance END';
  colList.push(CreateColRow('orders_received_set.reserve_quantity', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('orders_received_set.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('orders_received_set.updated_user', user, 'VARCHAR'));
  // 在庫引当数
  strWork = 'CASE WHEN stocks.balance - ' + reserveQuantityIncomplete + ' >= 0 THEN stocks.inventory_reserve_count + ' + reserveQuantityIncomplete;
  strWork += ' ELSE stocks.inventory_reserve_count + stocks.balance END';
  colList.push(CreateColRow('stocks.inventory_reserve_count', strWork, 'NUMBER'));
  // 残高数
  strWork = 'CASE WHEN stocks.balance - ' + reserveQuantityIncomplete + ' >= 0 THEN stocks.balance - ' + reserveQuantityIncomplete;
  strWork += ' ELSE 0 END';
  colList.push(CreateColRow('stocks.balance', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('stocks.updated_user', user, 'VARCHAR'));
  let updateSql = CreateUpdateSql(colList, 't_orders_received_set AS orders_received_set', 'm_stocks AS stocks');
  updateSql += ' WHERE ';
  updateSql += 'orders_received_set.order_receive_id = ' + orderReceiveId + ' ';
  updateSql += 'AND orders_received_set.order_receive_row = ' + orderReceiveRow + ' ';
  updateSql += 'AND orders_received_set.order_receive_row_branch = ' + orderReceiveRowBranch + ' ';
  updateSql += 'AND orders_received_set.reserve_quantity_incomplete = ' + reserveQuantityIncomplete + ' ';
  updateSql += 'AND stocks.month_year = ' + processMonthYear + ' ';
  updateSql += 'AND stocks.office_id = ' + officeId + ' ';
  updateSql += 'AND orders_received_set.product_id = stocks.product_id ';
  updateSql += 'AND stocks.balance > 0 ';
  //console.log(updateSql);

  return updateSql;
}

/**
 * 受注データ更新SQL作成（セット品の引当）
 * @param {Int} orderReceiveId 受注番号
 * @param {Int} orderReceiveRow 受注行番号
 * @param {Int} productId 製品コード（セット品）
 * @param {String} user ユーザーID
 * @returns UPDATE文
 */
export function updateOrdersReceivesSetReserve(orderReceiveId, orderReceiveRow, productId, user) {
  //console.log('受注データ更新SQL作成（セット品の引当）');
  // 変数宣言
  let colList = [];
  // 未引当数
  colList.push(CreateColRow('orders_received.reserve_quantity_incomplete', 'orders_received.remaining_quantity - orders_received_set_QUERY.reserve_quantity', 'NUMBER'));
  // 引当済数
  colList.push(CreateColRow('orders_received.reserve_quantity', 'orders_received_set_QUERY.reserve_quantity', 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('orders_received.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('orders_received.updated_user', user, 'VARCHAR'));
  // 更新用の副問い合わせテーブル作成
  let updateQuery = '(';
  updateQuery += 'SELECT';
  updateQuery += ' orders_received_set.order_receive_id';
  updateQuery += ',orders_received_set.order_receive_row';
  updateQuery += ',MIN(TRUNCATE(orders_received_set.reserve_quantity / products_compositions.quantity,0)) AS reserve_quantity';
  updateQuery += ' FROM ';
  updateQuery += 't_orders_received_set AS orders_received_set ';
  updateQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
  updateQuery += 'ON products_compositions.product_id = ' + productId + ' ';
  updateQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
  updateQuery += ' WHERE ';
  updateQuery += 'orders_received_set.order_receive_id = ' + orderReceiveId + ' ';
  updateQuery += 'AND orders_received_set.order_receive_row = ' + orderReceiveRow + ' ';
  updateQuery += 'GROUP BY orders_received_set.order_receive_id,orders_received_set.order_receive_row ';
  updateQuery += ') AS orders_received_set_QUERY';
  let updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_received', updateQuery);
  updateSql += ' WHERE ';
  updateSql += 'orders_received.order_receive_id = ' + orderReceiveId + ' ';
  updateSql += 'AND orders_received.order_receive_row = ' + orderReceiveRow + ' ';
  updateSql += 'AND orders_received.order_receive_id = orders_received_set_QUERY.order_receive_id ';
  updateSql += 'AND orders_received.order_receive_row = orders_received_set_QUERY.order_receive_row ';
  //console.log(updateSql);

  return updateSql;
}

/**
 * 在庫マスタ更新SQL作成（ケース⇒バラ換算）
 * @param {Int} officeId 営業所コード
 * @param {Int} looseProductId バラ製品コード
 * @param {Int} caseProductId ケース製品コード
 * @param {Int} caseOpenCnt ケースを開ける数
 * @param {Int} convertedQuantity 換算入数
 * @param {Int} processMonthYear 現在処理年月
 * @param {String} user ユーザーID
 * @param {[]} sqlList 実行SQL一覧
 * @returns 無し（内部で更新文はsqlListにpush）
 */
export function updateStocksMasterConversion(officeId, looseProductId, caseProductId, caseOpenCnt, convertedQuantity, processMonthYear, user, sqlList) {
  //console.log('在庫マスタ更新SQL作成（ケース換算）');
  // 変数宣言
  let colList = [];
  let strWork = '';
  // １．バラ製品の増加（ケースを開けた分）
  // 残高数
  strWork = 'CASE WHEN stocks_case.balance - ' + caseOpenCnt + ' >= 0 THEN stocks_bara.balance + ' + (caseOpenCnt * convertedQuantity).toString();
  strWork += ' ELSE stocks_bara.balance + stocks_case.balance * ' + convertedQuantity + ' END';
  colList.push(CreateColRow('stocks_bara.balance', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('stocks_bara.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('stocks_bara.updated_user', user, 'VARCHAR'));
  /* WHERE句 */
  let where_clause = ' WHERE ';
  where_clause += ' stocks_bara.month_year = ' + processMonthYear + ' ';
  where_clause += 'AND stocks_bara.office_id = ' + officeId + ' ';
  where_clause += 'AND stocks_bara.product_id = ' + looseProductId + ' ';
  where_clause += 'AND stocks_case.month_year = ' + processMonthYear + ' ';
  where_clause += 'AND stocks_case.office_id = ' + officeId + ' ';
  where_clause += 'AND stocks_case.product_id = ' + caseProductId + ' ';
  where_clause += 'AND stocks_case.balance > 0 ';
  let updateSql = CreateUpdateSql(colList, 'm_stocks AS stocks_bara', 'm_stocks AS stocks_case') + where_clause;
  //console.log(updateSql);
  sqlList.push({sql: updateSql, forUpdateFlg: 0});
  
  // ２．ケース製品の減少（ケースを開けた分）
  colList = [];
  // 残高数
  strWork = 'CASE WHEN balance - ' + caseOpenCnt + ' >= 0 THEN balance - ' + caseOpenCnt;
  strWork += ' ELSE 0 END';
  colList.push(CreateColRow('balance', strWork, 'NUMBER'));
  // 更新日
  colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('updated_user', user, 'VARCHAR'));
  /* WHERE句 */
  where_clause = ' WHERE ';
  where_clause += ' month_year = ' + processMonthYear + ' ';
  where_clause += 'AND office_id = ' + officeId + ' ';
  where_clause += 'AND product_id = ' + caseProductId + ' ';
  where_clause += 'AND balance > 0 ';
  updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
  //console.log(updateSql);
  sqlList.push({sql: updateSql, forUpdateFlg: 0});
}

/**
 * 配列の要素交代
 * @param {[]} array 対象配列
 * @param {Int} index 対象インデックス
 * @param {boolean} moveMaeFlg 前移動フラグ（true：前と交代、false：後ろと交代）
 * @returns 無し（内部で更新文はsqlListにpush）
 */
export function arrayExchange(array, index, moveMaeFlg) {
  if (moveMaeFlg == true) {
    // 前と交代
    array.splice(index - 1, 2, array[index], array[index - 1]);
  } else {
    // 後ろと交代
    array.splice(index, 2, array[index + 1], array[index]);
  }
}

/**
 * 別セッションで更新済みかどうか確認（引数のデータ取得時刻と）
 * @param {[{select,initUpdated}]} listUpdatedSelect 更新日SELECT文一覧
 *        select：updated取得SELECT文
 *        initUpdated：画面表示時更新時刻（取得した値と比較して同じ出ない場合はエラー。）
 * @returns true:別セッションで更新済み（呼出元で更新エラーとする。）、false:別セッションで更新無し
 */
export async function isOtherSessionUpdated(listUpdatedSelect) {
  let dataResult = null;
  for (let i = 0; i < listUpdatedSelect.length; i++) {
    dataResult = await executeSelectSql(listUpdatedSelect[i].select);
    if (dataResult != null && dataResult.length > 0) {
      if (listUpdatedSelect[i].initUpdated != formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss')) {
        // 更新日がデータ取得時刻と違う場合、エラー
        return true;
      }
    } else {
      // データが取得できない場合
      if (listUpdatedSelect[i].initUpdated != '0000-01-01 00:00:00') {
        // 起動時はデータが取得できた場合、エラー
        return true;
      }
    }
  }
  return false;
}

/**
 * 別セッションで更新済みかどうか確認（引数のデータ取得時刻と）
 * @param {[{select, resultArray}]} listUpdatedSelect 更新日SELECT文一覧
 *        select：updated予定レコードのSELECT文
 *        resultArray：画面表示時更新時刻リスト（取得した値と比較して同じ出ない場合はエラー。）
 * @returns true:別セッションで更新済み（呼出元で更新エラーとする。）、false:別セッションで更新無し
 */
export async function isOtherSessionUpdatedBySqlResultObj(listUpdatedSelect) {
  let dataResult = null;
  try {
    for (let i = 0; i < listUpdatedSelect.length; i++) {
      let item = listUpdatedSelect[i]
      dataResult = await executeSelectSql(item.select);
      if (dataResult != null && dataResult.length > 0) {
        // 返すレコード数違う
        if (dataResult.length != item.resultArray.length) {
          return true;
        } 
        for (let i = 0; i < dataResult.length; i++) {
          // 各レコード中身が違う
          if (String(dataResult[i].updated) != String(item.resultArray[i].updated)) {
            return true;
          }
        }
      } else {
        // データが取得できない場合
        // 前後不一致、エラー
        if (item.resultArray.length != 0 && dataResult.length == 0) {
          return true;
        }
      }
    }
    return false;
  } catch (error) {
    console.log(error);
  }
  return true;
}

/**
 * システムが編集可能かどうかをチェックします。月次更新・取引先コード切替・製品コード切替の
 * いずれかを実施中は、あらゆる登録・更新・削除を許可しない方針です。登録・更新・削除を行う
 * 場合はこの関数を使って事前に確認して下さい。
 * @param {Int} closingUpdateFlg 締更新フラグ（0:締更新状態が実行中の場合、更新不可、1:締更新状態が実行中でも更新可能）
 *                               ※締更新関連の保存の場合は1を設定して、それ以外は0として下さい。
 * @param {Int} productsBulkUpdateFlg 製品一括更新フラグ（0:製品一括更新状態が実行中の場合、更新不可、1:製品一括更新状態が実行中でも更新可能）
 *                               ※製品一括更新の保存の場合は1を設定して、それ以外は0として下さい。
 * @returns {String} 編集可能の場合はnull、編集不可の場合はその原因のメッセージ
 * @throws {Error} AppSyncがエラーを返した、またはコントロールマスタにデータが無かった場合
 * @throws {*} AppSyncのAPIコールで例外が発生した場合
 */
export async function isSystemEditable(closingUpdateFlg = 0, productsBulkUpdateFlg = 0) {
  const listMControlResult = await API.graphql(graphqlOperation(list_m_control));
  if (listMControlResult.errors) {
    throw new Error(JSON.stringify(listMControlResult.errors));
  }
  if (listMControlResult.data.list_m_control.length === 0) {
    throw new Error('コントロールマスタにレコードが存在しません。');
  }
  const mControl = listMControlResult.data.list_m_control[0];

  return mControl.monthly_update_state === Const.MonthlyUpdateStateClass.RUNNING ? DISP_MESSAGES.WARNING['2020'] : // 月次更新実施中の場合
    mControl.switch_client_id_state === Const.ClientCodeSwitchStateClass.RUNNING ? DISP_MESSAGES.WARNING['2035'] : // 取引先コード切替実施中の場合
      mControl.switch_product_id_state === Const.ProductCodeSwitchStateClass.RUNNING ? DISP_MESSAGES.WARNING['2036'] : // 製品コード切替実施中の場合
        (mControl.closing_update_state === Const.ClosingUpdateStateClass.RUNNING && closingUpdateFlg == 0) ? DISP_MESSAGES.WARNING['2053'] : // 締更新実施中の場合
          (mControl.products_bulk_update_state === Const.ClosingUpdateStateClass.RUNNING && productsBulkUpdateFlg == 0) ? DISP_MESSAGES.WARNING['2061'] : // 製品一括更新実施中の場合
            null; // 実行中ではない場合
}

/**
 * 相殺相手先コードの紐付け
 * @param {Int} client_class_from  紐付け元、取引先区分
 * @param {Int} client_id_from  紐付け元、取引先コード
 * @param {Int} client_id_to  紐付け先、取引先コード
 * @param {String} userName  更新ユーザ
 * @param {String} type  紐付けパターン ->  'insert','delete',
 * @returns {String} 紐付けのsql
 */
export async function offsetClientIdRelation(client_class_from, client_id_from, client_id_to, username = 'admin', type) {
  // 紐付け先、取引先区分
  let client_class_to = Number(client_class_from) == 1 ? 2 : 1  
  let sql = ''

  // 紐付け先の相殺相手先コードを更新
  let colList = [];
  // 更新ユーザー
  const colUser = await getUserCol(username, 'update')

  switch (type) {
  case 'insert':
    // 相殺相手先コード追加
    colList.push(CreateColRow('offset_client_id', client_id_from, 'INT'));
    sql = CreateUpdateSql(colList.concat(colUser), 'm_clients') + ` WHERE client_id = ${client_id_to} AND client_class = ${client_class_to} AND offset_client_id = 0;`;
    break;
  case 'delete':
    // 相殺相手先コードを削除
    colList.push(CreateColRow('offset_client_id', 0, 'INT'));
    sql = CreateUpdateSql(colList.concat(colUser), 'm_clients') + ` WHERE client_id = ${client_id_to} AND client_class = ${client_class_to};`;
    break;
  default:
    break;
  }

  return sql
}
/**
 * 相殺相手先コードが存在の判断
 * @param {Int} client_class_from  紐付け元、取引先区分
 * @param {Int} client_id_to  紐付け先、取引先コード
 * @returns {Boolean} すでに登録 true
 */
export async function checkOffsetClientIdFaild(client_class_from, client_id_to) {
  // 紐付け先、取引先区分
  let client_class_to = Number(client_class_from) == 1 ? 2 : 1  
  let sql = `SELECT id from m_clients WHERE client_id = ${client_id_to} AND client_class = ${client_class_to} AND offset_client_id <> 0`
  let dataResult = await executeSelectSql(sql);
  // 存在する場合、return true 
  return dataResult.length > 0
}

/**
 * 休日の判断
 * @param {Striing} kijunDate 対象日
 * @returns {Boolean} 休日 true
 */
export async function isHoliday(kijunDate) {
  if (kijunDate) {
    let valMoment = moment(kijunDate);
    if (valMoment.isValid() == false) {
      // 日付でない場合は対象外のため判定しない
      return false;
    }
    // 曜日確認
    let day = valMoment.format('d');
    for (let i = 0; i < Const.WeekHolidayList.length; i++) {
      if (day == Const.WeekHolidayList[i].value) {
        return true;
      }
    }
    // カレンダマスタの休日確認
    let where_clause = 'AND date = ' + '\''+ valMoment.format('YYYY-MM-DD') + '\' ';
    where_clause += 'AND event_class = ' + Const.CalendarEventClass.Holiday + ' ';
    let condition = {where_clause: where_clause};
    let result = await API.graphql(graphqlOperation(list_m_calendar,condition));
    let resultData = result.data.list_m_calendar;
    if (resultData != null && resultData.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    // 有効な文字列でない場合は判定しない
    return false;
  }
}

/**
 * 休日の判断（曜日のみの判定）
 * @param {Striing} kijunDate 対象日
 * @returns {Boolean} 休日 true
 */
export function isDayHoliday(kijunDate) {
  // 曜日確認
  let day = moment(kijunDate).format('d');
  for (let i = 0; i < Const.WeekHolidayList.length; i++) {
    if (day == Const.WeekHolidayList[i].value) {
      return true;
    }
  }
  return false;
}

/**
 * 翌営業日取得
 * @param {Striing} kijunDate 対象日（YYYY-MM-DD形式）
 * @returns {Striing} 翌営業日（YYYY-MM-DD形式）
 */
export async function getNextBusinessDate(kijunDate) {
  // カレンダーマスタについて、対象日から100日先まで確認
  const maxDay = 100;
  // 検索条件作成
  let selectSql = '';
  selectSql += 'SELECT ';
  selectSql += ' calendar_a.date AS next_holiday';
  selectSql += ',IfNull(calendar_b.date,\'' + kijunDate + '\') AS base_date';
  selectSql += ',DATEDIFF(calendar_a.date,IfNull(calendar_b.date,\'' + kijunDate + '\')) - 1 AS business_day_to_next_holiday';
  selectSql += ' FROM ';
  selectSql += '(SELECT date FROM m_calendar WHERE date > \'' + kijunDate + '\' AND event_class = ' + Const.CalendarEventClass.Holiday + ') AS calendar_a ';
  selectSql += 'LEFT JOIN (SELECT date FROM m_calendar WHERE date > \'' + kijunDate + '\' AND event_class = ' + Const.CalendarEventClass.Holiday + ') AS calendar_b ';
  selectSql += 'ON calendar_a.date = (SELECT min(date) FROM m_calendar WHERE date > calendar_b.date AND event_class = ' + Const.CalendarEventClass.Holiday + ') ';
  selectSql += ' WHERE ';
  selectSql += 'calendar_a.date < DATE_ADD(\'' + kijunDate + '\', INTERVAL ' + maxDay + ' DAY) ';
  selectSql += 'ORDER BY calendar_a.date ';

  let date = '';
  let dateCalendarMax = '';
  let dataResult = await executeSelectSql(selectSql);
  //console.log(selectSql);
  //console.log(dataResult);
  if (dataResult != null && dataResult.length > 0) {
    for (let i = 0; i < dataResult.length; i++) {
      for (let j = 0; j < Number(dataResult[i].business_day_to_next_holiday); j++) {
        date = formatDateCalc(dataResult[i].base_date, 0, 0, j + 1, false, 'YYYY-MM-DD');
        //console.log(date);
        if (isDayHoliday(date) == false) {
          return date;
        }
      }
    }
    // ここまでで営業日が見つからなかった場合、以降は最終休日＋１日を設定し、曜日のみ確認
    dateCalendarMax = formatDateCalc(dataResult[dataResult.length - 1].next_holiday, 0, 0, 1, false, 'YYYY-MM-DD');
  } else {
    // カレンダーに休日が登録されていない場合、基準日＋１日を設定し、曜日で確認
    dateCalendarMax = formatDateCalc(kijunDate, 0, 0, 1, false, 'YYYY-MM-DD');
  }
  // 全曜日を確認（月～日まで全て休日としていない限り、ここで必ず返却される。（月～日まで全て休日としている場合は空白を返却））
  for (let i = 0; i < 7; i++) {
    date = formatDateCalc(dateCalendarMax, 0, 0, i, false, 'YYYY-MM-DD');
    //console.log(date);
    if (isDayHoliday(date) == false) {
      return date;
    }
  }
  return '';
}

/**
 * 月次更新後の判断（画面起動時の現在処理年月と比較して、異なる場合は月次更新されたと判定）
 * @param {Striing} openProcessMonthYear  画面起動時の現在処理年月
 * @returns {Boolean} 月次更新された（画面起動時と比較） true
 */
export async function isAfterMonthlyUpdate(openProcessMonthYear) {
  // 現在処理年月取得
  let controlData = await getControlMaster();
  if (openProcessMonthYear == controlData.process_month_year) {
    return false;
  } else {
    return true;
  }
}

/**
 * 取引先の受注メモ取得
 * @param {String} clientId 取引先コード
 * @returns {String} 受注メモ
 */
export async function getOrderReceiveClientNote(clientId) {
  let selectSql = '';
  selectSql += 'SELECT ';
  selectSql += 'order_receive_note';
  selectSql += ' FROM ';
  selectSql += 'm_clients';
  selectSql += ' WHERE ';
  selectSql += 'client_class = ' + Const.ClientClass.customer + ' ';
  selectSql += 'AND client_id = ' + clientId + ' ';

  let dataResult = await executeSelectSql(selectSql);
  if (dataResult != null && dataResult.length > 0) {
    return dataResult[0].order_receive_note;
  }
  return '';
}

/**
 * 現場の受注メモ取得
 * @param {String} clientId 取引先コード
 * @param {String} siteId 現場コード
 * @returns {String} 受注メモ
 */
export async function getOrderReceiveSiteNote(clientId, siteId) {
  let selectSql = '';
  selectSql += 'SELECT ';
  selectSql += 'order_receive_note';
  selectSql += ' FROM ';
  selectSql += 'm_clients_sites';
  selectSql += ' WHERE ';
  selectSql += 'client_id = ' + clientId + ' ';
  selectSql += 'AND site_id = ' + siteId + ' ';

  let dataResult = await executeSelectSql(selectSql);
  if (dataResult != null && dataResult.length > 0) {
    return dataResult[0].order_receive_note;
  }
  return '';
}

/**
 * ルートマスタチェック
 * @param {Striing} shippingCode 配送コード
 * @returns {Boolean} 配送マスタに存在する true
 */
export async function checkMasterRoutes(shippingCode) {
  let where_clause = 'AND shipping_code = BINARY ' + '\''+ shippingCode + '\' ';
  let condition = {where_clause: where_clause};
  let result = await API.graphql(graphqlOperation(list_m_routes,condition));
  let resultData = result.data.list_m_routes;
  if (resultData != null && resultData.length > 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * パラメータのSELECT文を実行してその結果を返却（制限無しの大量データ取得用）
 * @param {String} selectSql SELECT文
 * @param {Int} limitCnt SELECT文1回毎の取得上限（設定しない場合は定数を設定）
 * @returns SELECT文の返却値の配列、エラーが発生した場合はエラーをthrow。（呼出元でcatchすること）
 */
export async function executeSelectSqlNoLimit(selectSql, limitCnt = Const.SelectLimit) {
  let selectSqlCount = 'SELECT COUNT(*) AS CNT FROM (' + selectSql + ') AS COUNT_QUERY';
  let dataResult = await executeSelectSql(selectSqlCount);
  let selectCnt = dataResult[0].CNT;
  //console.log(selectCnt);

  if (selectCnt == 0) {
    // 件数が0件の場合はnullを返却
    return null;
  }
  let selectLimit = '';
  let arrRet = [];
  for (let i = 0; i * limitCnt <= selectCnt; i++) {
    selectLimit = selectSql + ' LIMIT ' + limitCnt + ' OFFSET ' + (i * limitCnt).toString();

    dataResult = await executeSelectSql(selectLimit);
    if (dataResult != null && dataResult.length > 0) {
      arrRet = arrRet.concat(dataResult);
      //console.log(arrRet);
    }
  }
  return arrRet;
}

/**
 * パラメータから製品情報を取り直し、製品情報の売価を更新
 * @param {[]} productList 製品一覧（製品情報を保持する配列）
 * @param {Int} clientClass 取引先区分
 * @param {Int} clientId 取引先コード
 * @param {String} kijunDate 基準日
 * @param {String} productIdText 製品コードの要素名
 * @param {boolean} clientAmountFlg 取引先製品単価区分の有無
 * @param {String} sellingPriceText 売価の要素名
 * @param {String} clientAmountClassText 取引先製品単価区分の要素名
 * @param {boolean} orderReceiveFlg 受注画面かどうか（受注画面の場合、サービス区分が設定されている場合、売価は0）
 * @returns 無し エラーが発生した場合はエラーをthrow。（呼出元でcatchすること）
 */
export async function refreshSalesAmountProductList(productList, clientClass, clientId, kijunDate, productIdText = 'ProductCode', clientAmountFlg = false, sellingPriceText = 'SellingPrice', clientAmountClassText = 'ClientAmountClass', orderReceiveFlg = false, serviceClassText = 'ServiceClass') {
  let csvProductId = '';
  for (let i = 0; i < productList.length; i++) {
    if (getNullStr(productList[i][productIdText]) != '' && isNaN(getNullStr(productList[i][productIdText])) == false) {
      if (csvProductId != '') {
        csvProductId += ',';
      }
      csvProductId += productList[i][productIdText];
    }
  }
  if (csvProductId == '') {
    return;
  }
  let selectSql = '';
  selectSql = makeSelectSqlAmountProduct(clientClass, clientId, kijunDate, csvProductId);
  //console.log(selectSql);
  let resultData = await executeSelectSql(selectSql);
  //console.log(resultData);
  if (resultData != null) {
    for (let i = 0; i < productList.length; i++) {
      let data = resultData.find(el => el.product_id == productList[i][productIdText]);
      if (data != undefined) {
        if (orderReceiveFlg == true) {
          // 受注入力（修正）画面
          if (productList[i][serviceClassText] != '') {
            // サービス区分が空白以外の場合、強制0
            productList[i][sellingPriceText] = 0;
          } else {
            // サービス区分が空白
            productList[i][sellingPriceText] = data.sales_unit_price;
          }
        } else {
          // 受注入力（修正）画面以外
          productList[i][sellingPriceText] = data.sales_unit_price;
        }
        if (clientAmountFlg == true) {
          if (data.client_amount_class == 1) {
            productList[i][clientAmountClassText] = '*';
          } else {
            productList[i][clientAmountClassText] = '';
          }
        }
      }
    }
  }
}

/**
 * パラメータから製品情報を取り直し、製品情報の金額を更新
 * @param {Int} clientClass 取引先区分
 * @param {Int} clientId 取引先コード
 * @param {String} kijunDate 基準日
 * @param {String} csvProductId カンマ区切りの製品コード
 * @returns SELECT文
 */
export function makeSelectSqlAmountProduct(clientClass, clientId, kijunDate, csvProductId) {
  // 取引先製品マスタクエリ２（取引先製品毎の最大適用日のレコードのみ取得）
  let selectSqlQuery2 = 'SELECT';
  selectSqlQuery2 += ' client_class';
  selectSqlQuery2 += ',client_id';
  selectSqlQuery2 += ',product_id';
  selectSqlQuery2 += ',MAX(unit_price_effective_date) AS unit_price_effective_date';
  selectSqlQuery2 += ' FROM ';
  selectSqlQuery2 += 'm_clients_products ';
  selectSqlQuery2 += ' WHERE ';
  selectSqlQuery2 += 'client_class = ' + clientClass + ' ';
  selectSqlQuery2 += 'AND client_id = ' + clientId + ' ';
  selectSqlQuery2 += 'AND product_id IN (' + csvProductId + ') ';
  selectSqlQuery2 += 'AND unit_price_effective_date <= \'' + kijunDate + '\' ';
  selectSqlQuery2 += 'GROUP BY client_class,client_id,product_id ';
  // 取引先製品マスタクエリ（取引先製品毎の最大適用日のレコードの製品コードと売上単価）
  let selectSqlQuery = 'SELECT';
  selectSqlQuery += ' clients_products.product_id';
  selectSqlQuery += ',clients_products.sales_unit_price';
  selectSqlQuery += ' FROM ';
  selectSqlQuery += 'm_clients_products AS clients_products ';
  selectSqlQuery += 'INNER JOIN (' + selectSqlQuery2 + ') AS clients_products_QUERY2 ';
  selectSqlQuery += 'ON clients_products_QUERY2.client_class = clients_products.client_class ';
  selectSqlQuery += 'AND clients_products_QUERY2.client_id = clients_products.client_id ';
  selectSqlQuery += 'AND clients_products_QUERY2.product_id = clients_products.product_id ';
  selectSqlQuery += 'AND clients_products_QUERY2.unit_price_effective_date = clients_products.unit_price_effective_date ';
  // SELECT文
  let selectSql = 'SELECT';
  selectSql += ' products.product_id';
  selectSql += ',IfNull(clients_products_QUERY.sales_unit_price,products.sales_unit_price) AS sales_unit_price';
  selectSql += ',CASE WHEN clients_products_QUERY.product_id IS NULL THEN 0 ELSE 1 END AS client_amount_class';
  selectSql += ' FROM ';
  selectSql += 'm_products AS products ';
  selectSql += 'LEFT JOIN (' + selectSqlQuery + ') AS clients_products_QUERY ';
  selectSql += 'ON clients_products_QUERY.product_id = products.product_id ';
  selectSql += ' WHERE ';
  selectSql += 'products.product_id IN (' + csvProductId + ') ';

  return selectSql;
}

/**
 * パラメータから製品情報を取り直し、製品情報の取引先製品単価区分を更新
 * @param {[]} productList 製品一覧（製品情報を保持する配列）
 * @param {Int} clientClass 取引先区分
 * @param {Int} clientId 取引先コード
 * @param {String} kijunDate 基準日
 * @param {String} productIdText 製品コードの要素名
 * @param {String} clientAmountClassText 取引先製品単価区分の要素名
 * @returns 無し エラーが発生した場合はエラーをthrow。（呼出元でcatchすること）
 */
export async function refreshClientAmountClassProductList(productList, clientClass, clientId, kijunDate, productIdText = 'ProductCode', clientAmountClassText = 'ClientAmountClass') {
  let csvProductId = '';
  for (let i = 0; i < productList.length; i++) {
    if (getNullStr(productList[i][productIdText]) != '' && isNaN(getNullStr(productList[i][productIdText])) == false) {
      if (csvProductId != '') {
        csvProductId += ',';
      }
      csvProductId += productList[i][productIdText];
    }
  }
  if (csvProductId == '') {
    return;
  }
  let selectSql = '';
  selectSql = makeSelectSqlAmountProduct(clientClass, clientId, kijunDate, csvProductId);
  //console.log(selectSql);
  let resultData = await executeSelectSql(selectSql);
  //console.log(resultData);
  if (resultData != null) {
    for (let i = 0; i < productList.length; i++) {
      let data = resultData.find(el => el.product_id == productList[i][productIdText]);
      if (data != undefined) {
        if (data.client_amount_class == 1) {
          productList[i][clientAmountClassText] = '*';
        } else {
          productList[i][clientAmountClassText] = '';
        }
      }
    }
  }
}

/**
 * 年月と締日から日付を返却（締日が末日の場合、その月の末日を返却）
 * @param {Int} processMonthYear 処理年月（YYYYMM）
 * @param {Int} closingDate 締日（5,10,15,20,25,99）
 * @param {Boolean} isStart 開始かどうか（true：返却値は開始日、false：返却値は終了日）
 * @returns 開始日、または、終了日（文字列型）
 */
export function getClosingDate(processMonthYear, closingDate, isStart) {
  let date = '';
  let format = 'YYYY-MM-DD';
  if (closingDate == 99) {
    // 末日の場合
    if (isStart == true) {
      // 開始日：処理年月の月初日
      date = moment(processMonthYear + '01').format(format);
    } else {
      // 締切日：処理年月の月末日
      date = moment(processMonthYear + '01').endOf('month').format(format);
    }
  } else {
    // 末日でない場合
    if (isStart == true) {
      // 開始日：処理年月の先月の締日の翌日
      date = moment(processMonthYear + ('00' + closingDate).slice(-2)).add(-1,'months').add(1,'days').format(format);
    } else {
      // 締切日：処理年月の締日
      date = moment(processMonthYear + ('00' + closingDate).slice(-2)).format(format);
    }
  }
  return date;
}

/**
 * 在庫引当用の製品一覧をケース換算を含めたものに修正
 * @param {[]} stockProductIdList 在庫引当用の製品コード一覧
 * @returns 修正後の配列
 */
export async function modStockProductIdListCase(stockProductIdList) {
  //console.time('modStockProductIdListCase');
  //console.log(stockProductIdList);
  // 返却値用
  let memoryProductIdList = [];
  let promiseArray = [];
  for (let i = 0; i < stockProductIdList.length; i++) {
    if (memoryProductIdList.find(el => el == stockProductIdList[i]) == undefined) {
      promiseArray.push(modStockProductIdCase(stockProductIdList[i]));
      memoryProductIdList.push(stockProductIdList[i]);
    }
  }
  //console.log(memoryProductIdList);
  let aryProductIdList = await Promise.all(promiseArray);
  let retStockProductIdList = [];
  //console.log(aryProductIdList);
  if (aryProductIdList != null && aryProductIdList.length > 0) {
    for (let i = aryProductIdList.length - 1; i > 0; i--) {
      //console.log('リフレッシュ:' + i);
      let delFlg = false;
      for (let j = i - 1; j >= 0; j--) {
        for (let k = 0; k < aryProductIdList[i].length; k++) {
          if (aryProductIdList[j].find(el => el == aryProductIdList[i][k]) != undefined) {
            // 重複する製品コードがあった場合、結合して重複部分は削除
            aryProductIdList[j] = aryProductIdList[j].concat(aryProductIdList[i]);
            aryProductIdList[j] = Array.from(new Set(aryProductIdList[j]));
            aryProductIdList.splice(i, 1);
            delFlg = true;
            //console.log('結合:' + i + 'と' + j);
            break;
          }  
        }
        if (delFlg == true) {
          break;
        }
      }
    }
    let csvProductIdList = [];
    for (let i = 0; i < aryProductIdList.length; i++) {
      let csvProductId = '';
      aryProductIdList[i].sort();
      for (let j = 0; j < aryProductIdList[i].length; j++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += aryProductIdList[i][j];
      }
      csvProductIdList.push(csvProductId);
    }
    if (csvProductIdList.length > 0) {
      for (let i = 0; i < csvProductIdList.length; i++) {
        if (retStockProductIdList.find(el => el == csvProductIdList[i]) == undefined) {
          retStockProductIdList.push(csvProductIdList[i]);
        }
      }  
    }
  }
  //console.log(retStockProductIdList);
  //console.timeEnd('modStockProductIdListCase');
  return retStockProductIdList;
}

/**
 * 在庫引当用の製品一覧をケース換算を含めたものに修正
 * @param {[]} productId 在庫引当用の製品コード
 * @returns 修正後の配列
 */
export async function modStockProductIdCase(productId) {
  let memoryProductIdList = [];
  let looseResult = null;
  let caseResult = null;
  let componentResult = null;
  let setResult = null;
  memoryProductIdList.push(productId);
  // 指定されたバラ製品コードを保持する製品単位変換マスタを確認
  let looseCondition = {where_clause: 'AND loose_product_id = ' + productId + ' '};
  // 指定されたケース製品コードを保持する製品単位変換マスタを確認
  let caseCondition = {where_clause: 'AND case_product_id = ' + productId + ' '};
  // 指定された部材製品コードを保持する製品構成マスタを確認
  let componentCondition = {where_clause: 'AND component_product_id = ' + productId + ' '};
  // 指定されたセット製品コードを保持する製品構成マスタを確認
  let setCondition = {where_clause: 'AND product_id = ' + productId + ' '};
  [looseResult, caseResult, componentResult, setResult] = await Promise.all([
    API.graphql(graphqlOperation(list_m_products_units_conversions, looseCondition)),
    API.graphql(graphqlOperation(list_m_products_units_conversions, caseCondition)),
    API.graphql(graphqlOperation(list_m_products_compositions, componentCondition)),
    API.graphql(graphqlOperation(list_m_products_compositions, setCondition)),
  ]);
  let looseResultData = looseResult.data.list_m_products_units_conversions;
  //console.log(looseResultData);
  if (looseResultData != null && looseResultData.length > 0) {
    // バラ製品に対するケース製品が存在する場合
    if (memoryProductIdList.find(el => el == looseResultData[0].case_product_id) == undefined) {
      memoryProductIdList.push(looseResultData[0].case_product_id);
    }
  }
  let caseResultData = caseResult.data.list_m_products_units_conversions;
  if (caseResultData != null && caseResultData.length > 0) {
    // ケース製品に対するバラ製品が存在する場合
    for (let j = 0; j < caseResultData.length; j++) {
      if (memoryProductIdList.find(el => el == caseResultData[j].loose_product_id) == undefined) {
        memoryProductIdList.push(caseResultData[j].loose_product_id);
      }
    }
  }
  //console.log(caseResultData);
  let componentResultData = componentResult.data.list_m_products_compositions;
  if (componentResultData != null && componentResultData.length > 0) {
    // 部材製品に対するセット製品が存在する場合
    for (let j = 0; j < componentResultData.length; j++) {
      if (memoryProductIdList.find(el => el == componentResultData[j].product_id) == undefined) {
        memoryProductIdList.push(componentResultData[j].product_id);
      }
    }
  }
  //console.log(componentResultData);
  let setResultData = setResult.data.list_m_products_compositions;
  if (setResultData != null && setResultData.length > 0) {
    // セット製品に対する部材製品が存在する場合
    for (let j = 0; j < setResultData.length; j++) {
      if (memoryProductIdList.find(el => el == setResultData[j].component_product_id) == undefined) {
        memoryProductIdList.push(setResultData[j].component_product_id);
      }
    }
  }
  //console.log(setResultData);
  return memoryProductIdList;
}

/**
 * 一覧の製品の受注引当
 * @param {Int} officeId 営業所コード
 * @param {[]} csvStockProductIdList 在庫引当用の製品コード（CSV形式）配列（modStockProductIdListCaseの返却値）
 * @param {Int} processMonthYear 現在処理年月
 * @param {String} user ユーザーID
 * @param {String} MODULE_NAME 呼出元のファイル名
 * @param {Int} orderReceiveId 受注番号（受注番号を指定する場合のみ指定、未指定の場合は受注番号の小さい順に引当を行う。）
 * @returns エラーが発生した製品コード（CSV形式）を返却
 */
export async function reserveStockProductIdList(officeId, csvStockProductIdList, processMonthYear, user, MODULE_NAME, orderReceiveId = 0) {
  //console.time('reserveStockProductIdList');
  let errCsvProductId = '';
  let promiseArray = [];
  for (let i = 0; i < csvStockProductIdList.length; i++) {
    //console.log(i);
    promiseArray.push(reserveOrdersReceives(officeId, csvStockProductIdList[i], processMonthYear, user, MODULE_NAME, orderReceiveId));
  }
  let errProductIdList = await Promise.all(promiseArray);
  //console.log(errProductIdList);
  if (errProductIdList != null && errProductIdList.length > 0) {
    for (let i = 0; i < errProductIdList.length; i++) {
      //console.log('エラー文字列連結：' + (i + 1).toString() + '/' + errProductIdList.length);
      if (errProductIdList[i] != '') {
        if (errCsvProductId != '') {
          errCsvProductId += ',';
        }
        errCsvProductId += errProductIdList[i];
      }
    }
  }
  //console.log(errCsvProductId);
  //console.timeEnd('reserveStockProductIdList');
  return errCsvProductId;
}

/**
 * パラメータの製品情報は受注コピーして問題はないか？（製品コード0の行無し、且つ、製品名は正規であること）
 * @param {[]} productList 製品一覧（製品情報を保持する配列）
 * @returns null：受注コピーOK、{msgId、productId}：受注コピーNG ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export async function checkReceivedOrderCopy(productList) {
  let csvProductId = '';
  for (let i = 0; i < productList.length; i++) {
    if (getNullStr(productList[i].ProductCode) != '0') {
      if (csvProductId != '') {
        csvProductId += ',';
      }
      csvProductId += productList[i].ProductCode;
    } else {
      // 製品コードが0のレコードが1つでもある場合は受注コピーNG
      return {msgId: '2055', productId: 0};
    }
  }
  if (csvProductId != '') {
    let where_clause = 'AND product_id IN (' + csvProductId + ') ';
    let condition = {where_clause: where_clause};
    let productsResult = await API.graphql(graphqlOperation(list_m_products,condition));
    let productsResultData = productsResult.data.list_m_products;
    //console.log(productsResultData);
    if (productsResultData != null) {
      for (let i = 0; i < productList.length; i++) {
        let data = productsResultData.find(el => el.product_id == productList[i].ProductCode);
        if (data != undefined) {
          productList[i].ProductNameRight = data.product_name_kanji;
          productList[i].SundriesClass = data.sundries_class;
        }
      }
    }
  } else {
    // 入らない想定
    return {msgId: '2057'};
  }
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].SundriesClass == Const.SundriesClass.normal) {
      // 製品名のチェックは行わないに仕様変更
      /*
      if (productList[i].ProductName != productList[i].ProductNameRight) {
        // 製品名が正規の値以外
        return {msgId: '2056', productId: productList[i].ProductCode};
      }
      */
    } else if (productList[i].SundriesClass != Const.SundriesClass.shokuchi) {
      // 諸口区分が不正、または、製品マスタに存在しない製品コード
      return {msgId: '2055', productId: productList[i].ProductCode};
    }
  }
  return null;
}

/**
 * 単価登録の更新一覧の返却処理
 * @param {[]} productList 製品一覧（製品情報を保持する配列）
 * @param {Int} clientId 取引先コード
 * @param {String} dateUnitPriceEffectiveDate 単価適用日
 * @param {Int} registerClass 登録区分（「0：グループ」「1：単独」）
 * @param {Int} unitPriceRegisterClass 単価登録区分（「2：自身のみ登録」「3：親子全て登録」）※「1：登録しない」の場合は本関数は呼び出さないこと
 * @param {String} user ユーザーID
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export async function getInsertUnitPriceSqlList(productList, clientId, dateUnitPriceEffectiveDate, registerClass, unitPriceRegisterClass, user) {
  if (registerClass == Const.ClientsProductsMasterRegisterClass.group) {
    // 登録区分が「0:グループ」の場合、製品一覧のグループ情報を取得して追加
    let promiseArray = [];
    for (let i = 0; i < productList.length; i++) {
      let selectSql = makeSelectSqlGroupProductId(productList[i].ProductCode);
      promiseArray.push(executeSelectSql(selectSql));
    }
    //console.log(promiseArray);
    let productGroupList = await Promise.all(promiseArray);
    for (let i = 0; i < productList.length; i++) {
      productList[i].productGroupIdList = [];
      let productGroupResult = productGroupList[i];
      if (productGroupResult != null && productGroupResult.length > 0) {
        for (let j = 0; j < productGroupResult.length; j++) {
          // 代表製品コード
          let productIndex = productList[i].productGroupIdList.findIndex(el => el == productGroupResult[j].product_group_id);
          if (productIndex == -1) {
            // 入っていない場合は追加
            productList[i].productGroupIdList.push(productGroupResult[j].product_group_id);
          }
          // 製品コード
          productIndex = productList[i].productGroupIdList.findIndex(el => el == productGroupResult[j].product_id);
          if (productIndex == -1) {
            // 入っていない場合は追加
            productList[i].productGroupIdList.push(productGroupResult[j].product_id);
          }
        }
      } else {
        // グループ登録されていない場合は自己を追加
        productList[i].productGroupIdList.push(productList[i].ProductCode);
      }
    }
  } else {
    // 登録区分が「0:グループ」以外の場合、製品一覧のグループ情報には自己の製品コードのみを追加
    for (let i = 0; i < productList.length; i++) {
      productList[i].productGroupIdList = [productList[i].ProductCode];
    }
  }
  let sqlList = [];
  // どの取引先に対して単価登録するか
  if (getNullStr(clientId) != '0' && unitPriceRegisterClass == Const.UnitPriceRegisterClass.insertAll) {
    // 単価登録区分が「3:親子全て登録」の場合、且つ、親子登録されている可能性がある場合
    let where_clause = '';
    where_clause += 'AND (client_class_parent = ' + Const.ClientClass.customer + ' ';
    where_clause += 'AND client_id_parent = ' + clientId + ') ';
    where_clause += 'OR (client_class_branch = ' + Const.ClientClass.customer + ' ';
    where_clause += 'AND client_id_branch = ' + clientId + ') ';
    where_clause += 'LIMIT 1 ';
    let resultClientsParentChild = await API.graphql(graphqlOperation(list_m_clients_parent_child,{where_clause: where_clause}));
    let dataClientsParentChild = resultClientsParentChild.data.list_m_clients_parent_child;
    if (dataClientsParentChild != null && dataClientsParentChild.length > 0) {
      // 取引先コード（親）を取得
      let clientIdParent = dataClientsParentChild[0].client_id_parent;
      // 取得した取引先コード（親）を元に取引先コード（子）を取得
      where_clause = '';
      where_clause += 'AND client_class_parent = ' + Const.ClientClass.customer + ' ';
      where_clause += 'AND client_id_parent = ' + clientIdParent + ' ';
      resultClientsParentChild = await API.graphql(graphqlOperation(list_m_clients_parent_child,{where_clause: where_clause}));
      dataClientsParentChild = resultClientsParentChild.data.list_m_clients_parent_child;
      // 取引先コード（子）をCSVで取得
      let csvClientId = '';
      for (let i = 0; i < dataClientsParentChild.length; i++) {
        // 親と同じ区分値の子は除外
        if (clientIdParent != dataClientsParentChild[i].client_id_branch) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += dataClientsParentChild[i].client_id_branch;
        }
      }
      // 画面に入力された製品コード分の単価登録を行う
      let productIdList = [];
      for (let i = 0; i < productList.length; i++){
        for (let j = 0; j < productList[i].productGroupIdList.length; j++) {
          let productIdIndex = productIdList.findIndex(el => el == productList[i].productGroupIdList[j]);
          if (productIdIndex == -1) {
            // 数値以外の場合は追加しない
            productIdList.push(productList[i].productGroupIdList[j]);
          }
        }
      }
      let csvProductId = productIdList.join(',');
      // 親取引先の単価登録を行う
      pushMergeClientsProducts(sqlList, clientIdParent, dateUnitPriceEffectiveDate, productList, user);
      copyChildClientsProducts(sqlList, csvClientId, csvProductId, clientIdParent, dateUnitPriceEffectiveDate, user);
    } else {
      // 親子取引先が登録されていない登録の場合、自身の単価登録のみ
      pushMergeClientsProducts(sqlList, clientId, dateUnitPriceEffectiveDate, productList, user);
    }
  } else {
    // 単価登録区分が「2：自身のみ登録」、または、未設定の場合、自身の単価登録のみ
    pushMergeClientsProducts(sqlList, clientId, dateUnitPriceEffectiveDate, productList, user);
  }
  //console.log(sqlList);
  return sqlList;
}

/**
 * 単価登録マージ文を追加パラメータのSQLリストに追加する
 * @param {Int} productId 取引先コード
 * @returns String:引数の製品コードのグループを全て取得するSELECT文
 */
export function makeSelectSqlGroupProductId(productId) {
  let selectSql;
  // SELECT句
  selectSql = 'SELECT ';
  selectSql += ' products_prices_groups.product_group_id';
  selectSql += ',products_prices_groups.product_id';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 'm_products_prices_groups AS products_prices_groups ';
  selectSql += 'INNER JOIN (SELECT product_group_id FROM m_products_prices_groups';
  selectSql += '  WHERE ';
  selectSql += 'product_group_id = ' + productId + ' ';
  selectSql += 'OR product_id = ' + productId + ' ';
  selectSql += 'LIMIT 1) AS products_prices_groups_QUERY ';
  // WHERE句
  selectSql += ' WHERE ';
  selectSql += 'products_prices_groups.product_group_id = products_prices_groups_QUERY.product_group_id ';

  return selectSql;
}

/**
 * 単価登録マージ文を追加パラメータのSQLリストに追加する
 * @param {[]} sqlList SQLリスト（ここにマージ文をpush）
 * @param {Int} clientId 取引先コード
 * @param {String} dateUnitPriceEffectiveDate 単価適用日
 * @param {[]} productList 製品一覧（製品情報を保持する配列）
 * @param {String} user ユーザーID
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export function pushMergeClientsProducts(sqlList, clientId, dateUnitPriceEffectiveDate, productList, user) {
  let bulkInsertSql = '';
  let clientClass = Const.ClientClass.customer;
  if (getNullStr(clientId) == '0') {
    clientClass = 0;
  }
  // 製品の分だけループ
  for (let i = 0; i < productList.length; i++){
    for (let j = 0; j < productList[i].productGroupIdList.length; j++) {
      let colList = [];
      // 取引先区分
      colList.push(CreateColRow('client_class', clientClass, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', clientId, 'NUMBER'));
      // 製品コード
      colList.push(CreateColRow('product_id', productList[i].productGroupIdList[j], 'NUMBER'));
      // 単価適用日
      colList.push(CreateColRow('unit_price_effective_date', dateUnitPriceEffectiveDate, 'DATE'));
      // 売上単価
      colList.push(CreateColRow('sales_unit_price', productList[i].SellingPrice, 'NUMBER'));
      // 理由
      colList.push(CreateColRow('reason', '自動', 'VARCHAR'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', user, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', user, 'VARCHAR'));
      if (bulkInsertSql == '') {
        bulkInsertSql += 'INSERT INTO m_clients_products (' + CreateInsertSql(colList, 'col', 'm_clients_products') + ') VALUES ';
      } else {
        bulkInsertSql += ',';
      }
      bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 'm_clients_products') + ')';
      if (bulkInsertSql.length >= Const.SqlMaxLength) {
        let mergeColList = [];
        // 売上単価
        mergeColList.push(CreateColRow('sales_unit_price', 'sales_unit_price', 'COLUMN'));
        // 理由
        mergeColList.push(CreateColRow('reason', 'reason', 'COLUMN'));
        // 更新日
        mergeColList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        mergeColList.push(CreateColRow('updated_user', 'updated_user', 'COLUMN'));
        bulkInsertSql += CreateMergeSql(mergeColList);
        //console.log(bulkInsertSql)
        sqlList.push(bulkInsertSql);
        bulkInsertSql = '';
      }
    }
  }
  // 登録するレコードがある場合、マージ文追加（登録済みの場合に更新したい値を設定）
  if (bulkInsertSql != '') {
    let mergeColList = [];
    // 売上単価
    mergeColList.push(CreateColRow('sales_unit_price', 'sales_unit_price', 'COLUMN'));
    // 理由
    mergeColList.push(CreateColRow('reason', 'reason', 'COLUMN'));
    // 更新日
    mergeColList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
    // 更新ユーザー
    mergeColList.push(CreateColRow('updated_user', 'updated_user', 'COLUMN'));
    bulkInsertSql += CreateMergeSql(mergeColList);
    //console.log(bulkInsertSql)
    sqlList.push(bulkInsertSql);
  }
}

/**
 * 取引先製品の親情報を子取引先にコピー
 * @param {String} csvClientId コピー対象の取引先コード（CSV形式）
 * @param {String} csvProductId コピー対象の製品コード（CSV形式）
 * @param {Int} clientIdParent 取引先コード（親）
 * @param {String} dateUnitPriceEffectiveDate 単価適用日
 * @param {String} user ユーザーID
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export function copyChildClientsProducts(sqlList, csvClientId, csvProductId, clientIdParent, dateUnitPriceEffectiveDate, user) {
  // 最初にdelete（指定された子取引先の単価情報の全適用日分を削除）
  let delSql = 'DELETE FROM m_clients_products WHERE ';
  delSql += 'client_class = ' + Const.ClientClass.customer + ' ';
  delSql += 'AND client_id IN (' + csvClientId + ') ';
  delSql += 'AND product_id IN (' + csvProductId + ') ';
  delSql += 'AND unit_price_effective_date = \'' + dateUnitPriceEffectiveDate + '\' ';
  //console.log(delSql);
  sqlList.push(delSql);
  // 親取引先の同製品の単価情報を全適用日分コピーするSQL作成
  let copySql = '';
  let colList = [];
  // 取引先区分
  colList.push(CreateColRow('client_class', null, 'NUMBER'));
  // 取引先コード
  colList.push(CreateColRow('client_id', null, 'NUMBER'));
  // 製品コード
  colList.push(CreateColRow('product_id', null, 'NUMBER'));
  // 単価適用日
  colList.push(CreateColRow('unit_price_effective_date', null, 'DATE'));
  // 売上単価
  colList.push(CreateColRow('sales_unit_price', null, 'NUMBER'));
  // 理由
  colList.push(CreateColRow('reason', null, 'VARCHAR'));
  // 作成ユーザー
  colList.push(CreateColRow('created_user', null, 'VARCHAR'));
  // 更新ユーザー
  colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
  copySql += 'INSERT INTO m_clients_products (' + CreateInsertSql(colList, 'col', 'm_clients_products') + ') ';
  copySql += 'SELECT ';
  copySql += 'clients.client_class';
  copySql += ',clients.client_id';
  copySql += ',clients_products.product_id';
  copySql += ',clients_products.unit_price_effective_date';
  copySql += ',clients_products.sales_unit_price';
  copySql += ',\'自動\'';
  copySql += ',\'' + user + '\'';
  copySql += ',\'' + user + '\'';
  copySql += ' FROM m_clients_products AS clients_products';
  copySql += ' INNER JOIN m_clients AS clients';
  copySql += ' ON clients.client_class = ' + Const.ClientClass.customer + ' ';
  copySql += ' AND clients.client_id IN (' + csvClientId + ') ';
  copySql += ' WHERE ';
  copySql += 'clients_products.client_class=' + Const.ClientClass.customer + ' ';
  copySql += 'AND clients_products.client_id=' + clientIdParent + ' ';
  copySql += 'AND product_id IN (' + csvProductId + ') ';
  copySql += 'AND unit_price_effective_date = \'' + dateUnitPriceEffectiveDate + '\' ';
  //console.log(copySql)
  sqlList.push(copySql);
}

/**
 * 単価登録の削除SQLの返却処理
 * @param {[]} productIdList 製品コード一覧
 * @param {Int} clientId 取引先コード
 * @param {String} dateUnitPriceEffectiveDate 単価適用日
 * @param {Int} registerClass 登録区分（「0：グループ」「1：単独」）
 * @param {Int} unitPriceRegisterClass 単価登録区分（「2：自身のみ登録」「3：親子全て登録」）※「1：登録しない」の場合は本関数は呼び出さないこと
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export async function getDeleteUnitPriceSqlList(productIdList, clientId, dateUnitPriceEffectiveDate, registerClass, unitPriceRegisterClass) {
  let deleteProductIdList = [];
  if (registerClass == Const.ClientsProductsMasterRegisterClass.group) {
    // 登録区分が「0:グループ」の場合、製品一覧のグループ情報を取得して追加
    let promiseArray = [];
    for (let i = 0; i < productIdList.length; i++) {
      let selectSql = makeSelectSqlGroupProductId(productIdList[i]);
      promiseArray.push(executeSelectSql(selectSql));
    }
    //console.log(promiseArray);
    let productGroupList = await Promise.all(promiseArray);
    for (let i = 0; i < productIdList.length; i++) {
      let productGroupResult = productGroupList[i];
      if (productGroupResult != null && productGroupResult.length > 0) {
        for (let j = 0; j < productGroupResult.length; j++) {
          // 代表製品コード
          let productIndex = deleteProductIdList.findIndex(el => el == productGroupResult[j].product_group_id);
          if (productIndex == -1) {
            // 入っていない場合は追加
            deleteProductIdList.push(productGroupResult[j].product_group_id);
          }
          // 製品コード
          productIndex = deleteProductIdList.findIndex(el => el == productGroupResult[j].product_id);
          if (productIndex == -1) {
            // 入っていない場合は追加
            deleteProductIdList.push(productGroupResult[j].product_id);
          }
        }
      } else {
        // グループ登録されていない場合は自己を追加
        let productIndex = deleteProductIdList.findIndex(el => el == productIdList[i]);
        if (productIndex == -1) {
          // 入っていない場合は追加
          deleteProductIdList.push(productIdList[i]);
        }
      }
    }
  } else {
    // 登録区分が「0:グループ」以外の場合、製品一覧のグループ情報には自己の製品コードのみを追加
    for (let i = 0; i < productIdList.length; i++) {
      let productIndex = deleteProductIdList.findIndex(el => el == productIdList[i]);
      if (productIndex == -1) {
        // 入っていない場合は追加
        deleteProductIdList.push(productIdList[i]);
      }
    }
  }
  let csvProductId = deleteProductIdList.join(',');
  let delSql = '';
  // どの取引先に対して単価登録するか
  if (getNullStr(clientId) != '0' && unitPriceRegisterClass == Const.UnitPriceRegisterClass.insertAll) {
    // 単価登録区分が「3:親子全て登録」の場合、且つ、親子登録されている可能性がある場合
    let where_clause = '';
    where_clause += 'AND (client_class_parent = ' + Const.ClientClass.customer + ' ';
    where_clause += 'AND client_id_parent = ' + clientId + ') ';
    where_clause += 'OR (client_class_branch = ' + Const.ClientClass.customer + ' ';
    where_clause += 'AND client_id_branch = ' + clientId + ') ';
    where_clause += 'LIMIT 1 ';
    let resultClientsParentChild = await API.graphql(graphqlOperation(list_m_clients_parent_child,{where_clause: where_clause}));
    let dataClientsParentChild = resultClientsParentChild.data.list_m_clients_parent_child;
    if (dataClientsParentChild != null && dataClientsParentChild.length > 0) {
      // 取引先コード（親）を取得
      let clientIdParent = dataClientsParentChild[0].client_id_parent;
      // 取得した取引先コード（親）を元に取引先コード（子）を取得
      where_clause = '';
      where_clause += 'AND client_class_parent = ' + Const.ClientClass.customer + ' ';
      where_clause += 'AND client_id_parent = ' + clientIdParent + ' ';
      resultClientsParentChild = await API.graphql(graphqlOperation(list_m_clients_parent_child,{where_clause: where_clause}));
      dataClientsParentChild = resultClientsParentChild.data.list_m_clients_parent_child;
      // 削除対象の取引先コードのCSVを作成
      let clientIdList = [clientIdParent];
      for (let i = 0; i < dataClientsParentChild.length; i++) {
        let clientIdIndex = clientIdList.findIndex(el => el == dataClientsParentChild[i].client_id_branch);
        if (clientIdIndex == -1) {
          // 入っていない場合は追加
          clientIdList.push(dataClientsParentChild[i].client_id_branch);
        }
      }
      let csvClientId = clientIdList.join(',');
      // 親取引先の単価登録を行う
      delSql = deleteClientsProducts(csvClientId, csvProductId, dateUnitPriceEffectiveDate);
    } else {
      // 親子取引先が登録されていない登録の場合、自身の単価登録のみ
      delSql = deleteClientsProducts(clientId, csvProductId, dateUnitPriceEffectiveDate);
    }
  } else {
    // 単価登録区分が「2：自身のみ登録」、または、未設定の場合、自身の単価登録のみ
    delSql = deleteClientsProducts(clientId, csvProductId, dateUnitPriceEffectiveDate);
  }
  //console.log(delSql);
  return delSql;
}

/**
 * 取引先製品の親情報を子取引先にコピー
 * @param {String} csvClientId コピー対象の取引先コード（CSV形式）
 * @param {String} csvProductId コピー対象の製品コード（CSV形式）
 * @param {String} dateUnitPriceEffectiveDate 単価適用日
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export function deleteClientsProducts(csvClientId, csvProductId, dateUnitPriceEffectiveDate) {
  let clientClass = Const.ClientClass.customer;
  if (getNullStr(csvClientId) == '0') {
    clientClass = 0;
  }
  // 最初にdelete（指定された子取引先の単価情報の全適用日分を削除）
  let delSql = 'DELETE FROM m_clients_products WHERE ';
  delSql += 'client_class = ' + clientClass + ' ';
  delSql += 'AND client_id IN (' + csvClientId + ') ';
  delSql += 'AND product_id IN (' + csvProductId + ') ';
  delSql += 'AND unit_price_effective_date = \'' + dateUnitPriceEffectiveDate + '\' ';
  //console.log(delSql);
  return delSql;
}

/**
 * 見積データの単価登録の削除SQLの返却処理
 * @param {Int} estimateId 見積番号
 * @returns [String]:更新文のリスト ※DB呼出時にエラーが発生する可能性あり。（呼出元でcatchすること）
 */
export async function getEstimateDeleteUnitPriceSqlList(estimateId) {
  let selectSql = 'SELECT';
  selectSql += ' client_id';
  selectSql += ',unit_price_register_class';
  selectSql += ',unit_price_effective_date';
  selectSql += ',group_register_class';
  selectSql += ',product_id';
  selectSql += ' FROM ';
  selectSql += 't_estimate ';
  selectSql += ' WHERE ';
  selectSql += 'estimate_id = ' + estimateId + ' ';

  let result = await executeSelectSql(selectSql);
  if (result != null || result.length > 0) {
    if (result[0].unit_price_register_class != Const.UnitPriceRegisterClass.insertNo) {
      let productIdList = [];
      for (let i = 0; i < result.length; i++) {
        let productIndex = productIdList.findIndex(el => el == result[i].product_id);
        if (productIndex == -1) {
          if (getNullStr(result[i].product_id) != '0') {
            productIdList.push(result[i].product_id);
          }
        }
      }
      let delSql = await getDeleteUnitPriceSqlList(productIdList, result[0].client_id, result[0].unit_price_effective_date, result[0].group_register_class, result[0].unit_price_register_class);
      return delSql;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

/**
 * 伝票日付と締日を比較して、締日を過ぎた伝票でないかチェック
 * @param {String} dateBillingDate 伝票日付
 * @param {String} closingDate 締日
 * @returns true：締日を過ぎていない（問題なし）、false：締日を過ぎている（注意必要）
 */
export function checkOldClosingDate(dateBillingDate, closingDate) {
  // 現在日が締日を過ぎているかチェック
  if (dateConsistency(formatCurDate(), closingDate) == true) {
    // 現在日が締日より過去、または、同じ日の場合は問題なし
    return true;
  } else {
    // 売上計上日（伝票日付）が締日よりも過去日かチェック
    if (dateConsistency(dateBillingDate, closingDate) == true) {
      // 売上計上日が締日よりも過去日、または、同じ
      return false;
    } else {
      // 売上計上日が締日よりも未来
      return true;
    }
  }
}

/**
 * 請求書の発行登録済みの伝票でないかチェック
 * @param {Int} clientId 取引先コード
 * @param {String} dateBillingDate 伝票日付
 * @param {String} closingDate 締日
 * @param {Int} siteId 現場コード
 * @param {Int} billingNo 伝票番号
 * @returns エラーメッセージ：請求書の発行登録済み（伝票変更抑止）、空白：請求書の発行未登録（伝票変更問題なし）
 */
export async function checkInvoiceIssue(clientId, dateBillingDate, closingDate, siteId, billingNo = 0) {
  let billingMonthYear = getBillingMonthYear(dateBillingDate, closingDate);
  // SELECT句
  let selectSql = 'SELECT';
  selectSql += ' (SELECT COUNT(*) FROM t_billings_issue_input WHERE ';
  selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId;
  selectSql += ' ) AS billings_issue_input_flg';
  selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_site WHERE ';
  selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId + ' AND site_id = ' + siteId;
  selectSql += ' ) AS billings_issue_input_site_flg';
  if (billingNo != 0) {
    selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_billing_no WHERE ';
    selectSql += ' billing_month_year = ' + billingMonthYear + ' AND billing_no = ' + billingNo;
    selectSql += ' ) AS billings_issue_input_billing_no_flg';
  } else {
    selectSql += ',0 AS billings_issue_input_billing_no_flg';
  }
  // FROM句
  selectSql += ' FROM ';
  selectSql += 'DUAL ';
  //console.log(selectSql);
  let resultData = await executeSelectSql(selectSql);
  if (resultData[0].billings_issue_input_flg == 0 && resultData[0].billings_issue_input_site_flg == 0 && resultData[0].billings_issue_input_billing_no_flg == 0) {
    // 請求書の発行登録がない場合
    return '';
  } else if (resultData[0].billings_issue_input_flg == 1) {
    // 請求書の発行登録がある場合（取引先単位）
    return DISP_MESSAGES.WARNING['2068'].replace('%arg1%','取引先');
  } else if (resultData[0].billings_issue_input_site_flg == 1) {
    // 請求書の発行登録がある場合（現場単位）
    return DISP_MESSAGES.WARNING['2068'].replace('%arg1%','現場');
  } else {
    // 請求書の発行登録がある場合（伝票単位）
    return DISP_MESSAGES.WARNING['2068'].replace('%arg1%','伝票番号' + billingNo);
  }
}

/**
 * 一覧がそれぞれ請求書の発行登録済みかどうかをセット
 * @param {Array} result 一覧データ
 * @returns true：請求書発行済みの行あり（伝票発行抑止）、false：請求書発行済みの行なし（伝票発行問題なし）
 */
export async function setInvoiceIssueList(result) {
  let billingMonthYear = '';
  let csvClientId = '';
  let csvSiteId = '';
  let clientId = null;
  let siteId = null;
  let preOrderReceiveId = null;
  let billingsIssueInput = [];
  let billingsIssueInputSiteId = [];
  let isInvoiceIssueInput = false;
  for (let i = 0; i < result.length; i++) {
    if (preOrderReceiveId == null || preOrderReceiveId != result[i].order_receive_id) {
      preOrderReceiveId = result[i].order_receive_id;
      // 一覧の先頭行または、前の行と受注番号が異なる場合
      billingMonthYear = getBillingMonthYear(result[i].sales_issue_date, result[i].closing_date);
      clientId = result[i].client_id;
      siteId = result[i].site_id;
      // 年月-取引先一覧に年月が存在するかチェック
      let recordMonthYear = billingsIssueInput.find(el => el.billingMonthYear == billingMonthYear);
      if (recordMonthYear == undefined) {
        // 既存の年月にない場合
        billingsIssueInput.push({billingMonthYear: billingMonthYear, clientId: [clientId]});
      } else {
        // 既存の年月にある場合
        // 同年月に取引先コードが存在するかチェック
        let recordClientId = recordMonthYear.clientId.find(el => el == clientId);
        if (recordClientId == undefined) {
          // 既存の取引先にない場合
          recordMonthYear.clientId.push(clientId);
        }
      }
      // 年月-取引先-現場一覧に年月-取引先が存在するかチェック
      let recordMonthYearClientId = billingsIssueInputSiteId.find(el => el.billingMonthYear == billingMonthYear && el.clientId == clientId);
      if (recordMonthYearClientId == undefined) {
        // 既存の年月-取引先にない場合
        billingsIssueInputSiteId.push({billingMonthYear: billingMonthYear, clientId: clientId, siteId: [siteId]});
      } else {
        // 既存の年月-取引先にある場合
        // 同年月-取引先に現場が存在するかチェック
        let recordSiteId = recordMonthYearClientId.siteId.find(el => el == siteId);
        if (recordSiteId == undefined) {
          // 既存の現場にない場合
          recordMonthYearClientId.siteId.push(siteId);
        }
      }
      result[i].billingMonthYear = billingMonthYear;
    } else {
      result[i].billingMonthYear = billingMonthYear;      
    }
  }
  // 発行済みチェック用SQL作成（取引先毎）
  let selectSqlClient = '';
  for (let i = 0; i < billingsIssueInput.length; i++) {
    // 請求書の発行登録済みの伝票でないかチェック
    billingMonthYear = billingsIssueInput[i].billingMonthYear;
    csvClientId = billingsIssueInput[i].clientId.join(',');
    if (selectSqlClient != '') {
      selectSqlClient += ' UNION ALL ';
    }
    let selectSql = 'SELECT billing_month_year,client_id FROM t_billings_issue_input WHERE ';
    selectSql += 'billing_month_year = ' + billingMonthYear + ' ';
    selectSql += 'AND client_id IN (' + csvClientId + ') ';
    
    selectSqlClient += selectSql;
  }
  // 発行済みチェック用SQL作成（現場毎）
  let selectSqlSite = '';
  for (let i = 0; i < billingsIssueInputSiteId.length; i++) {
    // 請求書の発行登録済みの伝票でないかチェック
    billingMonthYear = billingsIssueInputSiteId[i].billingMonthYear;
    clientId = billingsIssueInputSiteId[i].clientId;
    csvSiteId = billingsIssueInputSiteId[i].siteId.join(',');
    if (selectSqlSite != '') {
      selectSqlSite += ' UNION ALL ';
    }
    let selectSql = 'SELECT billing_month_year,client_id,site_id FROM t_billings_issue_input_site WHERE ';
    selectSql += 'billing_month_year = ' + billingMonthYear + ' ';
    selectSql += 'AND client_id = ' + clientId + ' ';
    selectSql += 'AND site_id IN (' + csvSiteId + ') ';

    selectSqlSite += selectSql;
  }
  let resultDataClient = null;
  let resultDataSite = null;
  [resultDataClient, resultDataSite] = await Promise.all([
    executeSelectSql(selectSqlClient),
    executeSelectSql(selectSqlSite),
  ]);
  if ((resultDataClient != null && resultDataClient.length > 0) || (resultDataSite != null && resultDataSite.length > 0)) {
    for (let i = 0; i < result.length; i++) {
      if (resultDataClient != null && resultDataClient.length > 0) {
        // 請求書の発行登録がある場合（取引先毎）
        // 請求書の発行登録済みの伝票にならないようにする
        let index = resultDataClient.findIndex(el => getNullStr(el.billing_month_year) == result[i].billingMonthYear && el.client_id == result[i].client_id);
        if (index >= 0) {
          result[i].billings_issue_input_flg = true;
          isInvoiceIssueInput = true;
          // 請求書の発行登録済みの受注と記録した場合、次の行に移動
          continue;
        }
      }
      if (resultDataSite != null && resultDataSite.length > 0) {
        // 請求書の発行登録がある場合（現場毎）
        // 請求書の発行登録済みの伝票にならないようにする
        let index = resultDataSite.findIndex(el => getNullStr(el.billing_month_year) == result[i].billingMonthYear && el.client_id == result[i].client_id && el.site_id == result[i].site_id);
        if (index >= 0) {
          result[i].billings_issue_input_flg = true;
          isInvoiceIssueInput = true;
        }
      }
    }
  }
  return isInvoiceIssueInput;
}

/**
 * 伝票日付と締日から年月を取得
 * @param {String} dateBillingDate 伝票日付
 * @param {String} closingDate 締日
 * @returns 年月
 */
export function getBillingMonthYear(dateBillingDate, closingDate) {
  let billingMonthYear = '';
  if (Number(closingDate) == 99) {
    // 締日が末日の場合
    // 伝票日付の年月を取得
    billingMonthYear = moment(dateBillingDate).format('YYYYMM');
  } else {
    // 締日が末日以外
    // 締日の左0埋め
    let strClosingDate = ('0' + closingDate).slice(-2);
    // 伝票日付の日付を取得
    let strBillingDate = moment(dateBillingDate).format('DD');
    if (strBillingDate > strClosingDate) {
      // 伝票日付が締日を超えている場合は、翌月の年月を取得
      billingMonthYear = moment(dateBillingDate).add(1, 'months').format('YYYYMM');
    } else {
      // 伝票日付が締日を超えていない場合は、当月の年月を取得
      billingMonthYear = moment(dateBillingDate).format('YYYYMM');
    }
  }
  return billingMonthYear;
}

/**
 * 排他制御管理確認
 * @param {Int} processClass 処理区分
 * @param {Int} officeId 営業所コード
 * @param {String} user ユーザーID
 * @param {String} MODULE_NAME 呼出元のファイル名
 * @param {Int} valildSecond 有効時間（秒）
 *                           ※現在時刻が更新開始日時に本パラメータを加算した時刻を過ぎている場合はフラグが立っていても排他処理は終わっていることとする
 * @returns true：排他処理なし（問題なし）、false：排他処理あり
 */
export async function checkExclusiveManagement(processClass, officeId, user, MODULE_NAME, valildSecond) {
  const functionName = 'checkExclusiveManagement';
  // SELECT句
  let selectSql = 'SELECT COUNT(*) CNT';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 't_exclusive_management ';
  // WHERE句
  selectSql += ' WHERE ';
  selectSql += 'process_class = ' + processClass + ' ';
  selectSql += 'AND office_id = ' + officeId + ' ';
  selectSql += 'AND update_start_datetime + INTERVAL ' + valildSecond + ' SECOND >= CURRENT_TIMESTAMP() ';
  //console.log(selectSql);
  let resultData = await executeSelectSql(selectSql);
  //console.log(resultData);
  if (resultData[0].CNT == 0) {
    // 指定した処理が実行中でない場合
    // 指定した処理を実行中に更新してtrueを返却
    let colList = [];
    // 処理区分
    colList.push(CreateColRow('process_class', processClass, 'NUMBER'));
    // 営業所コード
    colList.push(CreateColRow('office_id', officeId, 'NUMBER'));
    // 更新開始日時
    colList.push(CreateColRow('update_start_datetime', 'CURRENT_TIMESTAMP()', 'DATETIME'));
    // 作成ユーザー
    colList.push(CreateColRow('created_user', user, 'VARCHAR'));
    // 更新ユーザー
    colList.push(CreateColRow('updated_user', user, 'VARCHAR'));
    let insertSql = CreateInsertSql(colList, 'full', 't_exclusive_management');
    // マージ処理追加
    let mergeColList = [];
    // 更新開始日時
    mergeColList.push(CreateColRow('update_start_datetime', 'update_start_datetime', 'COLUMN'));
    // 更新日
    mergeColList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
    // 更新ユーザー
    mergeColList.push(CreateColRow('updated_user', 'updated_user', 'COLUMN'));
    insertSql += CreateMergeSql(mergeColList);
    //console.log(insertSql);
    let sqlList = [];
    sqlList.push(insertSql);
    let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
    return retResult;
  } else {
    // 指定した処理が実行中の場合
    return false;
  }
}

/**
 * 排他制御管理のレコード削除
 * @param {Int} processClass 処理区分
 * @param {Int} officeId 営業所コード
 * @param {String} MODULE_NAME 呼出元のファイル名
 */
export async function dropExclusiveManagement(processClass, officeId, MODULE_NAME) {
  const functionName = 'dropExclusiveManagement';
  let sqlList = [];
  let deleteSql = 'DELETE FROM t_exclusive_management';
  deleteSql += ' WHERE ';
  deleteSql += 'process_class = ' + processClass + ' ';
  deleteSql += 'AND office_id = ' + officeId + ' ';
  //console.log(deleteSql);
  sqlList.push(deleteSql);
  await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
}

/**
 * 電子書類区分リスト作成
 * @param {boolean} allSelectRowFlg 「全て」の行を追加するかどうかのフラグ（true：追加、false：追加しない）
 */
export async function createElectronicDocumentsClassList(allSelectRowFlg) {
  let electronicDocumentsList = [];
  // 電子書類区分リスト作成
  let selectSql = '';
  // SELECT句
  selectSql += 'SELECT ';
  selectSql += 'electronic_documents_class';
  selectSql += ',electronic_documents_name';
  selectSql += ',sort';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 'm_electronic_documents ';
  // WHERE句
  selectSql += ' WHERE ';
  selectSql += 'is_deleted = 0 ';
  // ORDER BY句
  selectSql += 'ORDER BY sort,electronic_documents_class';
  let electronicDocumentsClassList = await executeSelectSql(selectSql);
  // 電子書類区分リスト作成
  if (allSelectRowFlg == true) {
    electronicDocumentsList.push({id: 0, text: '全て'});
  }
  if (electronicDocumentsClassList != null) {
    for(let i = 0; i < electronicDocumentsClassList.length; i++){
      let electronicDocumentsClass = {
        id: electronicDocumentsClassList[i].electronic_documents_class,
        text: electronicDocumentsClassList[i].electronic_documents_class + '：' + electronicDocumentsClassList[i].electronic_documents_name,
      };
      electronicDocumentsList.push(electronicDocumentsClass);
    }
  } else {
    if (electronicDocumentsList.length == 0) {
      // 0件の場合はnull行のみのリストを返却
      electronicDocumentsList.push({id: null, text: ''});
    }
  }

  return electronicDocumentsList;
}

/**
 * 営業所プルダウン作成
 * @param {boolean} allSelectRowFlg 「全て」の行を追加するかどうかのフラグ（true：追加、false：追加しない）
 */
export async function createOfficeList(allSelectRowFlg) {
  let officeList = [];
  // 営業所データ取得
  let officeListResult = await API.graphql(graphqlOperation(list_m_offices));
  let officeListData = officeListResult.data.list_m_offices;
  //console.log(officeListData);
  // 営業所プルダウン作成
  if (allSelectRowFlg == true) {
    officeList.push({id: 0, text: '全て'});
  }
  for(let i = 0; i < officeListData.length; i++){
    let office = {
      id: officeListData[i].office_id,
      name: officeListData[i].office_name_kanji,
      text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
    };
    officeList.push(office);
  }
  return officeList;
}

/**
 * HTMLをPDF形式でS3へアップロード
 * @param {Object} uploadFile アップロードするファイル
 * @param {String} filePath ファイルパス（ファイル名含まず）
 * @param {String} fileName ファイル名
 * @returns true：成功、false：失敗
 */
export async function uploadFileToS3(uploadFile, filePath, fileName) {
  try {
    let storageKey = filePath + '/' + fileName;
    let storageOption = {
      level: 'public',
    };
    await Storage.put(storageKey, uploadFile, storageOption);
    // 「1:アップロード」で呼び出す
    return await operateCustomStorage('1', storageKey);
  } catch(error) {
    console.log(error);
    return false;
  }
}

/**
 * HTMLをPDF形式でS3へアップロード（ページ分割アップロード）
 * @param {Object} pdfDoc 読み込んだPDFファイル
 * @param {String} filePath ファイルパス（ファイル名含まず）
 * @param {String} fileName ファイル名
 * @param {String} page ページ数（配列毎に印刷時のページ指定の文字列を設定（形式例：「1,3-4」=1,3,4,6ページを指定」））
 * @returns true：成功、false：失敗
 */
export async function uploadFileToS3PageSplitAlonePdf(pdfDoc, filePath, fileName, page) {
  try {
    let aryUsePage = [];
    // ページはカンマ区切りの可能性があるため、まずカンマで配列
    let listPartPage = page.split(',');
    //console.log(listPartPage);
    for (let j = 0; j < listPartPage.length; j++) {
      let partPage = listPartPage[j].trim();
      //console.log(partPage);
      if (partPage.indexOf('-') == -1) {
        // 「-」がない場合
        aryUsePage.push(Number(partPage) - 1);
      } else {
        // 「-」がある場合
        // 「-」を挟んで開始ページと終了ページまでの数値を全て設定
        let pageStartEnd = partPage.split('-');
        let pageStart = Number(pageStartEnd[0]);
        let pageEnd = Number(pageStartEnd[1]);
        for (let k = pageStart - 1; k < pageEnd; k++) {
          aryUsePage.push(k);
        }
      }
    }
    //console.log(aryUsePage);
    let newDoc = await PDFDocument.create();
    let copyPdfPage = await newDoc.copyPages(pdfDoc, aryUsePage);
    for (let j = 0; j < copyPdfPage.length; j++) {
      newDoc.addPage(copyPdfPage[j]);
    }
    let pdfBytes = await newDoc.save();
    let uploadFile = new File([pdfBytes], fileName, {type: 'application/pdf'});
    return await uploadFileToS3(uploadFile, filePath, fileName);
  } catch(error) {
    console.log(error);
    return false;
  }
}

/**
 * サイズの大きいPDFファイルをページ分割してS3へアップロード（ページ分割アップロード）
 * @param {Object} uploadAllFile アップロードするファイルの全ページ
 * @param {[String]} listFilePath ファイルパス（配列、ファイル名含まず）
 * @param {[String]} listFileName ファイル名（配列）
 * @param {[String]} listPage ページ数（配列）（配列毎に印刷時のページ指定の文字列を設定（形式例：「1,3-4」=1,3,4,6ページを指定」））
 * @param {String} MODULE_NAME  呼出元のファイル名
 * @returns json形式で返却（resultにtrue(成功)or false（失敗）を設定、trueの場合、s3KeyListにS3へのアップロードが成功したファイルのkey名を設定※後続で失敗した場合にS3のファイルを削除するため）}
 * @example listFileNameとlistPageの要素数は同じであること
 */
export async function uploadFileToS3PageSplit(uploadAllFile, listFilePath, listFileName, listPage, MODULE_NAME) {
  //console.log(uploadAllFile);
  //console.log(listFilePath);
  //console.log(listFileName);
  //console.log(listPage);
  let s3KeyList = [];
  try {
    //console.time('timerAll');
    //console.time('PDFDocument.load');
    const pdfDoc = await PDFDocument.load(uploadAllFile);
    //console.timeEnd('PDFDocument.load');
    //console.time('PDFDocumentCopy');
    // listPageのパラメータチェック
    if (checkLoadPdfPage(pdfDoc.getPages().length, listPage) == false) {
      // 入力ページに不正がある場合
      return {
        result: false,
        isPageErr: true,
      };
    }
    let promiseArray = [];
    for (let i = 0; i < listPage.length; i++) {
      promiseArray.push(uploadFileToS3PageSplitAlonePdf(pdfDoc, listFilePath[i], listFileName[i], listPage[i]));
    }
    let retList = await Promise.all(promiseArray);
    let isFailure = false;
    for (let i = 0; i < retList.length; i++) {
      if (retList[i] == true) {
        // 成功した場合はKeyListに入れる
        s3KeyList.push(listFilePath[i] + '/' + listFileName[i]);
      } else {
        // 一つでも失敗した場合は失敗フラグを立てる
        isFailure = true;
      }
    }
    if (isFailure == true) {
      // 失敗フラグが立っている場合
      throw 'アップロード失敗';
    }
    //console.timeEnd('PDFDocumentCopy');
    //console.timeEnd('timerAll');
    return {
      result: true,
      s3KeyList: s3KeyList
    };
  } catch (error) {
    console.log(error);
    if (s3KeyList.length > 0) {
      // アップロードに成功したファイルがある場合は全て削除
      await uploadFileRemoveFromS3Multiple(s3KeyList, MODULE_NAME);
    }
    return {
      result: false,
    };
  }
}

/**
 * HTMLをPDF形式でS3へアップロード（ページ分割アップロード）
 * @param {Object} htmlPdf HTMLソース
 * @param {Int} width HTMLソースの横幅（基本的にclientWidth）
 * @param {Int} height HTMLソースの縦幅（基本的にclientHeight×ページ数）
 * @param {[String]} listFilePath ファイルパス（配列、ファイル名含まず）
 * @param {[String]} listFileName ファイル名（配列）
 * @param {[String]} listPage ページ数（配列）（配列毎に印刷時のページ指定の文字列を設定（形式例：「1,3-4」=1,3,4,6ページを指定」））
 * @param {String} MODULE_NAME  呼出元のファイル名
 * @returns json形式で返却（resultにtrue(成功)or false（失敗）を設定、trueの場合、s3KeyListにS3へのアップロードが成功したファイルのkey名を設定※後続で失敗した場合にS3のファイルを削除するため）}
 * @example listFileNameとlistPageの要素数は同じであること
 */
export async function uploadHtmlPdfToS3PageSplit(htmlSrc, width, height, listFilePath, listFileName, listPage, MODULE_NAME) {
  //console.log(width);
  //console.log(height);
  //console.log(listFilePath);
  //console.log(listFileName);
  //console.log(listPage);
  let s3KeyList = [];
  try {
    //console.time('timerAll');
    let options = {
      margin: 0,
      filename: 'a.pdf',
      image: {
        type: 'jpeg',
        quality: 0.2
      },
      html2canvas: {
        scale: 1,
        width: width,
        height: height,
      },
      jsPDF: { format: 'a4', orientation: 'p' },
    };
    //console.time('outputPdfBuffer');
    let arraybuffer = await html2pdf().set(options).from(htmlSrc).outputPdf('arraybuffer');
    //console.timeEnd('outputPdfBuffer');
    //console.time('PDFDocument.load');
    const pdfDoc = await PDFDocument.load(arraybuffer);
    //console.timeEnd('PDFDocument.load');
    //console.time('PDFDocumentCopy');
    let promiseArray = [];
    for (let i = 0; i < listPage.length; i++) {
      promiseArray.push(uploadFileToS3PageSplitAlonePdf(pdfDoc, listFilePath[i], listFileName[i], listPage[i]));
    }
    let retList = await Promise.all(promiseArray);
    let isFailure = false;
    for (let i = 0; i < retList.length; i++) {
      if (retList[i] == true) {
        // 成功した場合はKeyListに入れる
        s3KeyList.push(listFilePath[i] + '/' + listFileName[i]);
      } else {
        // 一つでも失敗した場合は失敗フラグを立てる
        isFailure = true;
      }
    }
    if (isFailure == true) {
      // 失敗フラグが立っている場合
      throw 'アップロード失敗';
    }
    //console.timeEnd('PDFDocumentCopy');
    //console.timeEnd('timerAll');
    return {
      result: true,
      s3KeyList: s3KeyList
    };
  } catch (error) {
    console.log(error);
    if (s3KeyList.length > 0) {
      // アップロードに成功したファイルがある場合は全て削除
      await uploadFileRemoveFromS3Multiple(s3KeyList, MODULE_NAME);
    }
    return {
      result: false,
    };
  }
}

/**
 * パラメータのlistPageがpdfPageと比較して矛盾がないことをチェック
 * @param {Int} pdfPageCount pdfファイルのページ数（listPageがページ数を超過していないことのチェック用）
 * @param {Int} listPage ページ数（配列）（数字の他、「-」「,」が入っている。「--」「,,」も許容されているため、それらはNGとする。）
 * @returns true:チェックOK、false:チェックNG
 */
export function checkLoadPdfPage(pdfPageCount, listPage) {
  for (let i = 0; i < listPage.length; i++) {
    let page = listPage[i];
    // ページはカンマ区切りの可能性があるため、まずカンマで配列
    let listPartPage = page.split(',');
    //console.log(listPartPage);
    for (let j = 0; j < listPartPage.length; j++) {
      let partPage = listPartPage[j].trim();
      //console.log(partPage);
      if (partPage.indexOf('-') == -1) {
        // 「-」がない場合
        if (pdfPageCount < Number(partPage)) {
          // PDFファイルのページ数を超過したページが指定されているためNG
          return false;
        }
      } else {
        // 「-」がある場合
        // 「-」を挟んで開始ページと終了ページまでの数値を全て設定
        let pageStartEnd = partPage.split('-');
        if (pageStartEnd.length == 2) {
          let pageStart = Number(pageStartEnd[0]);
          let pageEnd = Number(pageStartEnd[1]);
          if (pageStart > pageEnd) {
            // 終了ページが開始ページを超過している場合NG
            return false;
          }
          // 終了ページが一番大きいため終了ページのみを比較
          if (pdfPageCount < pageEnd) {
            // PDFファイルのページ数を超過したページが指定されているためNG
            return false;
          }
        } else {
          // 2以外の場合は形式が不正なためNG
          return false;
        }
      }
    }
  }
  // 最後まで進んだ場合、NGのページがなかったためOK
  return true;
}

/**
 * S3にアップロードしたファイルを削除
 * @param {[String]} storageKeyList ファイルパス/ファイル名（配列）
 * @param {String} MODULE_NAME  呼出元のファイル名
 * @example 失敗した場合はエラーログ出力して知らせる。ゴミファイルになる。
 */
export async function uploadFileRemoveFromS3Multiple(storageKeyList, MODULE_NAME = 'common') {
  let promiseArray = [];
  for (let i = 0; i < storageKeyList.length; i++) {
    promiseArray.push(uploadFileRemoveFromS3(storageKeyList[i], MODULE_NAME));
  }
  await Promise.all(promiseArray);
}

/**
 * S3にアップロードしたファイルを削除
 * @param {String} storageKey ファイルパス/ファイル名
 * @param {String} MODULE_NAME  呼出元のファイル名
 * @example 失敗した場合はエラーログ出力して知らせる。ゴミファイルになる。
 */
export async function uploadFileRemoveFromS3(storageKey, MODULE_NAME = 'common') {
  const functionName = 'uploadFileRemoveFromS3';
  try {
    let storageOption = {
      level: 'public',
    };
    await Storage.remove(storageKey, storageOption);
    // 「3:削除」で呼び出す
    let ret = await operateCustomStorage('3', storageKey);
    if (ret == false) {
      throw 'S3カスタムストレージのファイルの削除に失敗しました。';
    }
  } catch(error) {
    console.log(error);
    // エラーログ出力（処理は続ける）
    await addOperationLogs('Error', MODULE_NAME, functionName, {
      message: 'S3からファイルの削除に失敗しました。',
      s3Key: storageKey,
    }, error);
  }
}

/**
 * カスタムS3からファイルをダウンロード
 * @param {String} storageKey ファイルパス/ファイル名
 * @returns true：ダウンロード成功、false：ダウンロード失敗
 */
export async function downloadFileFromS3(storageKey) {
  try {
    // 「2:ダウンロード」で呼び出す
    let ret = await operateCustomStorage('2', storageKey);
    if (ret == false) {
      return false;
    }
    let config = {
      level: 'public',
    };
    let result = await Storage.get(storageKey, config);
    const download = document.createElement('a');
    download.onclick = () => {
      window.open(result, '_blank');
    }
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(result);
  } catch(err) {
    console.log(err);
    return false;
  }
  return true;
}

/**
 * Lambda関数（saveDocumentsStorageOperation）を呼出し、CustomStorageを操作する
 * @param {String} operationKbn 操作区分（1:アップロード、2:ダウンロード、3:削除）
 * @param {String} objectKey  操作するオブジェクトのキー（フォルダ名＋ファイル名）
 * @returns true：処理成功、false：処理失敗
 */
export async function operateCustomStorage(operationKbn, objectKey) {
  let saveDocumentsStorageOperationInput = {
    operationKbn: operationKbn,
    objectKey: objectKey
  };
  let result = await API.graphql(graphqlOperation(saveDocumentsStorageOperation, {saveDocumentsStorageOperationInput: saveDocumentsStorageOperationInput}));
  //console.log(result);
  if (result.data.saveDocumentsStorageOperation.statusCode != 200) {
    console.log(result.data.saveDocumentsStorageOperation.body);
    return false;
  } else {
    return true;
  }
}

/**
 * 引数から請求重複区分を判定して返却する
 * @param {Int} billingOutputClass 請求書出力単位区分（0:取引先別、1:現場別）
 * @param {Int} normalSeparateClass  通常分割区分（0:通常、1:分割）
 * @param {Int} separateBillingClass  請求書分割区分（0:伝票毎、1:現場毎）※通常分割区分「0:通常」の場合は0固定
 * @returns 請求重複区分（1:重複（取引先別の通常）、2:重複（取引先別の分割）、3:重複（現場別の現場全体）、4:重複（現場別の伝票毎））
 */
export function getBillingDuplicateClass(billingOutputClass, normalSeparateClass, separateBillingClass) {
  if (billingOutputClass == Const.BillingOutputClass.clientSite) {
    // 請求書出力単位区分「1：現場別」の場合
    if (normalSeparateClass == Const.NormalSeparateClass.separate) {
      // 通常分割区分「1:分割」の場合
      if (separateBillingClass == Const.SeparateBillingClass.sites) {
        // 請求書分割区分「1:現場毎」の場合
        // 「3:重複（現場別の現場全体）」を返却
        return Const.BillingDuplicateClass.sitesAll;
      } else {
        // 請求書分割区分「0:伝票毎」の場合
        // 「4:重複（現場別の伝票毎）」を返却
        return Const.BillingDuplicateClass.sitesBillings;
      }
    } else {
      // 通常分割区分「0:通常」の場合
      // 「3:重複（現場別の現場全体）」を返却
      return Const.BillingDuplicateClass.sitesAll;
    }
  } else {
    // 請求書出力単位区分「0:取引先別」の場合
    if (normalSeparateClass == Const.NormalSeparateClass.separate) {
      // 通常分割区分「1:分割」の場合
      // 「2:重複（取引先別の分割）」を返却
      return Const.BillingDuplicateClass.clientsSeparate;
    } else {
      // 通常分割区分「0:通常」の場合
      // 「1:重複（取引先別の通常）」を返却
      return Const.BillingDuplicateClass.clientsNormal;
    }
  }
}

/**
 * 電子書類データ＿請求書データの請求重複区分のUPDATE文
 * @param {String} csvBillingMonthYear カンマ区切りの請求年月
 * @param {String} csvClientId  カンマ区切りの取引先コード
 * @param {Int} billingDuplicateClass  請求重複区分（1:重複（取引先別の通常）、2:重複（取引先別の分割）、3:重複（現場別の現場全体）、4:重複（現場別の伝票毎））
 * @param {String} loginId ログインID
 * @param {boolean} isDelete 削除時かどうかを確認するフラグ（削除時は重複ではなくなるかどうかを判定して、重複で亡くなる場合は0に更新）
 *        デフォルト値はfalse（削除以外の場合はloginIdまでの指定でよい。）
 * @returns UPDATE文
 */
export function updateElectronicDocumentsSeikyuDuplicateClass(csvBillingMonthYear, csvClientId, billingDuplicateClass, loginId, isDelete = false) {
  let colList = [];
  // 請求重複区分
  if (isDelete == false) {
    // 削除以外（登録）の場合（登録によって増えて重複になった場合の重複区分を設定）
    colList.push(CreateColRow('t_eds.billing_duplicate_class', billingDuplicateClass, 'NUMBER'));
  } else {
    // 削除の場合（削除によって減って重複ではなくなった場合の重複区分「0：重複無し」を設定）
    colList.push(CreateColRow('t_eds.billing_duplicate_class', Const.BillingDuplicateClass.none, 'NUMBER'));
  }
  // 更新日
  colList.push(CreateColRow('t_eds.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
  // 更新ユーザー
  colList.push(CreateColRow('t_eds.updated_user', loginId, 'VARCHAR'));
  // 請求重複区分の更新を試みるデータ取得
  let selectSql = '';
  // SELECT句
  selectSql += 'SELECT ';
  selectSql += 'billing_month_year';
  selectSql += ',client_id';
  selectSql += ',site_id';
  // FROM句
  selectSql += ' FROM ';
  selectSql += 't_electronic_documents_seikyu ';
  // WHERE句
  selectSql += ' WHERE ';
  selectSql += 'billing_month_year IN (' + csvBillingMonthYear + ') ';
  selectSql += 'AND client_id IN (' + csvClientId + ') ';
  if (billingDuplicateClass == Const.BillingDuplicateClass.clientsNormal) {
    // 請求重複区分「1:重複（取引先別の通常）」の場合
    selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.client + ' ';
    selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
  } else if (billingDuplicateClass == Const.BillingDuplicateClass.clientsSeparate) {
    // 請求重複区分「2:重複（取引先別の分割）」の場合
    selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.client + ' ';
    selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
  } else if (billingDuplicateClass == Const.BillingDuplicateClass.sitesAll) {
    // 請求重複区分「3:重複（現場別の現場全体）」の場合
    selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
    selectSql += 'AND (normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
    selectSql += 'OR (normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
    selectSql += 'AND billing_separate_class = ' + Const.SeparateBillingClass.sites + ')) ';
  } else {
    // 請求重複区分「4:重複（現場別の伝票毎）」の場合
    selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
    selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
    selectSql += 'AND billing_separate_class = ' + Const.SeparateBillingClass.billings + ' ';
  }
  // GROUP BY句
  selectSql += 'GROUP BY billing_month_year,client_id,site_id ';
  // HAVING句
  if (isDelete == false) {
    // 削除以外（登録）の場合（登録によって増えて重複になる可能性があるため、確認）
    selectSql += 'HAVING COUNT(*) > 1 ';
  } else {
    // 削除の場合（削除によって減って重複ではなくなる可能性があるため、確認）
    selectSql += 'HAVING COUNT(*) = 1 ';
  }
  // WHERE句
  let where_clause = '';
  where_clause = ' WHERE ';
  where_clause += 't_eds.billing_month_year = query.billing_month_year ';
  where_clause += 'AND t_eds.client_id = query.client_id ';
  where_clause += 'AND t_eds.site_id = query.site_id ';
  if (billingDuplicateClass == Const.BillingDuplicateClass.clientsNormal) {
    // 請求重複区分「1:重複（取引先別の通常）」の場合
    where_clause += 'AND t_eds.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
    where_clause += 'AND t_eds.normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
  } else if (billingDuplicateClass == Const.BillingDuplicateClass.clientsSeparate) {
    // 請求重複区分「2:重複（取引先別の分割）」の場合
    where_clause += 'AND t_eds.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
    where_clause += 'AND t_eds.normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
  } else if (billingDuplicateClass == Const.BillingDuplicateClass.sitesAll) {
    // 請求重複区分「3:重複（現場別の現場全体）」の場合
    where_clause += 'AND t_eds.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
    where_clause += 'AND (t_eds.normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
    where_clause += 'OR (t_eds.normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
    where_clause += 'AND t_eds.billing_separate_class = ' + Const.SeparateBillingClass.sites + ')) ';
  } else {
    // 請求重複区分「4:重複（現場別の伝票毎）」の場合
    where_clause += 'AND t_eds.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
    where_clause += 'AND t_eds.normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
    where_clause += 'AND t_eds.billing_separate_class = ' + Const.SeparateBillingClass.billings + ' ';
  }
  // Update句とWHERE句でUPDATE文作成
  let updateSql = CreateUpdateSql(colList, 't_electronic_documents_seikyu AS t_eds', '(' + selectSql + ') AS query') + where_clause;
  return updateSql;
}