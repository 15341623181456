export const DISP_MESSAGES = {
  INFO: {
    '0001': 'ページ数が多いため、%arg1%ページ毎に分割して印刷します。印刷を終えたらタブは閉じて下さい。',
    '0002': '複数タブを開いた場合、メモリエラーとなる可能性があります。その場合はPDF出力ボタンから再度やり直してください。',
    '0003': '%arg1%件インポートしました。',
  },
  SUCCESS: {
    '1001': '保存に成功しました。',
    '1002': '削除に成功しました。',
    '1003': '更新に成功しました。',
  },
  WARNING: {
    '2001': '予期せぬエラーが起きました。検索し直してください。',
    '2002': '1000件以降の検索結果は表示されません。検索条件を変えて絞込みを行ってください。',
    '2003': 'データの取得に失敗しました。削除されている可能性があります。',
    '2004': '重複しているため、登録することは不可能です。',
    '2005': '不正なデータが登録されているため、コピーを中止しました。（取引先（子）と取引先（親）が同じデータがあります。',
    '2006': '指定された取引先（子）に紐づく親は存在しません。',
    '2007': '指定された取引先（子）は他の親に子として登録されています。',
    '2008': '指定された取引先（子）は既に親として登録されています。',
    '2009': '%arg1%件以降の%arg2%は表示されません。検索条件を変えて絞込みを行ってください。',
    '2010': '指定された%arg1%は存在しません。',
    '2011': '%arg1%は1件以上設定する必要があります。',
    '2012': '%arg1%はまだ月次処理が行われていません。',
    '2013': '同じ製品コードが存在します',
    '2014': '製品コードの確認を行ってください',
    '2015': 'ケース製品コードとバラ製品コードと同じ設定はできません',
    '2016': '在庫が足りないため更新できません。',
    '2017': '処理年月よりも過去のデータのため編集不可です。',
    '2018': '返品在庫未計上のデータのため編集不可です。',
    '2019': '相殺によって自動作成されたデータのため編集不可です。',
    '2020': '月次更新を実行中です。しばらくしてからもう一度お試しください。',
    '2021': '製品%arg1%の在庫引当が成功しませんでした。製品構成保守や製品単位変換保守と整合性が合っていない可能性があります。',
    '2022': '月次更新処理はエラーにより停止しました。',
    '2023': '受注番号%arg1%と紐づいているため、一部の編集が制限されます。',
    '2024': '%arg1%の受注セット品のデータが不正なため、編集不可です。',
    '2025': '月次更新処理が進行中です。終了するまで各種の登録・更新・削除が制限されます。',
    '2026': '注文済みです。ご注意ください。',
    '2027': '別ユーザーによって更新されたため、保存できませんでした。画面を開き直して再度入力して下さい。',
    '2028': '操作している本人を削除することはできません。',
    '2029': '別営業所で設定されている伝票番号と重複しています。',
    '2030': '%arg1%にシングルクオーテーションを使用することはできません。',
    '2031': '%arg1%にダブルクオーテーションを使用することはできません。',
    '2032': '別ユーザーによって更新されています。画面を開き直して下さい。',
    '2033': '取引先コード切替が進行中です。終了するまで各種の登録・更新・削除が制限されます。',
    '2034': '製品コードコード切替が進行中です。終了するまで各種の登録・更新・削除が制限されます。',
    '2035': '取引先コード切替を実行中です。しばらくしてからもう一度お試しください。',
    '2036': '製品コード切替を実行中です。しばらくしてからもう一度お試しください。',
    '2037': '%arg1%件以降の検索結果は表示されません。検索条件を変えて絞込みを行ってください。',
    '2038': '指定された9A製品の製品分類コードは定義されていません。',
    '2039': '指定された%arg1%は既に利用されています。未使用の%arg2%を指定してください。',
    '2040': '%arg1%は%arg2%と異なる値を指定してください。',
    '2041': '枝番は%arg1%～%arg2%の値でなければなりません。',
    '2042': '%arg1%以上%arg2%以下で選択してください。',
    '2043': '%arg1%に休日が選択されています。',
    '2044': '諸口製品は選択できません。',
    '2045': '月次更新前のデータを編集しようとしています。画面を開き直して下さい。',
    '2046': '指定された%arg1%の%arg2%は不可能です。',
    '2047': '全製品が削除されているため保存できません。',
    '2048': '同じ注文番号で入力済です。ご注意ください。',
    '2049': '一度のPDF出力で出力する取引先は%arg1%以内として下さい。',
    '2050': '指定された営業所の棚卸データがありません。棚卸前処理を行ってください。',
    '2051': '%arg1%締更新が終わっていないため更新不可能です。',
    '2052': '請求/支払締更新処理が進行中です。終了するまで各種の登録・更新・削除が制限されます。',
    '2053': '請求/支払締更新処理を実行中です。しばらくしてからもう一度お試しください。',
    '2054': '出力可能なサイズを超過しています。検索条件を絞り込むか、出力項目を減らしてください',
    '2055': '製品コード%arg1%の製品は製品マスタに存在しないため、受注コピーできません。',
    '2056': '製品コード%arg1%の製品名が正しくないため、受注コピーできません。',
    '2057': '予期せぬエラーが発生したため、受注コピーできません。',
    '2058': '指定された注文番号はまだ入荷が完了していません。ご注意ください。',
    '2059': '読み込んだファイルの形式が不正です。%arg1%',
    '2060': '売価・仕入単価一括更新処理が進行中です。終了するまで各種の登録・更新・削除が制限されます。',
    '2061': '売価・仕入単価一括更新処理を実行中です。しばらくしてからもう一度お試しください。',
    '2062': 'ファイルの読み込みに失敗しました。ファイルが更新された可能性があります。ファイルを選択し直してください。',
    '2063': '非同期SELECTリクエストメッセージ送信処理に失敗しました。',
    '2064': '非同期SELECTの実行中にエラーが発生しました。',
    '2065': '検索条件に該当するデータが存在しません。',
    '2066': '%arg1%と%arg2%が重複しています。',
    '2067': '別ユーザーによって%arg1%は実行中です。しばらくしてからもう一度お試しください。',
    '2068': '指定された%arg1%は請求書発行済みです。',
    '2069': '請求書発行登録済みのため、一部の受注は伝票を発行しませんでした。（受注番号：%arg1%）',
    '2070': '%arg1%件インポートしました。NGデータが%arg2%件ありました。',
    '2071': '検索範囲が広く検索不可能です。検索条件を更に絞り込んでください。',
    '2072': '分割請求番号は%arg1%に1種類としてください。',
    '2073': '処理年月よりも過去の請求書は請求書発行登録を実行した請求書のみが発行可能です。',
    '2074': '%arg1%同時に先行発行可能な%arg2%は%arg3%件までです。',
    '2075': '同時に選択可能な%arg1%は%arg2%件までです。',
    '2076': '%arg1%が重複しています。',
    '2077': '%arg1%は%arg2%で使用されています。%arg3%をして下さい。',
    '2078': 'ファイルのダウンロードに失敗しました。',
    '2079': 'ページの指定に誤りがあるためPDFを読み込めません。',
    '2080': '同時に保存可能な行数は%arg1%行までとして下さい。',
    '2081': '保存から%arg1%経過したため%arg2%不可能です。',
    '2082': '%arg1%を指定して下さい。',
    '2083': '通常配送の場合は配送コードに営業の番号を指定しないで下さい。',
    '2084': '検索後に%arg1%が更新されています。検索し直してください。',
  },
  DANGER: {
    '3001': '保存に失敗しました。',
    '3002': '削除に失敗しました。',
    '3003': '更新に失敗しました。',
    '3004': '%arg1%が設定されていません。',
    '3005': '予期せぬエラーが起きました。',
    '3006': '相殺相手先が取引先マスタに存在しません。',
    '3007': 'システム管理者にお問い合わせください。',
    '3008': '相殺相手先が重複しているため、登録することは不可能です。',
    '3009': '一部のデータの%arg1%に失敗しました。',
  },
};

export const LOG_MESSAGES = {
  DEBUG: {
    '0001': '共通DEBUG_001です',
  },
  INFO: {
    '1001': '共通INFO_001です',
  },
  WARN: {
    '2001': '共通WARN_001です',
  },
  ERROR: {
    '3001': '共通ERROR_001です',
  },
  FATAL: {
    '4001': '共通FATAL_001です',
  },
};